import React from 'react';

import styled from '@emotion/styled';
import { ButtonComponent, Text } from '@clutter/clean';
import { Link } from 'react-router-dom';

const LINK_STYLES = `
  display: flex;
  justify-content: center;
  align-items: center;
  & div,
  & div:hover {
    color: inherit !important;
  }
`;

const shouldForwardProp = (prop: string) => prop !== 'fullWidth' && prop !== 'kind';

const Button = styled(ButtonComponent, {
  shouldForwardProp,
})(LINK_STYLES).withComponent(Link);

const Anchor = styled(ButtonComponent, {
  shouldForwardProp,
})(LINK_STYLES).withComponent('a');

export const ButtonAnchor: React.FC<
  React.ComponentProps<typeof ButtonComponent> & React.HTMLProps<HTMLAnchorElement>
> = ({ children, ...props }) => {
  return (
    <Anchor {...props}>
      <Text.Button>{children}</Text.Button>
    </Anchor>
  );
};

export const ButtonLink: React.FC<React.ComponentProps<typeof ButtonComponent> & React.ComponentProps<typeof Link>> = ({
  children,
  ...props
}) => {
  return (
    <Button {...props}>
      <Text.Button>{children}</Text.Button>
    </Button>
  );
};
