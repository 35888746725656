import * as React from 'react';

import { Bounds, Icon, Line, Map } from '../mapbox';

import { Resizer } from '../resizer';

import { useOrderContext } from '@application/contexts/order';
import { Mode } from '@application/platform_schema';
import { COLORS } from '@clutter/clean';

const ROUTE_LAYOUT = {
  'line-join': 'round',
  'line-cap': 'round',
};

const ROUTE_PAINT = {
  'line-color': COLORS.tealPrimary,
  'line-width': 4,
  'line-gradient': ['interpolate', ['linear'], ['line-progress'], 0, COLORS.tealPrimary, 1, COLORS.dew],
};

const height = (): number => {
  if (window.screen.height > 812) {
    return 500;
  } // i.e. desktop
  if (window.screen.height > 736) {
    return 400;
  } // i.e. X
  if (window.screen.height > 667) {
    return 360;
  } // i.e. plus
  return 300; // i.e. normal
};

const Route: React.FC = () => {
  const {
    app: { order, mode },
    staleCheckin,
  } = useOrderContext();
  const { address, movingOperation } = order;

  const destination =
    (mode === Mode.ArrivedAtDestination || mode === Mode.DepartedForDestination) &&
    order?.route?.kind === 'origin_to_destination'
      ? movingOperation?.destinationAddress
      : address;

  const map = ({ width }: { width: number }) => {
    const checkin = order.lastCheckin;
    const { polyline } = order.route || {};
    const hasArrived = mode === Mode.Arrived || mode === Mode.ArrivedAtDestination;
    const latitude = destination?.latitude;
    const longitude = destination?.longitude;
    const coordinates =
      latitude && longitude
        ? {
            latitude: latitude,
            longitude: longitude,
          }
        : undefined;

    return (
      <Map {...coordinates} width={width} height={height()} zoom={hasArrived ? 16 : undefined}>
        {!hasArrived && polyline && !staleCheckin && (
          <>
            <Line id="route" paint={ROUTE_PAINT} layout={ROUTE_LAYOUT} geometry={polyline} />
            <Bounds geometry={polyline} />
          </>
        )}
        {checkin && <Icon id="checkin" maxzoom={24} type="origin-pin" coordinate={checkin} />}
        {coordinates && <Icon maxzoom={24} id="address" type="destination-pin" coordinate={coordinates} />}
      </Map>
    );
  };

  return <Resizer render={map} />;
};

export { Route };
