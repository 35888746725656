import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCreditCard,
  faInfo,
  faPhone,
  faQuestionCircle,
  faShare,
  faSpinner,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
  faGoogle,
  faYelp,
} from '@fortawesome/free-brands-svg-icons';

library.add(faCreditCard);
library.add(faInfo);
library.add(faPhone);
library.add(faQuestionCircle);
library.add(faShare);
library.add(faSpinner);
library.add(faThumbsDown);
library.add(faThumbsUp);

library.add(faCcAmex);
library.add(faCcDinersClub);
library.add(faCcDiscover);
library.add(faCcJcb);
library.add(faCcMastercard);
library.add(faCcVisa);
library.add(faGoogle);
library.add(faYelp);
