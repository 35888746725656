import { NetworkStatus } from '@apollo/client/core/networkStatus';
import { Pluralize } from '@application/components/formatters';
import { BackNavArrow } from '@application/components/shared/back_nav_arrow';
import { Fallback } from '@application/components/shared/fallback';
import { useActionCableSubscription } from '@application/hooks/use_action_cable_subscription';
import { useIntersectionObserver } from '@application/hooks/use_intersection_observer';
import { platformClient } from '@application/libraries/apollo';
import { ItemSortEnum, OrderTypeEnum, useItemCountQuery, useItemsPaginatedQuery } from '@application/platform_schema';
import { Box, COLORS, FontWeight, Icon, Text, UnstyledButton, SkeletonLoader } from '@clutter/clean';
import React, { useEffect, useState } from 'react';
import { ItemPreviewCard } from '../shared/item_preview_card';
import { useItemSelectionContext } from '@application/contexts/item_selection';
import { Detail } from './detail';
import { SortBy } from './sort_by';

import { createAsyncQueue } from '@application/utilities/async';
import { useUpdateEffect } from '@application/hooks/lifecycle';
import { useHistory } from 'react-router';

export const PAGE_SIZE = 10;

const ItemContainer: React.FC = ({ children }) => <Box padding="8px 0px">{children}</Box>;

const LoadingState = () => (
  <>
    <Box margin="8px 0 0">
      {[1, 2, 3, 4, 5].map((i) => (
        <ItemContainer key={i}>
          <ItemPreviewCard.Loader />
        </ItemContainer>
      ))}
    </Box>
  </>
);

const OptimisticItems: React.FC<{
  totalItems: number;
  pendingItems: number;
  currentItems: number;
}> = ({ totalItems, pendingItems, currentItems }) => (
  <>
    {new Array(Math.min(pendingItems, totalItems - currentItems)).fill(null).map((_, i) => (
      <ItemContainer key={i}>
        <ItemPreviewCard.Loader />
      </ItemContainer>
    ))}
  </>
);

const itemFetchingQueue = createAsyncQueue();

export const Items: React.FC<{ backURL: string; returnOrder: boolean }> = ({ backURL, returnOrder }) => {
  const [cachedCount, setCachedCount] = useState(0);
  const [sort, setSort] = useState(ItemSortEnum.Newest);
  const [page, setPage] = useState(1);
  const [inFlight, setInFlight] = useState(0);

  const { data: itemCountData, refetch: refetchCount } = useItemCountQuery({
    variables: { orderType: returnOrder ? OrderTypeEnum.Return : OrderTypeEnum.Pickup },
  });
  const { data, error, refetch, fetchMore, networkStatus } = useItemsPaginatedQuery({
    variables: { per: PAGE_SIZE, page: 1, sort, orderType: returnOrder ? OrderTypeEnum.Return : OrderTypeEnum.Pickup },
    notifyOnNetworkStatusChange: true,
  });

  if (error) throw error;

  const loading = !itemCountData || networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.loading;
  const currentCount = itemCountData?.itemCount || 0;
  const newCount = (currentCount || 0) - cachedCount;

  const items = data?.itemsPaginated.results || [];
  const count = data?.itemsPaginated.pagination.totalCount;

  useEffect(() => {
    if (count && !cachedCount) {
      setCachedCount(count);
    }
  }, [count, cachedCount]);

  useUpdateEffect(() => {
    setPage(1);
    setCachedCount(0);

    platformClient.cache.evict({ fieldName: 'itemsPaginated' });

    refetch({ sort, page: 1, per: PAGE_SIZE });
  }, [sort, refetch]);

  useActionCableSubscription({
    channel: 'OrderResetChannel',
    callback: () => {
      refetchCount();
    },
  });

  const fetch = async () => {
    setPage((page) => page + 1);
    setInFlight((cur) => cur + 1);

    itemFetchingQueue.enqueue(async () => {
      try {
        await fetchMore({ variables: { page: page + 1, sort } });
      } finally {
        setInFlight((cur) => cur - 1);
      }
    });
  };

  const [triggerRef, entry] = useIntersectionObserver();

  useEffect(() => {
    if (entry?.isIntersecting) fetch();
  }, [entry?.isIntersecting]);

  const { selectedItem, setSelectedItem, nextItem } = useItemSelectionContext(items);

  useEffect(() => {
    if (selectedItem && !nextItem && items.length < currentCount) {
      fetch();
    }
  }, [nextItem, selectedItem]);

  if (selectedItem) {
    return <Detail loadingNextItem={networkStatus === NetworkStatus.fetchMore} />;
  }

  return (
    <div>
      <BackNavArrow to={backURL} />
      <Box.Flex margin="32px 0 24px" justifyContent="space-between" alignItems="baseline">
        <Text.Title size="large">Your items</Text.Title>
        <SortBy onSelectSort={setSort} sort={sort} />
      </Box.Flex>
      <Fallback delayMs={0} loader={<SkeletonLoader height="17px" width="100px" />} value={!!cachedCount}>
        <Box color={newCount ? COLORS.tealPrimary : COLORS.panther}>
          {newCount && !returnOrder ? (
            <UnstyledButton onClick={() => refetch()}>
              <Text.Caption weight={FontWeight.Medium}>
                <Pluralize count={newCount} singular="new item" plural="new items" /> added
              </Text.Caption>
            </UnstyledButton>
          ) : (
            <Text.Caption weight={FontWeight.Medium}>
              <Pluralize count={cachedCount} singular="item" plural="items" />
              {!!newCount && ' new'} {!returnOrder && ' added'}
            </Text.Caption>
          )}
        </Box>
      </Fallback>
      <Fallback delayMs={0} loader={<LoadingState />} value={!loading} minimumLoadingMs={1500}>
        <Box margin="8px 0 24px">
          {items.map((item, i) => (
            <ItemContainer key={item.id}>
              <ItemPreviewCard item={item} onClick={setSelectedItem} />
            </ItemContainer>
          ))}
          {inFlight > 0 && (
            <OptimisticItems
              currentItems={items.length}
              pendingItems={inFlight * PAGE_SIZE}
              totalItems={currentCount}
            />
          )}
        </Box>
        {page < currentCount / PAGE_SIZE && <div ref={triggerRef} />}
      </Fallback>
    </div>
  );
};
