import { Box, COLORS, SERIF_FONT_FAMILY, toggleStyleValue } from '@clutter/clean';
import styled from '@emotion/styled';
import * as React from 'react';

import { Crop, Fit, Image } from '../../shared/image';

const SMALL_SIZE = '60px';
const LARGE_SIZE = '100px';
const DIMENSIONS = {
  small: {
    naturalWidth: 60, // px
    naturalHeight: 60, // px
    width: SMALL_SIZE,
  },
  large: {
    naturalWidth: 100, // px
    naturalHeight: 100, // px
    width: LARGE_SIZE,
  },
};

type Size = 'small' | 'large';

interface IAvatarProps {
  initials: string;
  imageURL?: string | null;
  size: Size;
}

const Container = styled.div<{ bordered: boolean; size?: Size }>`
  overflow: hidden;
  user-select: none;
  text-align: center;
  border-radius: 50%;
  background: ${COLORS.tealBackground};
  border: ${toggleStyleValue('bordered', `2px solid ${COLORS.tealPrimary}`, 'none')};

  ${({ size }) =>
    size === 'large'
      ? {
          height: LARGE_SIZE,
          width: LARGE_SIZE,
          lineHeight: LARGE_SIZE,
        }
      : {
          height: SMALL_SIZE,
          width: SMALL_SIZE,
          lineHeight: SMALL_SIZE,
        }};
`;

const Initials = styled.span<{ size: Size }>`
  font-family: ${SERIF_FONT_FAMILY};
  font-size: ${({ size }) => (size === 'small' ? '24px' : '40px')};
`;

const Avatar = ({ size, initials, imageURL }: IAvatarProps) => {
  return (
    <Container size={size} bordered={!imageURL}>
      {imageURL ? (
        <Image fit={Fit.Crop} crop={Crop.Faces} source={imageURL} {...DIMENSIONS[size]} />
      ) : (
        <Initials size={size}>{initials}</Initials>
      )}
    </Container>
  );
};
export { Avatar };
