import * as React from 'react';

import { IMap, mapbox } from '../../libraries/mapbox';

import { Context } from './context';
import { AttributionControl } from 'mapbox-gl';

const DEFAULT_LATITUDE = 37.7577;
const DEFAULT_LONGITUDE = -122.4376;
const DEFAULT_WIDTH = 640;
const DEFAULT_HEIGHT = 480;
const DEFAULT_ZOOM = 9;

const DEFAULT_STYLE = 'mapbox://styles/clutter/ckkn87qrj032217lbtogxpc6i';

interface IMapProps {
  width?: number;
  height?: number;
  style?: string;
  latitude?: number;
  longitude?: number;
  zoom?: number;
}

interface IMapState {
  map?: IMap;
  loaded: boolean;
}

class Map extends React.Component<IMapProps, IMapState> {
  private container?: HTMLElement;

  constructor(props: IMapProps) {
    super(props);
    this.state = { map: undefined, loaded: false };
  }

  public render() {
    const style: React.CSSProperties = {
      width: this.props.width || DEFAULT_WIDTH,
      height: this.props.height || DEFAULT_HEIGHT,
    };
    const { loaded, map } = this.state;
    return (
      <React.Fragment>
        <div style={style} ref={this.reference} />
        {loaded && map && <Context.Provider value={{ map }} children={this.props.children} />}
      </React.Fragment>
    );
  }

  public componentDidMount() {
    if (this.container) {
      const map = new mapbox.Map({
        container: this.container,
        style: this.props.style || DEFAULT_STYLE,
        center: [this.props.longitude || DEFAULT_LONGITUDE, this.props.latitude || DEFAULT_LATITUDE],
        zoom: this.props.zoom || DEFAULT_ZOOM,
        attributionControl: false,
        logoPosition: 'top-left',
      });
      map.addControl(new AttributionControl(), 'top-right');
      map.on('load', () => {
        this.setState({ loaded: true });
      });
      this.setState({ map });
    }
  }

  public componentWillUnmount() {
    const { map } = this.state;
    if (map) {
      map.remove();
    }
  }

  public componentDidUpdate(prior: IMapProps) {
    if (this.props.width !== prior.width || this.props.height !== prior.height) {
      const { map } = this.state;
      if (map) {
        map.resize();
      }
    }
  }

  private reference = (element: HTMLElement | null) => {
    this.container = element || undefined;
  };
}

export { Map };
