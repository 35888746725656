import * as React from 'react';

const SINGULAR_COUNT = 1;

const Pluralize = ({ count, singular, plural }: { count: number; singular: string; plural: string }) => (
  <React.Fragment>
    {count} {count === SINGULAR_COUNT ? singular : plural}
  </React.Fragment>
);

export { Pluralize };
