import { PlanProgress } from '@application/components/plan_progress';
import { Fallback } from '@application/components/shared/fallback';
import { useOrderContext } from '@application/contexts/order';
import {
  OrderTypeEnum,
  OrderSubtypeEnum,
  useLaborClocksQuery,
  usePlanAdjustmentsQuery,
} from '@application/platform_schema';
import { Box, COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { Agenda } from '../agenda/agenda';
import { IssueResolution } from '../issue_resolution/issue_resolution';
import { useProgressContext } from '../shared/progress_context';
import { DayOfInvoicesTotal } from './root/day_of_invoices';
import { Footer } from './root/footer';
import { Hero } from './root/hero';
import { Items } from './root/items';
import { JobStatus } from './root/job_status';
import { PlanAdjustment } from './root/plan_adjustment';

const CardDivider = styled.div`
  height: 16px;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 0px 0px;
  margin: 32px -24px;
  position: relative;

  &::after {
    content: ' ';
    display: block;
    width: 100%;
    height: 8px;
    position: relative;
    top: 100%;
    background: ${COLORS.cloud};
  }

  @media (min-width: 500px) {
    box-shadow: none;
    margin: 16px;
  }
`;

const LineDivider = styled.hr`
  border: 1px solid ${COLORS.grayBorder};
  border-style: solid none none none;
  margin: 32px 0;
  background: none;
`;

export const Root: React.FC<{ onCompleted(): void }> = ({ onCompleted }) => {
  const { data, error } = useLaborClocksQuery();
  const {
    app: { order, latestSubscriptionInvoices },
    hasPickup,
  } = useOrderContext();

  const { setProgress } = useProgressContext();
  useEffect(() => {
    setProgress(null);
  }, [setProgress]);

  if (error) throw error;

  const showStatus = order.type === OrderTypeEnum.Pickup || order.type === OrderTypeEnum.Return;
  const { data: planAdjustmentData } = usePlanAdjustmentsQuery();

  const storageSubscriptions = planAdjustmentData?.planAdjustments.storageSubscriptions || [];
  const planAdjustmentKind = planAdjustmentData?.planAdjustments.planAdjustmentKind;

  const orderInvoices = order.invoices;
  const subscriptionInvoices = latestSubscriptionInvoices;
  const showInvoiceTotal =
    order.type !== OrderTypeEnum.Move &&
    order.type !== OrderTypeEnum.SelfStorageShuttle &&
    (order.subtype === OrderSubtypeEnum.Onboarding ? !!orderInvoices && !!subscriptionInvoices : !!orderInvoices);

  const showItems = order.type === OrderTypeEnum.Pickup || (order.type === OrderTypeEnum.Return && !hasPickup);
  const showPlan =
    order.type !== OrderTypeEnum.Move &&
    order.type !== OrderTypeEnum.SelfStorageShuttle &&
    order.subtype !== OrderSubtypeEnum.Final;
  const showAgenda = order.type === OrderTypeEnum.Pickup;
  const showFooter = order.type === OrderTypeEnum.Pickup || order.type === OrderTypeEnum.Return;

  return (
    <Fallback value={data} loader={<Hero.Loader />}>
      <Box maxWidth="500px" margin={showFooter ? '0 auto' : '0 auto 24px'}>
        <Hero laborClocks={data?.laborClocks || []} onTip={onCompleted} />
        {showStatus && (
          <>
            <CardDivider />
            <JobStatus />
          </>
        )}

        {showItems && (
          <>
            <LineDivider />
            <Items />
          </>
        )}
        {showPlan && (
          <>
            <LineDivider />
            <PlanProgress />
          </>
        )}
        {planAdjustmentKind && order.subtype === OrderSubtypeEnum.Onboarding && (
          <>
            <LineDivider />
            <PlanAdjustment storageSubscriptions={storageSubscriptions} planAdjustmentKind={planAdjustmentKind} />
          </>
        )}
        {showInvoiceTotal && (
          <>
            <LineDivider />
            <DayOfInvoicesTotal orderInvoices={orderInvoices || []} subscriptionInvoices={subscriptionInvoices || []} />
          </>
        )}
        {showAgenda && (
          <>
            <LineDivider />
            <Agenda />
          </>
        )}
        <Box margin="24px 0 0">
          <IssueResolution />
        </Box>
        {showFooter && <Footer onTip={onCompleted} />}
      </Box>
    </Fallback>
  );
};
