import * as React from 'react';
import { Reminder } from './reminder';
import { useOrderContext } from '@application/contexts/order';
import box from '@application/images/box.svg';
import disassembly from '@application/images/disassembly.svg';
import stacked_boxes from '@application/images/stacked_boxes.svg';
import mover from '@application/images/mover.svg';
import mask from '@application/images/mask.svg';
import tennisBall from '@application/images/tennis_ball.svg';
import { OrderTypeEnum } from '@application/platform_schema';

export const MaskReminder = () => (
  <Reminder
    title="Wear a mask"
    description="Don't forget to wear a mask & practice social distancing while your team is working."
    iconURL={mask}
  />
);

export const SecurePetsReminder = () => (
  <Reminder
    title="Secure your pets"
    description="Please make sure your pets are happy and comfortable so our movers can focus on getting your move finished."
    iconURL={tennisBall}
  />
);

const LabelBoxesReminder = () => (
  <Reminder
    title="Label & seal your boxes"
    description="If you already started packing, use a marker to label your boxes to keep track of what’s inside."
    iconURL={box}
    iconSize={56}
  />
);

const ClearPathReminder: React.FC = () => {
  const {
    app: { order },
    hasParked,
  } = useOrderContext();

  if (hasParked) return null;

  const isReturn = order.type === OrderTypeEnum.Return;

  return (
    <Reminder
      title="Clear a path"
      description={`Clear a path so it's easy for our team to move your items ${
        isReturn ? 'into' : 'out of'
      } your home.`}
      iconURL={mover}
    />
  );
};

export const PickupReminders = () => {
  const { hasParked } = useOrderContext();
  return (
    <>
      {!hasParked && <LabelBoxesReminder />}
      {!hasParked && (
        <Reminder
          title="Disassemble your furniture"
          description="Make sure you've disassembled all of your furniture."
          iconURL={disassembly}
        />
      )}
      <Reminder
        title="Group your items together"
        description="Organize your items in one place, either outside or near the entrance to your home or apartment building."
        iconURL={stacked_boxes}
      />
      <MaskReminder />
      <SecurePetsReminder />
    </>
  );
};

export const PackingReminders = () => {
  return (
    <>
      <Reminder
        title="Get organized"
        description="Make sure all your items are ready to go. Separate or label any items going into storage from what's staying behind."
        iconURL={stacked_boxes}
      />
      <ClearPathReminder />
      <LabelBoxesReminder />
      <MaskReminder />
      <SecurePetsReminder />
    </>
  );
};

export const FullServiceReturnReminders = () => {
  return (
    <>
      <ClearPathReminder />
      <MaskReminder />
      <SecurePetsReminder />
    </>
  );
};

export const MovingReminders = () => {
  return (
    <>
      <LabelBoxesReminder />
      <Reminder
        title="Get organized"
        description="Decide what’s moving and what’s staying. Keep items you want moved together."
        iconURL={stacked_boxes}
      />
      <Reminder
        title="Clear a path"
        description="Clear a path so it’s easy for our team to move your items out of your home"
        iconURL={mover}
      />
      <MaskReminder />
      <SecurePetsReminder />
    </>
  );
};
