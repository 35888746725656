import * as cn from 'classnames';
import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Box, ButtonLink, Text } from '@clutter/clean';

import { useShareQuery, useExperienceReviewQuery, Rating } from '@application/platform_schema';
import { useWTEvent } from '../wt/event';

const CLICK_REVIEW_LINK_EVENT = {
  action: 'click',
  container: 'hero',
  objectName: 'yelp',
  objectType: 'link',
};

const Share: React.FC = () => {
  const { data } = useShareQuery();
  const share = data && data.share;

  const track = useWTEvent(CLICK_REVIEW_LINK_EVENT);

  if (!share) {
    return null;
  }
  const { text, url } = share;

  const provider = (() => {
    switch (text) {
      case 'Google':
        return 'google';
      case 'Yelp':
        return 'yelp';
      default:
        return 'share';
    }
  })();

  return (
    <ButtonLink kind="secondary" fullWidth title={text ?? 'Share'} target="_blank" href={url} onClick={() => track({})}>
      Review us on {text} <FontAwesomeIcon icon={['fab', provider]} />
    </ButtonLink>
  );
};

export const Review: React.FC = () => {
  const { data, error } = useExperienceReviewQuery();
  if (error) {
    throw error;
  }
  if (!data || !data.experienceReview) {
    return null;
  }
  const experienceReview = data.experienceReview;

  if (experienceReview && experienceReview.rating !== Rating.Good) {
    return null;
  }

  return (
    <Box>
      <Box margin="0 0 16px">
        <Text.Title size="large">Are you a fan of Clutter?</Text.Title>
      </Box>
      <Box margin="16px 0 32px">
        <Text.Body>Help us reach more customers like you and make their lives more convenient.</Text.Body>
      </Box>
      <Box>
        <Share />
      </Box>
    </Box>
  );
};
