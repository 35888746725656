export const SCHEDULED_LOCALE = 'en-US';
export const TIME_OPTIONS = {
  hour: 'numeric',
  minute: 'numeric',
} as const;
export const DATE_OPTIONS = {
  weekday: 'long',
  month: 'long',
  day: 'numeric',
} as const;
