import * as React from 'react';

import { Duration } from 'moment';

import { Pluralize } from './pluralize';

interface IPadding {
  left?: string;
  right?: string;
}

const DEFAULT_PADDING = { left: ' ', right: ' ' };

enum Unit {
  Hours,
  Minutes,
}

const MAPPINGS = {
  [Unit.Hours]: { singular: 'hour', plural: 'hours' },
  [Unit.Minutes]: { singular: 'minute', plural: 'minutes' },
};

const DEFAULT_UNITS = [Unit.Hours, Unit.Minutes];

const components = (duration: Duration) => {
  const parts: Array<{ singular: string; plural: string; count: number }> = [];
  for (const unit of DEFAULT_UNITS) {
    const { ...inflections } = MAPPINGS[unit];
    const count = (() => {
      switch (unit) {
        case Unit.Hours:
          return Math.floor(duration.asHours());
        case Unit.Minutes:
          return duration.minutes();
      }
    })();
    if (count) {
      parts.push({ ...inflections, count });
    }
  }
  return parts;
};

const Humanize = ({ duration, padding = DEFAULT_PADDING }: { duration: Duration; padding?: IPadding }) => {
  const parts = components(duration);
  if (parts.length) {
    return (
      <React.Fragment>
        {parts.map((part, index) => (
          <React.Fragment key={index}>
            {padding.left}
            <Pluralize {...part} />
            {padding.right}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  } else {
    return <React.Fragment>no time</React.Fragment>;
  }
};

export { Humanize, Unit };
