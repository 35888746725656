import { useOrderContext } from '@application/contexts/order';
import { LaborClockFragment, OrderTypeEnum, useLaborClocksQuery } from '@application/platform_schema';
import { Box, Text, Button, SkeletonLoader, COLORS } from '@clutter/clean';
import React from 'react';
import { Avatars } from '../tip/avatars';
import confetti from 'canvas-confetti';
import { useEffect } from 'react';

import affirmLogo from '@application/images/affirm_logo.svg';
import { ButtonAnchor } from '@application/components/shared/button_link';
import { getPaymentDueDate } from '@application/utilities/financing';
import { DATE_OPTIONS, SCHEDULED_LOCALE } from '@application/utilities/arrival_window';

function resolveCopy(type: OrderTypeEnum): { subTitle: React.ReactNode } {
  switch (type) {
    case OrderTypeEnum.Pickup:
      return {
        subTitle:
          'Your team has finished packing & loading your items onto the truck. Your items are now headed to our warehouse for storage.',
      };
    case OrderTypeEnum.Return:
      return {
        subTitle: 'Your team has finished dropping off the items you requested.',
      };
    case OrderTypeEnum.Move:
    case OrderTypeEnum.SelfStorageShuttle:
      return {
        subTitle: 'Your appointment is completed! Thank you for trusting Clutter with your move today.',
      };
    case OrderTypeEnum.RetailDelivery:
      return {
        subTitle: 'Your Supply Kit has been dropped off! We look forward to your upcoming appointment.',
      };
    default: {
      throw new Error(`Unsupported order type: ${type}`);
    }
  }
}

let confettiLaunched = false;

const launchConfetti = (velocity: number) => {
  const baseOptions = {
    colors: [COLORS.dew, COLORS.dust, COLORS.tiger, COLORS.sunshine],
    particleCount: 50,
    spread: 70,
    startVelocity: velocity,
    gravity: 1.5,
    disableForReducedMotion: true,
  };

  // Left launcher
  confetti({
    ...baseOptions,
    origin: { y: 0.5, x: 0 },
    angle: 80,
    drift: 1,
  });
  // Top launcher
  confetti({
    ...baseOptions,
    origin: { y: 0.5, x: 1 },
    angle: 110,
    drift: -1,
  });
  // Right launcher
  confetti({
    ...baseOptions,
    particleCount: 50,
    spread: 120,
    origin: { y: 0, x: 0.5 },
    angle: 90,
  });
};

const Loader = () => {
  const { app } = useOrderContext();
  if (app.order.financingIntent) return null;

  return (
    <Box height="392px" textAlign="center">
      <Box margin="4px 0 40px">
        <Text.Title size="large">Congrats!</Text.Title>
      </Box>
      <SkeletonLoader width="200px" height="100px" borderRadius="50px" margin="0 auto 28px" />
      <SkeletonLoader width="284px" height="18px" margin="0 auto 10px" />
      <SkeletonLoader width="284px" height="18px" margin="0 auto 10px" />
      <SkeletonLoader width="284px" height="18px" margin="0 auto 10px" />
      <SkeletonLoader width="200px" height="18px" margin="0 auto 34px" />
      <SkeletonLoader width="100%" height="48px" />
    </Box>
  );
};

const Hero: React.FC<{ laborClocks: LaborClockFragment[]; onTip(): void }> = ({ onTip, laborClocks }) => {
  const { app } = useOrderContext();

  useEffect(() => {
    if (!confettiLaunched) {
      setTimeout(() => {
        launchConfetti(20);
      }, 400);
      setTimeout(() => {
        launchConfetti(40);
      }, 500);
      confettiLaunched = true;
    }
  }, []);

  const { subTitle } = resolveCopy(app.order.type);

  const formattedDueDate = getPaymentDueDate(app.order.scheduled).toLocaleDateString(SCHEDULED_LOCALE, DATE_OPTIONS);

  return app.order.financingIntent?.intended ? (
    <Box margin="-8px 0 0" textAlign="center">
      <Text.SmallCaps>Congrats!</Text.SmallCaps>
      <Box margin="8px 0 24px">
        <Text.Title size="medium">
          Complete your payment <br />
          with <img src={affirmLogo} />
        </Text.Title>
      </Box>
      <Text.Body>
        Thank you for trusting Clutter with your move today. Make sure to complete your payment with Affirm by{' '}
        {formattedDueDate}.
      </Text.Body>
      <Box.Flex
        margin="24px auto 0"
        maxWidth="327px"
        flexDirection="column"
        height="112px"
        justifyContent="space-between"
      >
        <ButtonAnchor href={app.order.financingIntent.paymentURL} target="_blank">
          Pay with Affirm
        </ButtonAnchor>
        <Button fullWidth kind="secondary" onClick={() => onTip()}>
          Tip and Review Your Team
        </Button>
      </Box.Flex>
    </Box>
  ) : (
    <Box textAlign="center">
      <Box margin="4px 0 40px">
        <Text.Title size="large">Congrats!</Text.Title>
      </Box>
      <Avatars tippables={laborClocks} />
      <Box margin="24px 0 32px">
        <Text.Headline>{subTitle}</Text.Headline>
      </Box>
      <Button fullWidth onClick={() => onTip()}>
        Tip and Review Your Team
      </Button>
    </Box>
  );
};

const Combined = Object.assign(Hero, { Loader });

export { Combined as Hero };
