import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS, Text, Box, transparentize, Icon } from '@clutter/clean';

enum AgendaStepState {
  Unstarted = 'unstarted',
  InProgress = 'inProgress',
  Completed = 'completed',
}

interface IAgendaStepProps {
  title: string;
  description: string;
  state: AgendaStepState;
}

const PreWrapText = styled(Text.Callout)`
  white-space: pre-wrap;
`;

const AgendaStep = ({ title, description, state }: IAgendaStepProps) => {
  const iconColor = state == AgendaStepState.Completed ? COLORS.cloud : transparentize(COLORS.tealPrimary, 0.3);
  const iconBackground = state == AgendaStepState.Completed ? COLORS.tealPrimary : COLORS.tealBackground;
  const iconLoading = state == AgendaStepState.InProgress;

  return (
    <Box.Flex padding="0 0 24px 0">
      <Box.Flex padding="0 16px 0 0">
        <Icon.CircleCheck color={iconColor} backgroundColor={iconBackground} loading={iconLoading} />
      </Box.Flex>
      <Box.Flex flexDirection={['column']} color={COLORS.panther}>
        <Text.Title size="extraSmall">{title}</Text.Title>
        {state != AgendaStepState.Completed && (
          <Box padding="8px 0 0 0">
            <PreWrapText>{description}</PreWrapText>
          </Box>
        )}
      </Box.Flex>
    </Box.Flex>
  );
};

export { AgendaStep, AgendaStepState };
