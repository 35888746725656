import React, { useState } from 'react';

import { COLORS, transparentize } from '@clutter/clean';
import styled from '@emotion/styled';
import { useTransition, animated } from 'react-spring';

import { useActionCableSubscription } from '@application/hooks/use_action_cable_subscription';
import {
  CustomerItemFragment,
  useItemsRecentQuery,
  useItemsPaginatedQuery,
  OrderTypeEnum,
} from '@application/platform_schema';
import { uniqueId } from '@application/utilities/customer_item';

import { ItemPreviewCard, CARD_HEIGHT } from '../shared/item_preview_card';
import { useItemSelectionContext } from '@application/contexts/item_selection';

const PADDED_CARD_HEIGHT = CARD_HEIGHT + 16;

const CardsContainer = styled.div<{ cards: number }>`
  padding: 0 0 16px;
  margin: -8px -16px 0;
  position: relative;
  min-height: ${({ cards }) => PADDED_CARD_HEIGHT * (cards || 1) + 16}px;
  max-height: ${CARD_HEIGHT * 5}px;
  overflow: hidden;
  transition: min-height 0.4s ease-in-out;

  &::after {
    content: ' ';
    width: 100%;
    height: 16px;
    background: linear-gradient(0deg, ${COLORS.cloud}, ${transparentize(COLORS.cloud, 0)});
    position: absolute;
    bottom: 0;
  }
`;

const CardContainer = styled(animated.div)`
  position: absolute;
  top: 0px;
  padding: 8px 0;
  left: 16px;
  right: 16px;
`;

function easeOutBack(t: number): number {
  const c1 = 1.70158;
  const c3 = c1 + 1;
  return 1 + c3 * Math.pow(t - 1, 3) + c1 * Math.pow(t - 1, 2);
}

// react-spring doesn't support the delay property with `useTransition` which is
// needed to prevent the entering item from overlapping the other items. if this
// is added or better documentation around `useChain` is added, this can likely
// be removed in favor of consistent spring animation.
const delayedEaseOutBack = (t: number) => (t < 0.5 ? 0 : easeOutBack((t - 0.5) / 0.5));

const resolveConfig = (idx: number, hasLoaded: boolean) => {
  const useCustomConfig = hasLoaded && idx !== 0;
  return useCustomConfig
    ? {
        tension: 250,
        friction: 30,
      }
    : {
        duration: 500,
        easing: delayedEaseOutBack,
      };
};

type ExtendedItem = CustomerItemFragment;
type LoadingItem = { loading: true; idx: number };
type MaybeItem = ExtendedItem | LoadingItem;

const LoadingItem: MaybeItem = { loading: true, idx: 1 };
const FAKE_ITEMS = [
  { loading: true, idx: 1 } as const,
  { loading: true, idx: 2 } as const,
  { loading: true, idx: 3 } as const,
];

export const ItemFeed: React.FC<{ isReturn?: boolean }> = ({ isReturn }) => {
  const [hasAnimated, setHasAnimated] = useState(false);

  const { data, refetch } = useItemsRecentQuery({
    onCompleted: () => setTimeout(() => setHasAnimated(true), 1000),
    skip: !!isReturn,
  });

  const { data: returnData, refetch: refetchReturn } = useItemsPaginatedQuery({
    variables: {
      page: 1,
      per: 5,
      orderType: OrderTypeEnum.Return,
    },
    onCompleted: () => setTimeout(() => setHasAnimated(true), 1000),
    skip: !isReturn,
  });

  useActionCableSubscription({
    channel: 'OrderResetChannel',
    callback: () => {
      refetch();
      refetchReturn();
    },
  });

  // Slice since caching policy may retain more items from all return items view
  const customerItems = isReturn ? returnData?.itemsPaginated.results.slice(0, 5) : data?.itemsRecent;
  const { setSelectedItem } = useItemSelectionContext(customerItems || []);

  const items: MaybeItem[] = customerItems || FAKE_ITEMS;

  const transitions = useTransition(
    items.map((item, idx) => ({ ...item, idx: idx++ })),
    (item) => ('loading' in item ? item.idx : uniqueId(item)),
    {
      from: ({ idx }) => ({
        opacity: '0',
        transform: `translateY(${idx * 100}%) scale(0.8)`,
      }),
      enter: ({ idx }) => ({
        opacity: '1',
        transform: `translateY(${idx * 100}%)) scale(1)`,
      }),
      update: ({ idx }) => ({
        transform: `translateY(${idx * 100}%)  scale(1)`,
      }),
      leave: ({ idx }) => ({
        opacity: '0',
        transform: `translateY(${idx * 100}%) scale(1)`,
      }),
      config: (item) => resolveConfig(item.idx, hasAnimated),
    },
  );

  return (
    <div>
      <CardsContainer cards={items.length}>
        {transitions.map(({ props, item, key }) => (
          <CardContainer
            key={key}
            style={{
              opacity: props.opacity,
              transform: props.transform,
            }}
          >
            {'loading' in item ? <ItemPreviewCard.Loader /> : <ItemPreviewCard item={item} onClick={setSelectedItem} />}
          </CardContainer>
        ))}
      </CardsContainer>
    </div>
  );
};
