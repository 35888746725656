import * as React from 'react';

import { Box } from '@clutter/clean';

import { Redirect, Route, Switch } from 'react-router-dom';
import { Overview } from './summary/overview';
import { useOrderContext } from '@application/contexts/order';

const Summary: React.FC = () => {
  const { token } = useOrderContext();
  return (
    <Box maxWidth="500px" margin="0 auto">
      <Route path="/a/:token/summary" exact component={Overview} />
    </Box>
  );
};

export { Summary };
