import React, { useState } from 'react';
import { Radio, InfoModal, Box, Text, TextButton, Button } from '@clutter/clean';
import { ItemSortEnum } from '@application/platform_schema';

const OPTIONS = [
  { label: 'Newest items first', value: ItemSortEnum.Newest },
  { label: 'Size: big to small', value: ItemSortEnum.CuftDesc },
  { label: 'Size: small to big', value: ItemSortEnum.CuftAsc },
];

const DownCaret = () => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
    <path d="M1 1L6 6L11 1" stroke="#097E77" strokeWidth="2" />
  </svg>
);

export const SortBy: React.FC<{
  sort: ItemSortEnum;
  onSelectSort(sort: ItemSortEnum): void;
}> = ({ sort: currentSort, onSelectSort }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sort, setSort] = useState(currentSort);
  return (
    <>
      <TextButton size="small" onClick={() => setIsOpen(true)}>
        Sort By &nbsp;
        <DownCaret />
      </TextButton>
      <InfoModal isOpen={isOpen} handleModalClose={() => setIsOpen(false)}>
        <Box textAlign="center" margin="0 0 24px">
          <Text.Title size="extraSmall">Sort by</Text.Title>
        </Box>
        <Radio.Selector value={sort} name="sort" options={OPTIONS} onChange={setSort} />
        <Button
          fullWidth
          onClick={() => {
            onSelectSort(sort);
            setIsOpen(false);
          }}
        >
          Apply
        </Button>
      </InfoModal>
    </>
  );
};
