const MISSING_PAGENAME_MESSAGE = 'Unable to generate today page name: Invalid pathname';

const getTodayAppPageName = (pathname?: string) => {
  switch (pathname) {
    case '/canceled':
      return 'canceled';
    case '/rescheduled':
      return 'rescheduled';
    case '/summary':
      return 'summary';
    case '/tracker':
      return 'tracker';
    case '/rate':
      return 'rate';
    case '/rate/experience':
      return 'rate:experience';
    case '/rate/team':
      return 'rate:team';
    case '/rate/general':
      return 'rate:general';
    case '/rate/tip':
      return 'rate:tip';
    case '/thanks':
      return 'thanks';
  }
};

export const getPageName = () => {
  if (typeof window === 'undefined') {
    return 'unknown';
  }

  if (!window.location.pathname.startsWith('/a/')) {
    return 'today:unknown';
  }

  // Ensures no trailing slash
  const fullPath = window.location.pathname.replace(/\/$/, '');

  const re = /\/a\/[^/]+(\/.+)?/;
  const match = re.exec(fullPath);
  const path = match?.[1];

  const resolvedPageName = getTodayAppPageName(path);

  if (resolvedPageName) {
    return `today:${resolvedPageName}`;
  } else if (process.env.NODE_ENV === 'production') {
    return 'today:unknown';
  } else {
    throw new Error(MISSING_PAGENAME_MESSAGE);
  }
};
