import React from 'react';
import { Box, SpinDotsLoader } from '@clutter/clean';
import { useEffect, useState } from 'react';
import { Fallback } from './fallback';

export enum Fit {
  Crop = 'crop',
  Fill = 'fill',
}

export enum Crop {
  Faces = 'faces',
  Focalpoint = 'focalpoint',
}

const DEFAULT_FM = 'jpg';

interface ImageProps {
  source: string;
  /** Pixel width of the image at 1x resolution */
  naturalWidth: number;
  /** Pixel height of the image at 1x resolution */
  naturalHeight: number;
  /** CSS width of the image's container */
  width?: string;
  /** CSS height of the image's container */
  height?: string;
  className?: string;
  background?: string;
  fit?: Fit;
  crop?: Crop;
  initialLoaded?: boolean;
}

const RATIOS = [
  1, // i.e. 1 × density (default)
  2, // i.e. 2 × density
  3, // i.e. 3 × density
];

// NOTE: uses the imgix formatting options documented here: https://docs.imgix.com/apis/url/format
const format = (source: string, w: number, h: number, bg?: string, fit?: string, crop?: string) => {
  const options: any = { w, h, bg, fit, crop, fm: DEFAULT_FM };
  const query = Object.keys(options)
    .filter((key) => options[key] !== undefined)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(options[key])}`)
    .join('&');
  return `${source}?${query}`;
};

const ImgixImage = ({
  source,
  naturalWidth,
  naturalHeight,
  width,
  height,
  background,
  fit,
  crop,
  className,
  initialLoaded = true,
}: ImageProps) => {
  const [loaded, setLoaded] = useState(initialLoaded);
  const [fixedInitialLoaded] = useState(initialLoaded);
  const src = format(source, naturalWidth, naturalHeight, background, fit, crop);

  useEffect(() => {
    let mounted = true;
    if (!fixedInitialLoaded) {
      const loadImage = () => {
        const img = new Image();
        const onLoad = () => {
          mounted && setLoaded(true);
        };
        const onError = () => setTimeout(() => mounted && loadImage(), 10000);
        img.onload = onLoad;
        img.onerror = onError;
        img.src = src;
      };
      loadImage();
    }
    return () => {
      mounted = false;
    };
  }, [src, fixedInitialLoaded]);

  return (
    <Box.Flex width={width} height={height} justifyContent="center" alignItems="center" className={className}>
      <Fallback loader={<SpinDotsLoader />}>
        {loaded && (
          <img
            src={src}
            draggable={false}
            srcSet={RATIOS.map(
              (r) => `${format(source, naturalWidth * r, naturalHeight * r, background, fit, crop)} ${r}x`,
            ).join(', ')}
            width={width}
            height={height}
          />
        )}
      </Fallback>
    </Box.Flex>
  );
};

export { ImgixImage as Image };
