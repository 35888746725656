import { CustomerItemFragment } from '@application/platform_schema';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// A signifier of the item can be present from the query string with these properties (minus ID)
type MaybeCustomerItem = Pick<CustomerItemFragment, 'uuid' | '__typename'> & { id?: string };

interface ItemSelectionContextValue {
  items: Array<MaybeCustomerItem>;
  setItems: (items: Array<CustomerItemFragment>) => void;
  selectedItem: MaybeCustomerItem | null;
  setSelectedItem: (item: CustomerItemFragment) => void;
  nextItem?: CustomerItemFragment;
}

const ItemSelectionContext = createContext<ItemSelectionContextValue | undefined>(undefined);

export const useItemSelectionContext = (items?: CustomerItemFragment[]) => {
  const value = useContext(ItemSelectionContext);
  if (!value) throw new Error('No ItemSelectionContextProvider found');

  const { setItems } = value;

  useEffect(() => {
    if (items) setItems(items);
  }, [items, setItems]);

  return value;
};

export const ItemSelectionContextProvider: React.FC = ({ children }) => {
  const [items, setItems] = useState<CustomerItemFragment[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const location = useLocation();
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState<MaybeCustomerItem | null>(() => {
    if (!location.search) return null;

    const params = new URLSearchParams(location.search);
    const typename = params.get('group') ? 'ItemGroup' : 'Item';
    const uuid = params.get('uuid') || '';

    return {
      uuid,
      __typename: typename,
    };
  });

  useEffect(() => {
    if (!location.search) setSelectedItem(null);
  }, [location]);

  const setItem = (item: MaybeCustomerItem) => {
    const params = new URLSearchParams({ uuid: item.uuid });
    if (item.__typename === 'ItemGroup') params.append('group', 'true');
    const path = `${location.pathname}?${params.toString()}`;

    if (location.search) {
      history.replace(path);
    } else {
      history.push(path);
    }
    setSelectedIndex(items.findIndex((i) => i.__typename === item.__typename && i.id === item.id));
    setSelectedItem(item);
  };

  return (
    <ItemSelectionContext.Provider
      value={{
        items,
        setItems,
        selectedItem,
        setSelectedItem: setItem,
        nextItem: items[selectedIndex + 1],
      }}
    >
      {children}
    </ItemSelectionContext.Provider>
  );
};
