import { useInterval } from '@application/hooks/use_interval';
import { AppFragment, OrderTypeEnum } from '@application/platform_schema';
import { isCheckinTimestampStale } from '@application/utilities/tracker';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface OrderContextValue {
  token: string;
  app: AppFragment;
  staleCheckin: boolean;
  hasWalkedThrough?: boolean;
  hasParked?: boolean;
  hasSignedApproval?: boolean;
  hasEnded?: boolean;
  hasPickup: boolean;
  hasReturn: boolean;
}

const OrderContext = createContext<OrderContextValue | undefined>(undefined);

export const useOrderContext = () => {
  const value = useContext(OrderContext);
  if (!value) {
    throw new Error('OrderContext accessed without provider.');
  }
  return value;
};

const buildOrderEvents = (app: AppFragment) =>
  app.order.orderEvents
    .filter((event) => event.category === 'linear_orders')
    .reduce<Record<string, true>>((acc, event) => {
      acc[event.eventName] = true;
      return acc;
    }, {});

const buildServices = (app: AppFragment) => ({
  hasPickup: app.order.services.includes(OrderTypeEnum.Pickup),
  hasReturn: app.order.services.includes(OrderTypeEnum.Return),
});

export const OrderContextProvider: React.FC<{ app: AppFragment }> = ({ app, children }) => {
  const [value, setValue] = useState<OrderContextValue>({
    app,
    token: app.token,
    staleCheckin: true,
    ...buildServices(app),
  });
  const [staleCheckin, setStaleCheckin] = useState(true);

  const checkin = app.order.lastCheckin;
  useInterval(() => setStaleCheckin(!checkin || isCheckinTimestampStale(checkin.timestamp)), 1000);

  useEffect(() => {
    const events = buildOrderEvents(app);

    setValue({
      app,
      token: app.token,
      staleCheckin,
      hasWalkedThrough: !!events.walked_through,
      hasParked: !!events.parked,
      hasSignedApproval: !!events.signed_approval,
      hasEnded: !!events.ended,
      ...buildServices(app),
    });
  }, [app, staleCheckin]);

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
};
