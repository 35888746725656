import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface IResizerProps {
  render(size: { width: number; height: number }): React.ReactNode;
}

interface IResizerState {
  width: number;
  height: number;
}

const DEFAULT_WIDTH = 0;
const DEFAULT_HEIGHT = 0;

class Resizer extends React.Component<IResizerProps, IResizerState> {
  constructor(props: IResizerProps) {
    super(props);

    this.state = {
      width: DEFAULT_WIDTH,
      height: DEFAULT_HEIGHT,
    };
  }

  public render() {
    return <div>{this.props.render(this.state)}</div>;
  }

  public componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    this.resize();
  }

  private resize = () => {
    const element = ReactDOM.findDOMNode(this);

    if (element instanceof Element) {
      const rect = element.getBoundingClientRect();
      this.setState({
        width: rect.width,
        height: rect.height,
      });
    }
  };
}

export { Resizer };
