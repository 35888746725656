import React from 'react';
import { Box, COLORS, FontWeight, Text } from '@clutter/clean';

import { OrderTypeEnum, useItemCountQuery, useItemsPaginatedQuery } from '@application/platform_schema';
import { Pluralize } from '@application/components/formatters';
import { useOrderContext } from '@application/contexts/order';
import { Link } from '@application/components/shared/link';
import { imageFor } from '@application/utilities/customer_item';
import { Image } from '@application/components/shared/image';

export const Items: React.FC = () => {
  const {
    app: { token },
    hasPickup,
    hasReturn,
  } = useOrderContext();

  const inputOrderType = hasPickup ? OrderTypeEnum.Pickup : OrderTypeEnum.Return;

  const { data: itemCountData, error: itemCountError } = useItemCountQuery({
    variables: { orderType: inputOrderType },
  });
  const { data, error } = useItemsPaginatedQuery({ variables: { page: 0, per: 4, orderType: inputOrderType } });

  if (error || itemCountError) throw error || itemCountError;
  if (!data?.itemsPaginated.results || itemCountData?.itemCount === undefined) return null;

  const recentItems = data.itemsPaginated.results.slice(0, 4);
  const { itemCount } = itemCountData;

  const allItemsPath = hasReturn ? `/a/${token}/return_items` : `/a/${token}/items`;

  return (
    <>
      <Box.Flex justifyContent="space-between" alignItems="flex-end">
        <Text.Title size="extraSmall">
          <Pluralize count={itemCount} singular="Item" plural="Items" />
        </Text.Title>
        <Box color={COLORS.tealPrimary}>
          <Link to={allItemsPath}>
            <Text.Callout weight={FontWeight.Medium}>View All</Text.Callout>
          </Link>
        </Box>
      </Box.Flex>
      <Box.Flex justifyContent="center" margin="16px -4px 0">
        {recentItems.map((item) => {
          const image = imageFor(item);
          return (
            <Box key={item.uuid} width="75px" height="82px" margin="4px" borderRadius="4px" overflow="hidden">
              <Image
                naturalWidth={75}
                naturalHeight={82}
                width="75px"
                height="82px"
                source={image.source}
                initialLoaded={image.state === 'uploaded'}
              />
            </Box>
          );
        })}
      </Box.Flex>
    </>
  );
};
