import * as React from 'react';

import { DATE_OPTIONS, SCHEDULED_LOCALE } from '@application/utilities/arrival_window';
import { useOrderContext } from '@application/contexts/order';

export const Rescheduled: React.FC = () => {
  const {
    app: {
      order: {
        region: { tz: timeZone },
        scheduled,
        formattedScheduledArrivalWindow,
      },
    },
  } = useOrderContext();
  const scheduledDate = new Date(scheduled);
  const date = scheduledDate.toLocaleDateString(SCHEDULED_LOCALE, DATE_OPTIONS);

  return (
    <p className="title">
      Your order was rescheduled to {date} arriving between {formattedScheduledArrivalWindow}.
    </p>
  );
};
