import { useEffect } from 'react';
import { useLatestCallback } from './use_latest';

export function useInterval(callback: () => void, ms: number) {
  const stableCb = useLatestCallback(callback);

  useEffect(() => {
    const interval = setInterval(() => {
      stableCb();
    }, ms);
    return () => {
      clearInterval(interval);
    };
  }, [stableCb, ms]);
}
