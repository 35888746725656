import * as React from 'react';
import { COLORS, Text, transparentize, Box } from '@clutter/clean';

interface IReminderProps {
  title: string;
  description: React.ReactNode;
  iconURL: string;
  iconSize?: number;
}

const DEFAULT_ICON_SIZE = 64;

const Reminder = ({ title, description, iconURL, iconSize }: IReminderProps) => {
  const size = iconSize ? iconSize : DEFAULT_ICON_SIZE;
  return (
    <Box position="relative" margin="16px 0">
      <Box
        padding="24px 24px 24px 64px"
        minHeight="112px"
        position="relative"
        margin="0 -24px 0 0"
        background={transparentize(COLORS.tealBackground, 0.6)}
      >
        <Box text-align="left" color={COLORS.tealDark}>
          <Text.Title size="extraSmall">{title}</Text.Title>
          <Text.Callout>{description}</Text.Callout>
        </Box>
      </Box>
      <Box position="absolute" top="24px" left="-16px">
        <img src={iconURL} width={size} height={size} />
      </Box>
    </Box>
  );
};

export { Reminder };
