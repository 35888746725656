import React from 'react';
import { Box, Button, COLORS, Text } from '@clutter/clean';
import team from '@application/images/team.svg';

export const Footer: React.FC<{ onTip(): void }> = ({ onTip }) => (
  <Box
    position="absolute"
    width="100%"
    margin="24px 0 0"
    padding="32px 24px 40px"
    left="0"
    background={COLORS.tealBackground}
  >
    <Box.Flex alignItems="center" margin="0 auto" flexDirection="column" maxWidth="500px" textAlign="center">
      <img src={team} height="166" />
      <Box margin="24px  32px">
        <Text.Title size="extraSmall">
          Tell us how your <br />
          appointment went
        </Text.Title>
      </Box>
      <Button fullWidth onClick={() => onTip()}>
        Tip and Review Your Team
      </Button>
    </Box.Flex>
  </Box>
);
