import React from 'react';

import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { COLORS, Icon, UnstyledButton } from '@clutter/clean';

const Back = styled.div`
  margin-top: -12px;
  & svg {
    transform: rotate(180deg);
  }
`;

export const BackNavArrow: React.FC<{ to?: string; onClick?(): void }> = ({ to, onClick }) => {
  return (
    <Back>
      {to ? (
        <Link to={to} onClick={onClick}>
          <Icon.Arrow color={COLORS.tealPrimary} />
        </Link>
      ) : (
        <UnstyledButton onClick={onClick}>
          <Icon.Arrow color={COLORS.tealPrimary} />
        </UnstyledButton>
      )}
    </Back>
  );
};
