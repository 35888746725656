import React from 'react';

import styled from '@emotion/styled';
import moment from 'moment';

import { Box, COLORS, FontWeight, Text } from '@clutter/clean';
import { useTimer } from '@application/components/timer';
import { OrderFragment, WorkBreak } from '@application/platform_schema';

const DEFAULT_INTERVAL_DURATION = 30000;

const Title = styled(Text.Title)`
  color: ${COLORS.tealDark};
  font-weight: ${FontWeight.Medium};
`;

const Callout = styled(Text.Callout)`
  color: ${COLORS.tealDark};
  margin-top: 12px;
`;

const Duration = styled.span`
  color: ${COLORS.tealPrimary};
`;

const resolveDurationCopy = (hours: number, minutes: number) => {
  const hrs = `${hours} ${hours === 1 ? 'hr' : 'hrs'}`;
  const mins = `${minutes || 1} ${minutes <= 1 ? 'min' : 'mins'}`;

  if (hours === 0) {
    return mins;
  } else if (minutes === 0) {
    return hrs;
  } else {
    return hrs + ' and ' + mins;
  }
};

const resolveBreakDurationCopy = (breakStarted: string, now: Date) => {
  const expectedBreakEnd = moment(breakStarted).add(30, 'minutes');
  const minutes = moment.duration(expectedBreakEnd.diff(now)).minutes();

  if (minutes >= 1) {
    return (
      <Title size="small">
        Your team is on a lunch break & will return in about{' '}
        <Duration>
          {minutes} {minutes === 1 ? 'min' : 'mins'}.
        </Duration>
      </Title>
    );
  } else {
    return <Title size="small">Your team is finishing lunch & will return soon</Title>;
  }
};

export const LaborDuration: React.FC<{ order: OrderFragment; workBreaks: WorkBreak[] }> = ({ order, workBreaks }) => {
  const { started, ended } = order;
  const now = useTimer({ durationMs: DEFAULT_INTERVAL_DURATION });

  const [hours, minutes, breakStarted] = React.useMemo(() => {
    // workBreaks includes all of the breaks from the dispatch. In order to accurately calculate the labor duration we need to exclude
    // work breaks that were not started during the order
    const validWorkBreaks =
      workBreaks?.filter(
        (workBreak) =>
          !!workBreak.started &&
          workBreak.started > order.started! &&
          (!order.ended || workBreak.started < order.ended),
      ) || [];
    const completedWorkBreaks = validWorkBreaks?.filter((workBreak) => !!workBreak.started && !!workBreak.ended);
    const activeWorkBreak = validWorkBreaks?.find((workBreak) => !!workBreak.started && !workBreak.ended);

    const totalBreakTime = completedWorkBreaks.reduce((prev, wb) => {
      const duration = moment.duration(moment(wb.ended || now).diff(moment(wb.started || now)));
      return prev.add(duration);
    }, moment.duration(0));

    const duration = moment.duration(moment(ended || now).diff(moment(started || now))).subtract(totalBreakTime);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    return [hours, minutes, activeWorkBreak?.started] as const;
  }, [now, workBreaks, started, ended]);

  return (
    <Box background={COLORS.grayBackground} padding="32px 24px" margin="0 -24px" textAlign="left">
      {breakStarted ? (
        <>
          {resolveBreakDurationCopy(breakStarted, now)}
          <Callout>Don't worry, you won't be charged for this time.</Callout>
        </>
      ) : (
        <Title size="small">
          Your team has been working for <Duration>{resolveDurationCopy(hours, minutes)}.</Duration>
        </Title>
      )}
    </Box>
  );
};
