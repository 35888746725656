import * as React from 'react';

import { Duration } from 'moment';

import { Pad } from './pad';

const HOUR_DIGITS = 1;
const MINUTE_DIGITS = 2;

const HMM = ({ duration }: { duration: Duration }) => {
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  return (
    <React.Fragment>
      <Pad value={hours} digits={HOUR_DIGITS} />:
      <Pad value={minutes} digits={MINUTE_DIGITS} />
    </React.Fragment>
  );
};

export { HMM };
