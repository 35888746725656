import * as React from 'react';
import { COLORS, Text, TextButton, Box } from '@clutter/clean';
import helpBubble from '@application/images/icons/help_bubble.svg';

interface IConfig {
  account_url: string;
}

declare const CONFIG: IConfig;

const ICON_SIZE = 64;
const SUPPORT_HREF = `${CONFIG.account_url}/support/day_of`;

const IssueResolution = () => {
  return (
    <Box.Flex alignItems="center" padding="16px 16px 16px 16px" background={COLORS.grayBackground} borderRadius="4px">
      <Box>
        <img src={helpBubble} width={ICON_SIZE} height={ICON_SIZE} />
      </Box>
      <Box text-align="left" color={COLORS.tealDark} padding="0 0 0 16px">
        <Text.Title size="extraSmall">Still have questions?</Text.Title>
        <TextButton href={SUPPORT_HREF} size="small">
          Let us help
        </TextButton>
      </Box>
    </Box.Flex>
  );
};

export { IssueResolution };
