import * as React from 'react';
import { useContext, useEffect } from 'react';

import { NotificationsContext } from '@application/contexts';
import { INotification } from '@application/types';

export const Notification: React.FC<{
  notification: INotification;
}> = ({ notification }) => {
  const { onNotify } = useContext(NotificationsContext);
  useEffect(() => onNotify(notification), []);
  return null;
};
