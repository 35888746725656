import moment from 'moment';
import * as React from 'react';

import { SCHEDULED_LOCALE, TIME_OPTIONS } from '@application/utilities/arrival_window';
import { Stale } from '../stale';

import { COLORS, Box, Text } from '@clutter/clean';

import styled from '@emotion/styled';
import { Route } from '@application/platform_schema';

interface IArrivalProps {
  route: Route;
  scheduled: string;
  timeZone: string;
  stale: boolean;
  lastCheckinTimestamp?: string;
  workBreakActive: boolean;
}

const ColoredText = styled.span`
  color: ${COLORS.tealPrimary};
`;

const Arrival = ({ route, scheduled, timeZone, stale, lastCheckinTimestamp, workBreakActive }: IArrivalProps) => {
  const options = { ...TIME_OPTIONS, timeZone };

  const routeKind = route.kind;
  const eta = route.eta;

  if (!eta) {
    return null;
  }

  const early = routeKind === 'arrival' ? moment(eta).diff(moment(scheduled)) < 0 : undefined;
  const arrivalTime = moment(eta).toDate().toLocaleTimeString(SCHEDULED_LOCALE, options);

  return (
    <Box margin="0 0 0 0" color={COLORS.panther}>
      <Text.Title size="large">
        Your team will arrive at <ColoredText>{arrivalTime}</ColoredText>
      </Text.Title>
      {workBreakActive && (
        <Box margin="10px 0 20px 0">
          <Text.Headline>
            Your team is taking their required 30 minute lunch break. We've updated your ETA to account for this.
          </Text.Headline>
        </Box>
      )}
      <Box margin="10px 0 0 0">
        {routeKind === 'arrival' && <Text.Headline>We'll text you when your team has arrived.</Text.Headline>}
        {early && (
          <Box margin="10px 0 0 0">
            <Text.Callout>
              Your team is ahead of schedule. Don't worry, they will wait until your arrival window opens if you're not
              ready for us.
            </Text.Callout>
          </Box>
        )}
        {stale && lastCheckinTimestamp ? (
          <Box margin="10px 0 0 0">
            <Stale timestamp={lastCheckinTimestamp} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export { Arrival };
