import * as React from 'react';

import { PlanAdjustmentKindEnum, StorageSubscriptionsFragment } from '@application/platform_schema';
import { Box, COLORS, FontWeight, Text, TextButton, InfoModal, Button } from '@clutter/clean';
import styled from '@emotion/styled';
import piggyBank from '@application/images/piggy_bank.svg';

const LineItem: React.FC<{ featured?: boolean; children: React.ReactNode }> = ({ featured, children }) => (
  <Box padding="0 24px" background={featured ? COLORS.tealBackground : COLORS.grayBackground}>
    <Box.Flex justifyContent="space-between" padding="24px 0">
      {children}
    </Box.Flex>
  </Box>
);

const SavingsComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;

  & > div:first-of-type {
    & > div {
      border-bottom: 1.5px solid ${COLORS.grayBorder};
    }
  }
`;

const DowngradeModalContent: React.FC<{
  savings: number;
  initialPlan: StorageSubscriptionsFragment;
  currentPlan: StorageSubscriptionsFragment;
}> = ({ savings, initialPlan, currentPlan }) => (
  <Box textAlign="center">
    <Text.Title size="large">
      You're saving
      <br />${savings} a month!
    </Text.Title>
    <Box margin="16px 0 18px">
      <Text.Body>We automatically downgraded you into the smaller plan to help you save.</Text.Body>
    </Box>
    <SavingsComparisonContainer>
      <LineItem>
        <Text.Callout>Original plan: {initialPlan.formattedDimensions}</Text.Callout>
        <Text.Callout>${initialPlan.discountedTotal}/mo</Text.Callout>
      </LineItem>
      <LineItem>
        <Text.Callout>New plan: {currentPlan.formattedDimensions}</Text.Callout>
        <Text.Callout>${currentPlan.discountedTotal}/mo</Text.Callout>
      </LineItem>
      <LineItem featured={true}>
        <Text.Callout weight={FontWeight.Medium}>Savings</Text.Callout>
        <Text.Callout weight={FontWeight.Medium}>{`$${savings}/mo`}</Text.Callout>
      </LineItem>
    </SavingsComparisonContainer>
    <Box color={COLORS.storm} margin="16px 0 0">
      <Text.Caption>
        If you end up needing more space in the future, you can always increase the size of your plan.
      </Text.Caption>
    </Box>
  </Box>
);

const UpgradeModalContent: React.FC<{
  initialPlan: StorageSubscriptionsFragment;
  currentPlan: StorageSubscriptionsFragment;
}> = ({ initialPlan, currentPlan }) => (
  <Box textAlign="center">
    <Text.Title size="large">
      Your plan size
      <br />
      has increased
    </Text.Title>
    <Box margin="16px 0 18px">
      <Text.Body>
        Because your items didn’t fit into your original plan, we’ve upgraded you into a larger plan. If you remove
        items in the future, we’ll let you know if you can fit into a smaller plan size.{' '}
      </Text.Body>
    </Box>
    <SavingsComparisonContainer>
      <LineItem>
        <Text.Callout>Original plan: {initialPlan.formattedDimensions}</Text.Callout>
        <Text.Callout>${initialPlan.discountedTotal}/mo</Text.Callout>
      </LineItem>
      <LineItem>
        <Text.Callout weight={FontWeight.Medium}>New plan: {currentPlan.formattedDimensions}</Text.Callout>
        <Text.Callout weight={FontWeight.Medium}>${currentPlan.discountedTotal}/mo</Text.Callout>
      </LineItem>
    </SavingsComparisonContainer>
  </Box>
);

const SemiBold = styled.span`
  font-weight: ${FontWeight.Medium};
`;

const PlanAdjustment: React.FC<{
  planAdjustmentKind: PlanAdjustmentKindEnum;
  storageSubscriptions: StorageSubscriptionsFragment[];
}> = ({ planAdjustmentKind, storageSubscriptions }) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const initialPlan = storageSubscriptions.find((subscription) => !subscription.active);
  const currentPlan = storageSubscriptions.find((subscription) => subscription.active);
  const isDowngrade = planAdjustmentKind === PlanAdjustmentKindEnum.Downgrade;

  if (!initialPlan || !currentPlan) {
    throw new Error('Cannot find initial or current plan');
  }

  const savings = isDowngrade ? initialPlan?.discountedTotal - currentPlan?.discountedTotal : undefined;

  return (
    <Box.Flex borderRadius="4px" background={COLORS.grayBackground} padding="24px 24px 24px 16px">
      {isDowngrade && (
        <Box margin="0 24px 0 0" minWidth="52px">
          <img src={piggyBank} />
        </Box>
      )}
      <Box.Flex flexDirection="column" alignItems="flex-start">
        {isDowngrade ? (
          <>
            <Text.Callout weight={FontWeight.Medium}>You're saving ${savings} a month</Text.Callout>
            <Text.Callout>Your items ended up fitting into a smaller plan size.</Text.Callout>
          </>
        ) : (
          <Text.Callout>
            <SemiBold>Your storage space is flexible.</SemiBold> You ended up needing more space for your items, so we
            moved you into a {currentPlan?.formattedDimensions} plan.
          </Text.Callout>
        )}
        <Box margin="16px 0 0">
          <TextButton onClick={() => setModalOpen(true)}>Learn More</TextButton>
        </Box>
      </Box.Flex>
      <InfoModal isOpen={modalOpen} handleModalClose={() => setModalOpen(false)}>
        {isDowngrade ? (
          <DowngradeModalContent savings={savings!} initialPlan={initialPlan} currentPlan={currentPlan} />
        ) : (
          <UpgradeModalContent initialPlan={initialPlan!} currentPlan={currentPlan!} />
        )}
        <Box margin="24px 0 0">
          <Button size="small" fullWidth onClick={() => setModalOpen(false)}>
            Got it
          </Button>
        </Box>
      </InfoModal>
    </Box.Flex>
  );
};

export { PlanAdjustment };
