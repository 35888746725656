import { EventData, FitBoundsOptions, LngLatBoundsLike, Control, IControl } from 'mapbox-gl';

import { Layer } from './layer';
import { Source } from './source';

import { IMap } from '../types/map';

class Stub implements IMap {
  public static active = new Set<Stub>();

  public options: any;
  public images: Map<string, any>;
  public layers: Map<string, Layer>;
  public sources: Map<string, Source>;
  public resized?: EventData;
  public style?: any;

  public fitting?: {
    bounds: LngLatBoundsLike;
    options?: FitBoundsOptions;
  };

  constructor(options: any) {
    this.options = options;
    this.images = new Map<string, any>();
    this.layers = new Map<string, Layer>();
    this.sources = new Map<string, Source>();
    this.add();
  }

  public addLayer = (layer: any) => {
    if (!this.style) {
      throw new Error('"addLayer" is unsupported if no style is loaded');
    }
    const { id, ...options } = layer;
    this.layers.set(id, new Layer(options));
  };

  public moveLayer = (layer: string, before?: string) => undefined;

  public removeLayer = (id: string) => {
    if (!this.style) {
      throw new Error('"removeLayer" is unsupported if no style is loaded');
    }
    this.layers.delete(id);
  };

  public getLayer = (id: string) => {
    if (!this.style) {
      throw new Error('"getLayer" is unsupported if no style is loaded');
    }
    return this.layers.get(id);
  };

  public addSource = (id: string, options: any) => {
    if (!this.style) {
      throw new Error('"addSource" is unsupported if no style is loaded');
    }
    this.sources.set(id, new Source(options));
  };

  public removeSource = (id: string) => {
    if (!this.style) {
      throw new Error('"removeSource" is unsupported if no style is loaded');
    }
    this.sources.delete(id);
  };

  public getSource = (id: string) => {
    if (!this.style) {
      throw new Error('"getSource" is unsupported if no style is loaded');
    }
    return this.sources.get(id);
  };

  public loadImage = (url: string, callback: (error: Error | null, image: any) => void) => {
    if (!this.style) {
      throw new Error('"getSource" is unsupported if no style is loaded');
    }
    callback(null, url);
  };

  public addImage = (id: string, image: any, options?: any) => {
    if (!this.style) {
      throw new Error('"getSource" is unsupported if no style is loaded');
    }
    this.images.set(id, image);
  };

  public hasImage = (id: string): boolean => {
    if (!this.style) {
      throw new Error('"getSource" is unsupported if no style is loaded');
    }
    return this.images.has(id);
  };

  public removeImage = (id: string): void => {
    if (!this.style) {
      throw new Error('"getSource" is unsupported if no style is loaded');
    }
    this.images.delete(id);
  };

  public addControl = (
    _control: Control | IControl,
    _position?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ): void => {};

  public getStyle = () => {
    return this.style;
  };

  public setStyle = (style: any) => {
    this.style = style;
  };

  public on = (_: string, callback: any) => {
    callback(this);
  };

  public add = () => {
    Stub.active.add(this);
    this.setStyle({});
  };

  public remove = () => {
    Stub.active.delete(this);
    this.setStyle(null);
  };

  public loaded = () => {
    return !!this.options.container;
  };

  public resize = (options?: EventData): void => {
    this.resized = options;
  };

  public fitBounds = (bounds: LngLatBoundsLike, options?: FitBoundsOptions) => {
    this.fitting = {
      bounds,
      options,
    };
  };
}

export { Stub as Map };
