import * as React from 'react';
import { useLocation } from 'react-router';

import wt from '@clutter/wt';

import { WT_ENABLED } from '../../config/wt';

import { Interval } from '../../utilities/interval';
import { useRouteMatch } from 'react-router-dom';

const TIMER_MULTIPLIER = 1000; // the timer is in milliseconds
const PING_INTERVAL = 10; // seconds

export const PageView: React.FC = ({ children }) => {
  const location = useLocation();
  const [interval] = React.useState(() => new Interval(PING_INTERVAL * TIMER_MULTIPLIER));

  const match = useRouteMatch<{ token: string }>('/a/:token');
  const token = match?.params.token;

  React.useEffect(() => {
    if (WT_ENABLED) {
      if (token) {
        wt.set({ today_app_token: token });
      }
      wt.track('pageview');
    }
  }, [location, token]);

  React.useEffect(() => {
    const ping = (ticks: number) => {
      if (WT_ENABLED) {
        wt.track({ action: 'ping', value: ticks * PING_INTERVAL });
      }
    };
    interval.subscribe(ping);

    return () => interval.unsubscribe(ping);
  }, [interval]);

  return <>{children}</>;
};
