type Callback = (ticks: number) => void;

class Interval {
  private duration: number;
  private intervalID?: any;
  private callbacks: Set<Callback>;
  private ticks: number;

  constructor(duration: number) {
    this.duration = duration;
    this.callbacks = new Set();
    this.ticks = 0;
  }

  public subscribe(callback: Callback) {
    if (!this.callbacks.size) {
      this.ticks = 0;
      this.intervalID = setInterval(this.reset, this.duration);
    }

    this.callbacks.add(callback);
  }

  public unsubscribe(callback: Callback) {
    this.callbacks.delete(callback);

    if (!this.callbacks.size) {
      clearInterval(this.intervalID);
      this.ticks = 0;
      delete this.intervalID;
    }
  }

  private reset = () => {
    this.ticks++;
    const callbacks = Array.from(this.callbacks);
    for (const callback of callbacks) {
      callback(this.ticks);
    }
  };
}

export { Interval };
