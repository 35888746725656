import React, { useState } from 'react';

import { Box, COLORS, UnstyledButton, FontWeight, Text, InfoModal, Button } from '@clutter/clean';

export const RemeasureModal = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <InfoModal isOpen={open} handleModalClose={() => setOpen(false)}>
        <Box textAlign="center">
          <Text.Title size="large">How to get an item remeasured</Text.Title>
          <Box margin="16px 0 32px">
            If you believe your item was measured incorrectly, no problem. Simply let your team lead know and request
            that the item be remeasured.
          </Box>
          <Button fullWidth onClick={() => setOpen(false)}>
            Got it
          </Button>
        </Box>
      </InfoModal>
      <Box color={COLORS.tealPrimary}>
        <UnstyledButton onClick={() => setOpen(true)}>
          <Text.Callout weight={FontWeight.Medium}>Want this remeasured?</Text.Callout>
        </UnstyledButton>
      </Box>
    </>
  );
};
