import { useInterval } from '@application/hooks/use_interval';
import * as React from 'react';

const DEFAULT_INTERVAL_DURATION = 5000; // i.e. 5 seconds

type UseTimerOptions = {
  durationMs?: number;
};

export const useTimer = ({ durationMs = DEFAULT_INTERVAL_DURATION }: UseTimerOptions = {}) => {
  const [now, setNow] = React.useState(() => new Date());
  useInterval(() => setNow(new Date()), durationMs);
  return now;
};
