import * as polyline from '@mapbox/polyline';
import * as React from 'react';

import { Layer } from './layer';
import { Source } from './source';

interface ILineProps {
  id: string;
  geometry: string;
  paint: any;
  layout: any;
}

class Line extends React.Component<ILineProps> {
  public render() {
    const { id, geometry, layout, paint } = this.props;

    const data = polyline.toGeoJSON(geometry);

    return (
      <Layer id={id} type="line" source={id} layout={layout} paint={paint}>
        <Source id={id} data={data} type="geojson" lineMetrics={true} />
      </Layer>
    );
  }
}

export { Line };
