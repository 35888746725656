import * as React from 'react';

const Missing = () => (
  <section className="hero">
    <div className="hero-body">
      <div className="container">
        <p className="title">Page Not Found</p>
        <p className="subtitle">No page matches this URL. It might not exist anymore!</p>
      </div>
    </div>
  </section>
);

export { Missing };
