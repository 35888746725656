import * as React from 'react';

import { Notification } from '@application/components/notification';
import { Review } from './review';
import { NotificationKind } from '@application/types';
import { useProgressContext } from './shared/progress_context';

import { Box, ButtonComponent, Text } from '@clutter/clean';
import { useOrderContext } from '@application/contexts/order';
import { useWTEvent } from '../wt/event';

interface IConfig {
  account_url: string;
}

declare const CONFIG: IConfig;
const WELCOME_URL = `${CONFIG.account_url}/welcome/new`;

const CLICK_ACCOUNT_LINK_EVENT = {
  action: 'click',
  container: 'hero',
  objectName: 'account',
  objectType: 'link',
};

const EXPIRED = 'This link has expired. If you still want to tip your movers, please contact Customer Care.';

const ButtonLink = ButtonComponent.withComponent('a');

export const Thanks = () => {
  const { setProgress } = useProgressContext();
  const { app } = useOrderContext();
  const { order } = app;

  let accountHref = WELCOME_URL;
  if (order.customer.initialSetupToken) {
    accountHref = accountHref + '?initial_setup_token=' + order.customer.initialSetupToken;
  }

  React.useEffect(() => {
    setProgress(null);
  }, [setProgress]);

  const track = useWTEvent(CLICK_ACCOUNT_LINK_EVENT);

  return (
    <Box margin="0 0 0 0">
      {app.expired && <Notification notification={{ message: EXPIRED, kind: NotificationKind.Danger }} />}
      <Box textAlign="center" maxWidth="500px" margin="0 auto">
        <Box margin="0 0 24px">
          <Text.Title size="large">Thank You!</Text.Title>
        </Box>
        <Box>
          <ButtonLink fullWidth href={accountHref} target="_blank" onClick={() => track({})}>
            <Box textAlign="center" padding="12px">
              <Text.Button>Go to your Account</Text.Button>
            </Box>
          </ButtonLink>
        </Box>
        <Box margin="48px 0 32px">
          <Review />
        </Box>
      </Box>
    </Box>
  );
};
