import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

import { CSRF_TOKEN } from '@application/config/csrf';
import { JWT } from '@application/config/jwt';
import fragmentData from '@application/platform_fragments';
import { createInfiniteScrollFieldPolicy } from './apollo/pagination';

interface IConfig {
  platform_url: string;
}

declare const CONFIG: IConfig;

const legacyClient = new ApolloClient({
  connectToDevTools: false,
  cache: new InMemoryCache(),
  link: new HttpLink({
    credentials: 'same-origin',
    headers: {
      'X-CSRF-TOKEN': CSRF_TOKEN,
      Authorization: JWT ? `Bearer ${JWT}` : undefined,
    },
    uri: '/graphql',
  }),
});

const platformClient = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: fragmentData.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          itemsPaginated: createInfiniteScrollFieldPolicy(),
        },
      },
    },
  }),
  link: new HttpLink({
    credentials: 'include',
    headers: {
      Authorization: JWT ? `Bearer ${JWT}` : undefined,
    },
    uri: `${CONFIG.platform_url}/graphql`,
  }),
});

export { legacyClient, platformClient };
