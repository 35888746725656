import React from 'react';

import { Box, Carousel, COLORS, toggleStyleValue, UnstyledButton, useCarousel } from '@clutter/clean';
import styled from '@emotion/styled';

import { ImageFragment } from '@application/platform_schema';
import { Image } from '@application/components/shared/image';

const StyledCarousel = styled(Carousel)`
  overflow: visible !important;
`;

const StyledImage = styled(Image)`
  border-radius: 4px;
  overflow: hidden;
`;

const Dot = styled(UnstyledButton)<{ selected: boolean }>`
  display: inline-block;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  margin: 24px 7px;
  opacity: ${toggleStyleValue('selected', 1, 0.2)};
  background: ${COLORS.tealPrimary};
  transition: opacity 0.2s;
`;

export const ImageCarousel: React.FC<{ images: ImageFragment[] }> = ({ images }) => {
  const carouselProps = useCarousel({ wrapAround: false, initialIndex: 0 });

  const focusedIdx = (images.length + carouselProps.idx) % images.length;

  return (
    <Box margin="0 -24px">
      <Box margin="0 auto" width="375px" maxWidth="100vw" padding="0 6px">
        <Box maxWidth="339px" width="calc(100vw - 36px)" margin="0 auto" minHeight="364px">
          <StyledCarousel {...carouselProps} slidesToShow={1}>
            {images.map((image, i) => (
              <Box.Flex
                key={image.source}
                borderRadius="4px"
                justifyContent="center"
                margin="0 auto"
                maxWidth="339px"
                overflow="hidden"
                padding="0 6px"
                onPointerUp={() => {
                  Math.abs(carouselProps.dragDeltaRef.current) < 0.01 && carouselProps.setIdx(i);
                }}
              >
                <StyledImage source={image.source} naturalWidth={327} naturalHeight={440} />
              </Box.Flex>
            ))}
          </StyledCarousel>
        </Box>
        <Box.Flex justifyContent="center">
          {images.map((_, i) => (
            <Dot
              aria-label={`Image ${i}`}
              key={i}
              selected={focusedIdx === i}
              onClick={() => carouselProps.setIdx(i)}
            />
          ))}
        </Box.Flex>
      </Box>
    </Box>
  );
};
