import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

const Loader = () => (
  <div className="has-text-centered">
    <span className="icon has-text-light is-medium">
      <FontAwesomeIcon icon="spinner" spin />
    </span>
  </div>
);

export { Loader };
