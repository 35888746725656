import { Box, COLORS, getPropValue, toggleStyleValue, transparentize, UnstyledButton } from '@clutter/clean';
import React from 'react';

import emptyStar from '@application/images/icons/empty_star.svg';
import sparkle from '@application/images/icons/sparkle.svg';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const sparkleKeyframes = keyframes`
  0% {
    opacity: 1;
    transform:scale(1);
  }
  70% { transform:  scale(1.5);}
  100% { opacity: 1; }
}`;

const growKeyframes = keyframes`
  0% { transform: scale(1); }
  70% { transform: scale(1.1);}
  100% { transform: scale(1); }
`;

const Sparkle = styled.img`
  animation: ${sparkleKeyframes} 220ms ease 80ms;
  position: absolute;
  right: -24px;
  top: -12px;
`;

const SmallSparkle = styled.img`
  animation: ${sparkleKeyframes} 220ms ease 160ms;
  position: absolute;
  right: -40px;
  top: 8px;
  width: 14px;
`;

const Grow = styled.svg<{ animate: boolean }>`
  animation: ${growKeyframes} ${toggleStyleValue('animate', '0.3s', '0s')};
`;

const Star = ({ fill, animate }: { fill: string; animate: boolean }) => (
  <Grow
    animate={animate}
    width="44"
    height="43"
    fill="none"
    viewBox="0.364446759223938 0.7394999265670776 43.26153564453125 41.64219284057617"
    aria-hidden="true"
  >
    <path
      d="M20.109 2.043c.645-1.738 3.103-1.738 3.749 0l4.17 11.217a2 2 0 0 0 1.783 1.301l11.903.544c1.841.085 2.598 2.402 1.162 3.557l-9.34 7.508a2 2 0 0 0-.677 2.087l3.173 11.59c.488 1.783-1.498 3.217-3.037 2.192l-9.902-6.591a2 2 0 0 0-2.218.001l-9.878 6.589c-1.537 1.026-3.524-.405-3.04-2.188l3.153-11.597a2 2 0 0 0-.675-2.082l-9.322-7.51c-1.434-1.156-.677-3.471 1.163-3.556l11.88-.544a2 2 0 0 0 1.783-1.3l4.17-11.218z"
      fill={fill}
    ></path>
  </Grow>
);

const OPACITY = [0.4, 0.55, 0.7, 0.85, 1];

export const StarRating: React.FC<{ value?: number; onChange: (rating: number) => void }> = ({
  value = 0,
  onChange,
}) => {
  return (
    <Box.Flex position="relative">
      {OPACITY.map((o, i) => (
        <Box margin="0 3px" key={i}>
          <UnstyledButton onClick={() => onChange(i + 1)} aria-label={`${i + 1} Star`}>
            {value < i + 1 ? (
              <img src={emptyStar} aria-hidden="true" />
            ) : (
              <Star animate={value - 1 === i} fill={transparentize(COLORS.tiger, o)} />
            )}
          </UnstyledButton>
        </Box>
      ))}
      {value === 5 && (
        <>
          <Sparkle src={sparkle} />
          <SmallSparkle src={sparkle} />
        </>
      )}
    </Box.Flex>
  );
};
