import { useEffect, useRef } from 'react';

export const useMountedRef = () => {
  const ref = useRef(false);

  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);

  return ref;
};

/**
 * A hook with almost identical semantics to `useEffect`, but without firing
 * until dependencies are updated after initial mount.
 *
 * @param cb A callback matching what would be passed to useEffect
 * @param deps An array of dependencies to re-fire the effect
 */
export const useUpdateEffect = (cb: React.EffectCallback, deps: any[]) => {
  const mountedRef = useMountedRef();

  useEffect(() => {
    if (mountedRef.current) {
      return cb();
    }
    // No dependency other than `cb` should be missing.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, mountedRef]);
};
