import {
  Box,
  COLORS,
  FontWeight,
  SERIF_FONT_FAMILY,
  toggleStyleValue,
  UnstyledButton,
  Text,
  TextButton,
  Label,
} from '@clutter/clean';
import styled from '@emotion/styled';
import React, { useState } from 'react';

import { SuggestedPerMoverGratuity } from '@application/platform_schema';

import { CurrencyInput } from '../../shared';

const MAX_EXCEEDED_MESSAGE =
  'That amount exceeds our maximum charge. Please contact Customer Care to complete the transaction.';
export const MAX_TIP_SHARE = 200;

const Total = styled.span<{ selected: boolean }>`
  color: ${toggleStyleValue('selected', COLORS.tealJungle, 'inherit')};
  font: 30px ${SERIF_FONT_FAMILY};
  font-weight: ${FontWeight.Semibold};
  position: relative;
  margin-bottom: -4px;

  &:before {
    content: '$';
    display: block;
    position: absolute;
    font-size: 16px;
    left: -12px;
    top: 2px;
  }
`;

const Surface = styled(UnstyledButton)`
  position: relative;
  width: 33%;
  height: 100%;
`;

const CustomAmountInput = styled(CurrencyInput)`
  width: 100%;
`;

const Toggle = styled.div<{ selected: boolean }>`
  display: block;
  position: absolute;
  width: 100%;
  transition: all 0.1s cubic-bezier(0.6, 0.48, 0.58, 1.16);
  border-radius: 4px;

  ${({ selected }) =>
    selected
      ? {
          background: COLORS.cloud,
          height: '98px',
          top: '-6px',
          left: '-5px',
          width: '118px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          zIndex: 1,
          color: COLORS.panther,
        }
      : {
          background: COLORS.grayBackground,
          height: '100%',
          top: 0,
          left: 0,
          width: '100%',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.0)',
          zIndex: 0,
          color: COLORS.storm,
        }}
`;

const Button = ({
  tipShare,
  teamSize,
  onClick,
  selected,
}: {
  selected: boolean;
  tipShare: number;
  teamSize: number;
  onClick(value: number): void;
}) => {
  const total = tipShare * teamSize;
  return (
    <Surface onClick={() => onClick(total)}>
      <Toggle selected={selected}>
        <Box.Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%">
          <Total selected={selected}>{total}</Total>
          <Text.Caption> ${tipShare}/mover</Text.Caption>
        </Box.Flex>
      </Toggle>
    </Surface>
  );
};

export const Amount = ({
  teamSize,
  amount = 0,
  share = 0,
  suggestedPerMoverGratuities,
  onSelectAmount,
}: {
  teamSize: number;
  amount?: number;
  share?: number;
  suggestedPerMoverGratuities: SuggestedPerMoverGratuity;
  onSelectAmount(amount?: number): void;
}) => {
  const [showCustomInput, setShowCustomInput] = useState(false);

  const toggleCustomInput = () => {
    if (showCustomInput) onSelectAmount(undefined);
    setShowCustomInput(!showCustomInput);
  };

  const gratuityOptions = [
    suggestedPerMoverGratuities.low,
    suggestedPerMoverGratuities.mid,
    suggestedPerMoverGratuities.high,
  ];

  return (
    <>
      <Box.Flex
        background={COLORS.grayBackground}
        boxShadow="inset 0px 0px 4px rgba(0, 0, 0, 0.05)"
        height="86px"
        width="327px"
        justifyContent="space-around"
        borderRadius="8px"
        margin="0 auto"
      >
        {gratuityOptions.map((gratuityOption) => (
          <Button
            key={gratuityOption}
            teamSize={teamSize}
            selected={share === gratuityOption && !showCustomInput}
            tipShare={gratuityOption}
            onClick={(amount) => {
              setShowCustomInput(false);
              onSelectAmount(amount);
            }}
          />
        ))}
      </Box.Flex>
      <Box margin="24px 0 32px" height="100px" width="100%">
        {showCustomInput ? (
          <Box textAlign="left">
            <Label>Total tip amount</Label>
            <CustomAmountInput amount={amount} onUpdate={onSelectAmount} />
            {amount ? (
              <Box color={share > MAX_TIP_SHARE ? COLORS.toucan : COLORS.storm} margin="8px 0 0">
                <Text.Caption>
                  {share > MAX_TIP_SHARE ? MAX_EXCEEDED_MESSAGE : `$${Math.floor(amount / teamSize)}/mover`}
                </Text.Caption>
              </Box>
            ) : null}
          </Box>
        ) : (
          <TextButton size="medium" onClick={() => toggleCustomInput()}>
            Other
          </TextButton>
        )}
      </Box>
    </>
  );
};
