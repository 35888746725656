import React, { useEffect, useState } from 'react';

import { COLORS, toggleStyleValue } from '@clutter/clean';
import styled from '@emotion/styled';

const DURATION = 300; // milliseconds

const Container = styled.div<{ show: boolean }>`
  width: 100vw;
  margin: 0 -24px -24px;
  transition: transform ${DURATION}ms ease, opacity ${DURATION}ms;
  transform: translateY(${toggleStyleValue('show', '0%', '4%')});
  opacity: ${toggleStyleValue('show', 1, 0)};
  background: ${COLORS.cloud};
`;

export const ReasonContainer: React.FC<{ show: any }> = ({ show: show, children }) => {
  const [shouldRender, setShouldRender] = useState(show);

  useEffect(() => {
    if (show) setShouldRender(true);
  }, [show]);

  if (!shouldRender && !show) return null;

  return <Container show={shouldRender && show}>{children}</Container>;
};
