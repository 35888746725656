import * as React from 'react';

const Home = () => (
  <section className="section">
    <div className="container">
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            <p className="title">Thanks for using Clutter!</p>
            <p className="subtitle">Nothing to see here.</p>
          </div>
        </div>
      </section>
    </div>
  </section>
);

export { Home };
