import { CustomerItemFragment } from '@application/platform_schema';
import { isItemGroup } from '@application/utilities/customer_item';
import React from 'react';

export const ItemName: React.FC<{ item: CustomerItemFragment }> = ({ item }) => {
  if (isItemGroup(item)) return <>{item.name || item.categoryName}</>;
  else if (item.name) return <>{item.name}</>;
  else {
    return (
      <>
        {item.categoryName} #{item.barcode.value.slice(-4)}
      </>
    );
  }
};
