import * as React from 'react';
import { useContext } from 'react';

import { NotificationKind } from '@application/types';

import { NotificationsContext } from '@application/contexts';

const STYLES = {
  [NotificationKind.Info]: 'is-info',
  [NotificationKind.Danger]: 'is-danger',
  [NotificationKind.Success]: 'is-success',
  [NotificationKind.Warning]: 'is-warning',
};

export const Flashes: React.FC = () => {
  const { notifications } = useContext(NotificationsContext);
  return notifications.length ? (
    <section className="section">
      {notifications.map(({ kind, message }, key) => (
        <div key={key} className={`notification ${STYLES[kind]}`}>
          {message}
        </div>
      ))}
    </section>
  ) : null;
};
