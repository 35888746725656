import moment from 'moment';
import * as React from 'react';

import { Humanize } from '../formatters';
import { useTimer } from '../timer';

import { Text } from '@clutter/clean';

interface IStaleProps {
  timestamp: string;
}

const Stale = ({ timestamp }: IStaleProps) => {
  const now = useTimer();
  const duration = moment.duration(moment(now).diff(moment(timestamp)));
  return (
    <React.Fragment>
      <Text.Callout>
        Your team has not synced their location in <Humanize duration={duration} padding={{ left: ' ' }} />. Don't
        worry, they're still on the way!
      </Text.Callout>
    </React.Fragment>
  );
};

export { Stale };
