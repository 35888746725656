export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    CustomerItem: ['Item', 'ItemGroup'],
  },
};
export default result;
