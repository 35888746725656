import React from 'react';

import { Box, COLORS, FontWeight, Text, UnstyledButton, SkeletonLoader } from '@clutter/clean';

import { ItemName } from '@application/components/formatters/item_name';
import { Image } from '@application/components/shared/image';
import { CustomerItemFragment, ImageFragment } from '@application/platform_schema';
import { Pluralize } from '@application/components/formatters';
import styled from '@emotion/styled';

export const CARD_HEIGHT = 82; // px

const imageFor = (item: CustomerItemFragment): ImageFragment | undefined | null => {
  if (item.__typename === 'Item') return item.images[item.hero];
  if (item.__typename === 'ItemGroup') return item.heroImage;
};

const Container = styled(UnstyledButton)`
  height: 82px;
  display: flex;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  text-align: left;
  width: 100%;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
`;

const Loader = () => (
  <Container>
    <Box width="75px" height="100%" background={COLORS.grayBackground} display="inline-block" />
    <Box padding="16px" height="100%" width="100%">
      <SkeletonLoader height="16px" margin="0 0 8px" />
      <SkeletonLoader height="16px" margin="0 0 8px" />
    </Box>
  </Container>
);

const NameContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemCard: React.FC<{ item: CustomerItemFragment; onClick?(item: CustomerItemFragment): void }> = ({
  item,
  onClick,
}) => {
  const image = imageFor(item);
  const cuft = Math.max(Math.round(item.cuft || 1), 1);

  if (!image) return null;

  return (
    <Container onClick={() => onClick?.(item)}>
      <Box.Flex width="75px" flexShrink={0}>
        <Image
          naturalWidth={75}
          naturalHeight={82}
          width="75px"
          height="82px"
          source={image.source}
          initialLoaded={image.state === 'uploaded'}
        />
      </Box.Flex>
      <Box padding="12px 16px 16px" minWidth="0">
        <Box margin="0 0 8px">
          <Text.Body weight={FontWeight.Medium}>
            <NameContainer>
              <ItemName item={item} />
            </NameContainer>
          </Text.Body>
        </Box>
        <Box color={COLORS.storm}>
          <Text.Caption weight={FontWeight.Medium}>
            <Pluralize count={cuft} singular="cubic foot" plural="cubic feet" />
          </Text.Caption>
        </Box>
      </Box>
    </Container>
  );
};

export const ItemPreviewCard = Object.assign(ItemCard, { Loader });
