import * as React from 'react';

import styled from '@emotion/styled';
import { Box, Button, COLORS, FontWeight, InfoModal, Text, UsageBar } from '@clutter/clean';
import { useWTEvent } from './wt/event';
import { useOrderContext } from '@application/contexts/order';

const StyledLink = styled.a`
  color: ${COLORS.tealPrimary};
  cursor: pointer;

  &:hover {
    color: ${COLORS.__primaryButtonHover};
  }
`;

const Percent = styled(Text.Title)`
  color: ${COLORS.tealPrimary};
`;

const Title = styled(Text.Title)`
  color: ${COLORS.tealDark};
`;

const CuftModal: React.FC<{
  isOpen: boolean;
  handleModalClose: () => void;
}> = ({ isOpen, handleModalClose }) => (
  <InfoModal isOpen={isOpen} handleModalClose={handleModalClose}>
    <Box textAlign="center">
      <Text.Title size="large">How your plan usage is calculated</Text.Title>
      <Box margin="16px 0 24px">
        <Text.Body>
          While your team creates your photo catalog, they calculate the cubic feet your items take up in your plan.
          <br />
          <br />
          If you have any questions about the size of your item, just talk to your team lead.
        </Text.Body>
      </Box>
      <Button fullWidth onClick={handleModalClose}>
        Got it
      </Button>
    </Box>
  </InfoModal>
);

export const PlanProgress: React.FC = () => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const { app } = useOrderContext();

  const percentage = app.order.usage.usedCubicFootage / app.order.usage.totalCubicFootage;
  const parsedPlanSize = app.order.usage.formattedCubicFootage.split(' ');
  const planSize = `${parsedPlanSize[0]}' × ${parsedPlanSize[2]}'`;

  const roundedPercentage = Math.ceil(percentage * 100);

  const track = useWTEvent({
    action: 'click',
    label: 'How is this calculated?',
    container: 'plan_progress_bar',
    objectType: 'link',
    objectName: 'size_explanation_helper',
    metadata: { plan_size: planSize, percentage: roundedPercentage.toString() },
  });

  return (
    <Box>
      <Box.Flex justifyContent="space-between">
        <Title size="extraSmall">Your {planSize} plan</Title>
        <Percent size="extraSmall">{roundedPercentage}%</Percent>
      </Box.Flex>
      <Box margin="16px 0 8px">
        <UsageBar percentage={percentage} />
      </Box>
      <StyledLink
        onClick={() => {
          track({});
          setModalOpen(true);
        }}
      >
        <Text.Caption weight={FontWeight.Medium}>How is this calculated?</Text.Caption>
      </StyledLink>
      <CuftModal isOpen={modalOpen} handleModalClose={() => setModalOpen(false)} />
    </Box>
  );
};
