import * as React from 'react';

import { Avatar } from '@application/components/appointment/shared/avatar';

import { LaborClockFragment } from '@application/platform_schema';
import styled from '@emotion/styled';
import { Box, toggleStyleValue } from '@clutter/clean';

interface IAvatarsProps {
  tippables: LaborClockFragment[];
}

const AvatarContainer = styled.div<{ lift?: boolean }>`
  position: relative;
  z-index: ${toggleStyleValue('lift', 10, 1)};
  margin: -8px;
`;

export const Avatars = ({ tippables }: IAvatarsProps) => (
  <Box.Flex flexWrap="wrap" justifyContent="center" padding="8px 0">
    {tippables.map((tippable, i) => (
      <AvatarContainer key={i} lift={tippables.length === 3 ? i === 1 : true}>
        <Avatar imageURL={tippable.user.imageURL} initials={tippable.user.initials} size="large" />
      </AvatarContainer>
    ))}
  </Box.Flex>
);
