/**
 * Creates a queue to force serial execution of arbitrary promises.
 */
export const createAsyncQueue = () => {
  let last = Promise.resolve();
  return {
    enqueue(cb: () => Promise<any>) {
      const next = last.then(cb);
      last = next;
    },
  };
};
