import * as React from 'react';

import { StatusHeader } from './status_header/status_header';
import { Route } from './route';
import { PickupReminders, PackingReminders, FullServiceReturnReminders, MovingReminders } from './reminders/reminders';

import { OrderServiceTypeEnum, OrderTypeEnum, useItemCountQuery } from '@application/platform_schema';
import { Box, Text, COLORS } from '@clutter/clean';
import { Agenda } from './agenda/agenda';
import { ItemFeedSection } from './items/item_feed_section';
import { useOrderContext } from '@application/contexts/order';
import { IssueResolution } from './issue_resolution/issue_resolution';
import { useActionCableSubscription } from '@application/hooks/use_action_cable_subscription';
import { Detail } from './items/detail';
import { useItemSelectionContext } from '@application/contexts/item_selection';

const Tracker: React.FC = () => {
  const { app, hasReturn } = useOrderContext();

  const pickupNonFullService =
    app.order.type === OrderTypeEnum.Pickup && app.order.serviceType === OrderServiceTypeEnum.CurbsidePickup;
  const pickupFullService =
    app.order.type === OrderTypeEnum.Pickup && app.order.serviceType === OrderServiceTypeEnum.FullService;
  const returnFullService =
    app.order.type === OrderTypeEnum.Return && app.order.serviceType === OrderServiceTypeEnum.FullService;
  const move = app.order.type === OrderTypeEnum.Move;
  const showReminders = pickupNonFullService || pickupFullService || returnFullService || move;

  const { data, refetch } = useItemCountQuery({
    variables: { orderType: OrderTypeEnum.Return },
    skip: !hasReturn,
  });

  useActionCableSubscription({
    channel: 'OrderResetChannel',
    callback: () => {
      hasReturn && refetch();
    },
  });

  const { selectedItem } = useItemSelectionContext();
  if (selectedItem) {
    return <Detail />;
  }

  return (
    <>
      <Box margin="-40px -24px 0 -24px">
        <Route />
      </Box>
      <Box
        position="relative"
        top="-8px"
        borderRadius="8px 8px 0 0"
        background={COLORS.cloud}
        boxShadow="0px -4px 12px rgba(0, 0, 0, 0.1)"
        margin="0 -24px"
      >
        <Box padding="40px 0 0 0" margin="0 24px 0 24px" background={COLORS.cloud}>
          <StatusHeader />
          {showReminders && (
            <>
              <Box margin="40px 0 24px 0" color={COLORS.panther}>
                <Text.Title size="large">Reminders</Text.Title>
              </Box>
              {pickupNonFullService && <PickupReminders />}
              {pickupFullService && <PackingReminders />}
              {returnFullService && <FullServiceReturnReminders />}
              {move && <MovingReminders />}
            </>
          )}
          <Agenda />
          {[OrderTypeEnum.Pickup, OrderTypeEnum.Return].includes(app.order.type) &&
            [OrderServiceTypeEnum.CurbsidePickup, OrderServiceTypeEnum.FullService].includes(app.order.serviceType) && (
              <Box margin="24px 0">
                <IssueResolution />
              </Box>
            )}
        </Box>
      </Box>
      {hasReturn && (
        <Box>
          <ItemFeedSection itemCount={data?.itemCount || 0} isReturn={true} />
        </Box>
      )}
    </>
  );
};

export { Tracker };
