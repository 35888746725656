import { useOrderContext } from '@application/contexts/order';
import { InvoiceFragment, InvoiceStatusEnum } from '@application/platform_schema';
import { Box, COLORS, FontWeight, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import * as React from 'react';

interface IConfig {
  account_url: string;
}

declare const CONFIG: IConfig;
const BILLING_URL = `${CONFIG.account_url}/billing`;

const StyledLink = styled.a`
  color: ${COLORS.tealPrimary};

  &:active,
  &:visited {
    color: ${COLORS.tealPrimary};
  }
`;

const CURRENCY_FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

// subscriptionInvoices should only apply for onboardings
const calculateTotalPaid = (orderInvoices: InvoiceFragment[], subscriptionInvoices: InvoiceFragment[]) => {
  const paidOrderInvoicesTotal = orderInvoices.reduce((prev, invoice) => {
    const invoiceTotal = invoice.status === InvoiceStatusEnum.Paid ? invoice.total : 0;
    return prev + invoiceTotal;
  }, 0);

  const paidSubscriptionInvoicesTotal = subscriptionInvoices.reduce((prev, invoice) => {
    const invoiceTotal = invoice.status === InvoiceStatusEnum.Paid ? invoice.total : 0;
    return prev + invoiceTotal;
  }, 0);

  return CURRENCY_FORMATTER.format(paidOrderInvoicesTotal + paidSubscriptionInvoicesTotal);
};

const DayOfInvoicesTotal: React.FC<{
  orderInvoices: InvoiceFragment[];
  subscriptionInvoices: InvoiceFragment[];
}> = ({ orderInvoices, subscriptionInvoices }) => {
  const {
    app: { order },
  } = useOrderContext();

  let accountHref = BILLING_URL;
  if (order.customer.initialSetupToken) {
    accountHref = accountHref + '?initial_setup_token=' + order.customer.initialSetupToken;
  }

  return (
    <Box>
      <Box.Flex justifyContent="space-between" alignItems="center" color={COLORS.tealDark} margin="0 0 8px">
        <Text.Title size="extraSmall">Total Paid {calculateTotalPaid(orderInvoices, subscriptionInvoices)}</Text.Title>
        <StyledLink href={accountHref} target="_blank">
          <Text.Callout weight={FontWeight.Medium}>View Receipt</Text.Callout>
        </StyledLink>
      </Box.Flex>
    </Box>
  );
};

export { DayOfInvoicesTotal };
