import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Missing } from './missing';

import { Wizard } from './appointment/wizard';

import { useAppQuery } from '@application/platform_schema';
import { useActionCableSubscription } from '@application/hooks/use_action_cable_subscription';
import { OrderContextProvider } from '@application/contexts/order';

export const Appointment = () => {
  const { token } = useParams<{ token: string }>();
  const { data, refetch, loading, error } = useAppQuery();
  useActionCableSubscription({
    channel: 'OrderResetChannel',
    callback: () => {
      refetch();
    },
  });
  const app = data?.app;

  if (loading) return null;
  if (!app || error) return <Missing />;

  return (
    <OrderContextProvider app={app}>
      <Wizard />
    </OrderContextProvider>
  );
};
