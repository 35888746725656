import React from 'react';
import styled from '@emotion/styled';
import { Pluralize } from '@application/components/formatters';
import { ButtonLink } from '@application/components/shared/button_link';
import { useWTEvent } from '@application/components/wt/event';
import { useOrderContext } from '@application/contexts/order';
import { Box, COLORS, FontWeight, Text } from '@clutter/clean';
import { ItemFeed } from './item_feed';

const ItemCount = styled(Text.Caption)`
  color: ${COLORS.storm};
`;

export const ItemFeedSection: React.FC<{ itemCount: number; isReturn: boolean }> = ({ itemCount, isReturn }) => {
  const { token } = useOrderContext();

  const track = useWTEvent({ pageName: 'today:summary', action: 'click' });

  return (
    <>
      <Box.Flex justifyContent="space-between" alignItems="baseline">
        <Text.Title size="large">{isReturn ? 'Your delivery' : 'Your items'}</Text.Title>
        <ItemCount weight={FontWeight.Medium}>
          <Pluralize count={itemCount} singular="item" plural="items" />
          {!isReturn && ' added'}
        </ItemCount>
      </Box.Flex>
      <Box margin="32px 0 0">
        <ItemFeed isReturn={isReturn} />
        {itemCount > 5 && (
          <Box margin="0 0 24px">
            <ButtonLink
              fullWidth
              kind="secondary"
              to={isReturn ? `/a/${token}/return_items` : `/a/${token}/items`}
              onClick={() => {
                track({ container: 'item_feed', objectType: 'button', objectName: 'view_all_items' });
              }}
            >
              View All
            </ButtonLink>
          </Box>
        )}
      </Box>
    </>
  );
};
