import * as React from 'react';

import { COLORS, Box, Text } from '@clutter/clean';

import styled from '@emotion/styled';

interface IArrivalWindowProps {
  formattedEstimatedArrivalWindow: string;
  formattedScheduledArrivalWindow: string;
  slaWindowAdjustedToLaterTime: boolean;
}

const ColoredText = styled.span`
  color: ${COLORS.tealPrimary};
`;

const ArrivalWindow = ({
  formattedEstimatedArrivalWindow,
  formattedScheduledArrivalWindow,
  slaWindowAdjustedToLaterTime,
}: IArrivalWindowProps) => {
  return (
    <Box margin="0 0 0 0" color={COLORS.panther}>
      <Text.Title size="large">
        Your team is estimated to arrive between <ColoredText>{formattedEstimatedArrivalWindow}</ColoredText>
      </Text.Title>
      <Box margin="10px 0 0 0">
        <Text.Headline>
          We'll text you when your team is on their way. Please remain available for the duration of your full arrival
          window between <ColoredText>{formattedScheduledArrivalWindow}</ColoredText>.
        </Text.Headline>
      </Box>
      {slaWindowAdjustedToLaterTime && (
        <Box margin="10px 0 0 0">
          <Text.Callout>
            We apologize for the delay with your appointment today. Thanks for your patience and understanding!
          </Text.Callout>
        </Box>
      )}
    </Box>
  );
};

export { ArrivalWindow };
