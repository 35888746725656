import * as React from 'react';
import { useContext } from 'react';

import { NotificationsContext } from '@application/contexts';
import { NotificationKind } from '@application/types';

import {
  Status,
  useGratuityCreateMutation,
  useLaborClocksQuery,
  useSuggestedPerMoverGratuitiesQuery,
} from '@application/platform_schema';

import { useActionCableSubscription } from '@application/hooks/use_action_cable_subscription';
import { Loader } from '../../loader';
import { Avatars } from './tip/avatars';
import { Amount, MAX_TIP_SHARE } from './tip/amount';
import { Box, Button, Text, TextButton } from '@clutter/clean';
import { ProgressState, useProgressContext } from '../shared/progress_context';
import { useWTEvent } from '../../wt/event';
import { BASE_BOTTOM_NAV_PARAMS } from '@application/constants/wt';

interface IChannelData {
  status: 'ok' | 'unprocessable';
  error?: string;
}

interface ITipProps {
  onCompleted(): void;
}

export const Tip = ({ onCompleted }: ITipProps) => {
  const [submit] = useGratuityCreateMutation();

  const { onNotify } = useContext(NotificationsContext);
  const [amount, setAmount] = React.useState<number | undefined>(undefined);
  const [tipping, setTipping] = React.useState<boolean>(false);
  const { setProgress } = useProgressContext();

  const { data: laborClocksData, error: laborClocksError, loading: laborClocksLoading } = useLaborClocksQuery();
  const laborClocks = laborClocksData?.laborClocks;

  const {
    data: suggestedPerMoverGratuitiesData,
    error: suggestedPerMoverGratuitiesError,
    loading: suggestedPerMoverGratuitiesLoading,
  } = useSuggestedPerMoverGratuitiesQuery();
  const suggestedPerMoverGratuities = suggestedPerMoverGratuitiesData?.suggestedPerMoverGratuities;

  const track = useWTEvent();

  useActionCableSubscription<IChannelData>({
    channel: 'GratuityStatusChannel',
    callback: (data: IChannelData) => {
      if (data.error) {
        onNotify({ message: data.error, kind: NotificationKind.Danger });
      }
      onCompleted();
    },
  });

  const handleSave = async ({ skipped, amount }: { skipped: boolean; amount?: number }) => {
    setTipping(true);

    track({
      objectName: skipped ? 'skip' : 'next',
      ...BASE_BOTTOM_NAV_PARAMS,
      metadata: {
        ...(laborClocks && { numMovers: String(laborClocks.length) }),
        ...(suggestedPerMoverGratuities && {
          suggestedPerMoverAmounts: String([
            suggestedPerMoverGratuities.low,
            suggestedPerMoverGratuities.mid,
            suggestedPerMoverGratuities.high,
          ]),
        }),
        ...(amount && { selectedAmount: String(amount) }),
      },
    });

    const result = await submit({
      variables: { input: { amount, skipped } },
    });
    if (
      result?.data?.gratuityCreate &&
      result.data.gratuityCreate.status == Status.Unprocessable &&
      result.data.gratuityCreate.error
    ) {
      if (result.data.gratuityCreate.error.expected) {
        onNotify({
          message: result.data.gratuityCreate.error.message,
          kind: NotificationKind.Danger,
        });
      } else {
        throw new Error(result.data.gratuityCreate.error.message);
      }
    }
    setProgress(ProgressState.Tipped);
    if (skipped) {
      onCompleted();
    }
  };

  React.useEffect(() => {
    laborClocks && suggestedPerMoverGratuities && setAmount(suggestedPerMoverGratuities.mid * laborClocks.length);
  }, [laborClocks, suggestedPerMoverGratuities]);

  React.useEffect(() => {
    setProgress(ProgressState.Initial);
  }, [setProgress]);

  if (laborClocksLoading || suggestedPerMoverGratuitiesLoading) return <Loader />;
  if (laborClocksError) throw laborClocksError;
  if (suggestedPerMoverGratuitiesError) throw suggestedPerMoverGratuitiesError;

  if (!laborClocks?.length) throw new Error('No labor clocks present!');
  if (!suggestedPerMoverGratuities) throw new Error('No suggested gratuity amounts found!');
  const share = (amount || 0) / laborClocks.length;

  const onChoose = (value: number) => {
    setAmount(value);
  };

  return (
    <Box textAlign="center" maxWidth="500px" margin="0 auto">
      <Box margin="0 0 40px">
        <Text.Title size="large">Tip your team!</Text.Title>
      </Box>
      <Avatars tippables={laborClocks} />
      <Box margin="24px 0 32px">
        <Text.Body>
          We strongly recommend that you tip through our platform instead of tipping in cash or through a payment app.
          This way, you and the team member aren't sharing personal information, the transaction is secure, and 100% of
          your tip will be evenly distributed among the team.
        </Text.Body>
      </Box>
      <Amount
        teamSize={laborClocks.length}
        onSelectAmount={onChoose}
        amount={amount}
        share={share}
        suggestedPerMoverGratuities={suggestedPerMoverGratuities}
      />
      <Box margin="24px 0">
        <TextButton size="medium" onClick={() => handleSave({ skipped: true })}>
          Skip
        </TextButton>
      </Box>
      <Button
        fullWidth
        onClick={() => handleSave({ amount, skipped: false })}
        loading={tipping}
        disabled={share > MAX_TIP_SHARE || !amount}
      >
        Send Tip
      </Button>
    </Box>
  );
};
