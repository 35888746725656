import * as React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { BASE_BOTTOM_NAV_PARAMS } from '@application/constants/wt';

import { Experience } from './rate/experience';
import { Team } from './rate/team';
import { General } from './rate/general';
import { Tip } from './rate/tip';
import { useWTEvent } from '../wt/event';
import { useOrderContext } from '@application/contexts/order';
import { Root } from './rate/root';

enum Step {
  Experience = 'experience',
  Team = 'team',
  General = 'general',
  Tip = 'tip',
}

interface IRateProps {
  onCompleted(): void;
}

const INITIAL_INDEX = -1; // No subpath
const REVIEW_INDEX = 1;
const STEPS = [Step.Tip, Step.Experience, Step.Team, Step.General];

export const Rate: React.FC<IRateProps> = ({ onCompleted }) => {
  const { token, app } = useOrderContext();
  const [index, setIndex] = React.useState(app.tippable ? INITIAL_INDEX : REVIEW_INDEX);
  const history = useHistory();
  const track = useWTEvent({
    ...BASE_BOTTOM_NAV_PARAMS,
  });

  const rootPath = `/a/${token}/rate`;
  const path = (step: Step): string => {
    return `${rootPath}/${step}`;
  };

  const handleComplete = () => {
    track({
      objectName: 'next',
    });

    const nextIndex = index + 1;
    if (nextIndex < STEPS.length) {
      setIndex(nextIndex);

      history.push(path(STEPS[nextIndex]));
    } else {
      onCompleted();
    }
  };

  return (
    <Switch>
      <Route path={rootPath} exact>
        <Root onCompleted={handleComplete} />
      </Route>
      <Route path={path(Step.Experience)}>
        <Experience onCompleted={handleComplete} />
      </Route>
      <Route path={path(Step.Team)}>
        <Team onCompleted={handleComplete} />
      </Route>
      <Route path={path(Step.General)}>
        <General onCompleted={handleComplete} />
      </Route>
      <Route path={path(Step.Tip)}>
        <Tip onCompleted={handleComplete} />
      </Route>
      <Route>
        <Redirect to={rootPath} />
      </Route>
    </Switch>
  );
};
