import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { mapbox as stub } from './mapbox/stubs';

import { IMapbox } from './mapbox/types';
import { IMap } from './mapbox/types/map';

import { ENV, Environment } from '@application/config/env';

const provider = ((): IMapbox => {
  switch (ENV) {
    case Environment.test:
      Object.assign(global, { mapbox: stub });
      return stub;
    default:
      return mapboxgl;
  }
})();

export { provider as mapbox, IMap };
