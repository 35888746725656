/** @jsx jsx */

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, COLORS, Input, Label, Textarea, Text, FontWeight, Button, SkeletonLoader } from '@clutter/clean';

import {
  CustomerItemFragment,
  CustomerItemTypeEnum,
  DetailedCustomerItemFragment,
  ImageFragment,
  useItemDetailedQuery,
  useItemUpdateMutation,
} from '@application/platform_schema';
import { ImageCarousel } from './detail/image_carousel';
import { useOrderContext } from '@application/contexts/order';
import { ItemName } from '@application/components/formatters/item_name';
import { BackNavArrow } from '@application/components/shared/back_nav_arrow';
import { RemeasureModal } from './detail/remeasure_modal';
import { useItemSelectionContext } from '@application/contexts/item_selection';
import { Fallback } from '@application/components/shared/fallback';
import { AppFragment } from '@application/platform_schema';

const imagesFor = (item: DetailedCustomerItemFragment) => {
  if (item.__typename === 'Item') return item.images;
  const images: ImageFragment[] = [];
  item.items.forEach((subItem) => images.push(...subItem.images));
  return images;
};

const fieldStyle = css`
  width: 100%;
  max-width: 100%;
  margin-bottom: 24px;
  display: block;
`;

const Field: React.FC<{
  label: React.ReactNode;
  type: 'textarea' | 'tel' | 'text' | 'number' | 'password' | 'search';
  value: string;
  onChange(value: string): void;
}> = ({ label, type, value, onChange }) => {
  const Component = type === 'textarea' ? Textarea : Input;
  return (
    <React.Fragment>
      <Label>{label}</Label>
      <Component
        css={fieldStyle}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChange(e.currentTarget.value)}
      />
    </React.Fragment>
  );
};

const Separator = styled.hr`
  background: ${COLORS.grayBorder};
  border: none;
  margin: 24px 0;
  height: 1px;
`;

const LineItem: React.FC<{ label: React.ReactNode; content: React.ReactNode }> = ({ label, content }) => {
  return (
    <React.Fragment>
      <Separator />
      <Box.Flex justifyContent="space-between">
        <Text.Body weight={FontWeight.Medium}>{label}</Text.Body>
        <span> {content}</span>
      </Box.Flex>
    </React.Fragment>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  margin: 0 -8px;

  & > * {
    width: 100%;
    margin: 0 8px;
  }
`;

const baseInput = (item?: Pick<CustomerItemFragment, 'uuid' | '__typename'>) =>
  item
    ? {
        type: item.__typename === 'ItemGroup' ? CustomerItemTypeEnum.Group : CustomerItemTypeEnum.Item,
        uuid: item.uuid,
      }
    : { type: CustomerItemTypeEnum.Item, uuid: '' }; // This should never reach the server!

const Placeholder = () => {
  return (
    <React.Fragment>
      <Box padding="24px 0">
        <Text.Title size="large">
          <SkeletonLoader width="100%" maxWidth="327px" height="100%" />
        </Text.Title>
      </Box>
      <SkeletonLoader width="100%" maxWidth="327px" height="440px" margin="0 auto" animationDelay="200ms" />
    </React.Fragment>
  );
};

const Content: React.FC<{
  item: DetailedCustomerItemFragment;
  app: AppFragment;
  name?: string;
  contents?: string;
  onChangeName(name: string): void;
  onChangeContents(contents: string): void;
}> = ({ item, app, name, contents, onChangeName, onChangeContents }) => {
  const planCuft = app.order.usage.totalCubicFootage;
  const itemCuft = Math.round(item.cuft || 1) || 1;
  const cuftPct = Math.round((itemCuft / planCuft) * 100) || 1;
  const images = imagesFor(item);

  return (
    <React.Fragment>
      <Box padding="24px 0">
        <Text.Title size="large">
          <ItemName item={item} />
        </Text.Title>
      </Box>
      <ImageCarousel key={item.uuid} images={images} />
      <Field label="Item name" type="text" onChange={onChangeName} value={name || ''} />
      <Field label="Item description" type="textarea" onChange={onChangeContents} value={contents || ''} />
      <Separator />
      <Label>{itemCuft} cubic feet</Label>
      <Text.Callout>This item takes up {cuftPct}% of your total storage space</Text.Callout>
      <Box margin="16px 0 0">
        <RemeasureModal />
      </Box>
      {'barcode' in item && <LineItem label="Item barcode" content={item.barcode.value} />}
    </React.Fragment>
  );
};

export const Detail: React.FC<{ loadingNextItem?: boolean }> = ({ loadingNextItem }) => {
  const { app } = useOrderContext();
  const [name, setName] = useState('');
  const [contents, setContents] = useState('');
  const [changed, setChanged] = useState(false);
  const history = useHistory();
  const { nextItem, selectedItem, setSelectedItem } = useItemSelectionContext();
  const [hasLoaded, setHasLoaded] = useState(false);

  if (!selectedItem) throw new Error('Item must be present!');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, error } = useItemDetailedQuery({
    variables: {
      input: baseInput(selectedItem),
    },
    onCompleted: ({ itemDetailed }) => {
      setHasLoaded(true);
      setName(itemDetailed.name || itemDetailed.categoryName || '');
      setContents(itemDetailed.contents || '');
    },
  });

  // Prefetch next item
  useItemDetailedQuery({
    variables: {
      input: baseInput(nextItem),
    },
    skip: !nextItem,
  });

  const [update] = useItemUpdateMutation();

  if (error) throw error;

  const item = data?.itemDetailed;

  const save = () => {
    if (!changed) return;
    setChanged(false);
    update({
      variables: {
        input: {
          name,
          contents,
          ...baseInput(selectedItem),
        },
      },
    });
  };

  return (
    <React.Fragment>
      <BackNavArrow onClick={() => history.goBack()} />
      <Fallback loader={<Placeholder />} value={data?.itemDetailed || hasLoaded} minimumLoadingMs={1000}>
        {item && (
          <Content
            item={item}
            app={app}
            contents={contents}
            name={name}
            onChangeContents={(value) => {
              setChanged(true);
              setContents(value);
            }}
            onChangeName={(value) => {
              setChanged(true);
              setName(value);
            }}
          />
        )}
      </Fallback>
      <Box height="144px" />
      <Box
        padding="24px"
        boxShadow="0px 2px 20px rgba(0, 0, 0, 0.1)"
        position="fixed"
        bottom="0"
        left="0"
        width="100%"
        background={COLORS.cloud}
      >
        <Box maxWidth="500px" margin="0 auto">
          <ButtonGroup>
            <div>
              <Button
                fullWidth
                kind="secondary"
                onClick={async () => {
                  save();
                  history.goBack();
                }}
              >
                Done
              </Button>
            </div>
            {(nextItem || loadingNextItem) && (
              <div>
                <Button
                  fullWidth
                  onClick={async () => {
                    if (!nextItem) return;
                    save();
                    window.scrollTo(0, 0);
                    setSelectedItem(nextItem);
                  }}
                >
                  Next Item
                </Button>
              </div>
            )}
          </ButtonGroup>
        </Box>
      </Box>
    </React.Fragment>
  );
};
