import * as React from 'react';
import { Box, COLORS, Text, ButtonLink } from '@clutter/clean';
import { useAccountDetailsQuery } from '@application/platform_schema';
import styled from '@emotion/styled';
import { platformClient } from '@application/libraries/apollo';
import { useOrderContext } from '@application/contexts/order';
import { useWTEvent } from '@application/components/wt/event';
import { Loader } from '@application/components/loader';

interface IConfig {
  account_url: string;
}

declare const CONFIG: IConfig;
const ACCOUNT_URL = `${CONFIG.account_url}/account`;

const Callout = styled(Text.Callout)`
  margin-top: 16px;
  color: ${COLORS.storm};
`;

const CostRow: React.FC<{ service: any; cost: any }> = ({ service, cost }) => (
  <Box.Flex padding="24px 0" justifyContent="space-between">
    <Text.Callout>{service}</Text.Callout>
    <Box width="160px" textAlign="right">
      <Text.Callout>{cost}</Text.Callout>
    </Box>
  </Box.Flex>
);

const Rows = styled.div`
  margin: 8px 0;

  & > :not(:last-child) {
    border-bottom: 1.5px solid ${COLORS.grayBorder};
  }
`;

const PricingExplainer: React.FC = () => {
  const { app } = useOrderContext();
  const { data, error, loading } = useAccountDetailsQuery({ client: platformClient });

  const track = useWTEvent({
    action: 'click',
    label: 'View Plan Details',
    value: '/account',
    container: 'pricing_details',
    objectType: 'input:button',
    objectName: 'cta',
  });

  if (loading) return <Loader />;
  if (error) throw error;
  if (!data || !data.accountDetails) {
    return null;
  }

  const { upcomingSubscriptionSet, onboardingCostDetails } = data.accountDetails;

  const storageSubscription = upcomingSubscriptionSet.storageSubscriptions[0];
  const dimensions = storageSubscription.displayDimensions || '';

  let accountHref = ACCOUNT_URL;
  if (app.order.customer.initialSetupToken) {
    accountHref = accountHref + '?initial_setup_token=' + app.order.customer.initialSetupToken;
  }

  return (
    <Box padding="40px 24px" background={COLORS.grayBackground} margin="0 -24px">
      <Text.Title size="small">Your estimated costs</Text.Title>
      <Rows>
        <CostRow service="Appointment" cost={onboardingCostDetails.formattedCost} />
        <CostRow service={`${dimensions} storage plan`} cost={`$${storageSubscription.total}`} />
      </Rows>
      <ButtonLink
        size="small"
        fullWidth
        href={accountHref}
        target="_blank"
        onClick={() =>
          track({
            metadata: {
              appointmentPrice: onboardingCostDetails.formattedCost,
              storagePlan: dimensions,
              storagePrice: storageSubscription.total.toString(),
            },
          })
        }
      >
        View Plan Details
      </ButtonLink>
      <Callout>If you end up needing a larger or smaller plan size, your costs may change.</Callout>
    </Box>
  );
};

export { PricingExplainer };
