import { COLORS } from '@clutter/clean';
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

export const Link = styled(RouterLink)`
  color: ${COLORS.tealPrimary};
  text-decoration: none !important;
  &:hover,
  &:active {
    color: ${COLORS.__primaryButtonHover};
  }
`;
