import {
  Rating,
  ReviewReason,
  useOrderReviewReasonsQuery,
  useExperienceReviewUpsertMutation,
} from '@application/platform_schema';
import * as React from 'react';
import { Box, FontWeight, Text, COLORS, transparentize, TagSelector, Button } from '@clutter/clean';

import { StarRating } from './experience/star_rating';
import { ReasonContainer } from '@application/components/appointment/rate/experience/reason_container';
import { EMPTY_REASONS, reasonCopyToCode } from './utils/reasons';
import { ProgressState, useProgressContext } from '../shared/progress_context';
import { useWTEvent } from '@application/components/wt/event';

interface IExperienceProps {
  onCompleted(): void;
  onError?(): void;
}

const SCORE_TO_DESCRIPTION: Record<number, string> = {
  1: 'Terrible',
  2: 'Bad',
  3: 'Okay, some issues',
  4: 'Good',
  5: 'Everything was great',
};

type Review = {
  score?: number;
  reasons: Set<string>;
};

const Experience = ({ onCompleted, onError }: IExperienceProps) => {
  const [review, setReview] = React.useState<Review>(() => ({
    reasons: new Set(),
  }));
  const [execute, { error }] = useExperienceReviewUpsertMutation({
    onError,
  });
  const { setProgress } = useProgressContext();

  const track = useWTEvent({
    action: 'click',
    objectType: 'button',
  });

  const { data } = useOrderReviewReasonsQuery();
  const reasonOptions = data?.orderReviewReasons || EMPTY_REASONS;

  if (error) throw error;

  React.useEffect(() => {
    if (!review.score) {
      setProgress(ProgressState.Tipped);
      return;
    }

    setProgress(ProgressState.AppointmentRated);

    const reasonsHash: Record<string, true> = {};
    if (review.reasons) {
      review.reasons.forEach((tag) => {
        const code = reasonCopyToCode(tag, reasonOptions);
        reasonsHash[code] = true;
      });
    }

    execute({
      variables: {
        input: {
          score: review.score,
          reasons: JSON.stringify(reasonsHash),
        },
      },
    });
  }, [setProgress, execute, review, reasonOptions]);

  const rating = review.score === 5 ? Rating.Good : Rating.Poor;
  const { score, reasons } = review;

  React.useEffect(() => {
    setReview((current) => ({ ...current, reasons: new Set() }));
  }, [rating]);

  const reasonsCopy = reasonOptions
    .map((r) => (rating === Rating.Good ? r.positiveCopy : r.negativeCopy))
    .filter((r): r is string => !!r);

  return (
    <>
      <Box.Flex flexDirection="column">
        <Box.Flex alignItems="center" flexDirection="column">
          <Text.Title size="large">Give us feedback</Text.Title>
          <Box margin="32px 0">
            <Text.Body weight={FontWeight.Medium}>How was your appointment?</Text.Body>
          </Box>
          <Box height="48px">
            <StarRating
              value={score}
              onChange={(score) => {
                track({
                  container: 'hero',
                  objectName: 'star_rating',
                  value: score,
                });
                setReview((cur) => ({ ...cur, score }));
              }}
            />
          </Box>
          <Box margin="12px 0 40px">
            {score ? (
              SCORE_TO_DESCRIPTION[score]
            ) : (
              <Box maxWidth="296px" margin="20px 0 0" textAlign="center">
                <Text.Callout>
                  Your feedback is anonymous and our Customer Success team will use it to improve your storage
                  experience.
                </Text.Callout>
              </Box>
            )}
          </Box>
        </Box.Flex>
        <ReasonContainer show={score !== undefined}>
          <Box
            height="100%"
            background={transparentize(score === 5 ? COLORS.dew : COLORS.dust, 0.4)}
            transition="background 0.2s"
          >
            <Box.Flex
              height="100%"
              padding="40px 24px"
              maxWidth="600px"
              margin="0 auto"
              flexDirection="column"
              textAlign="center"
              justifyContent="space-between"
            >
              <div>
                <Text.Title size="small">What {score === 5 ? 'went well?' : 'could improve?'}</Text.Title>
                <Text.Callout>Select all that apply</Text.Callout>
                <Box margin="24px -8px 40px">
                  {reasonOptions.length > 0 && (
                    <TagSelector
                      onChange={(newReasons) => setReview((cur) => ({ ...cur, reasons: newReasons }))}
                      onClick={(reason) => track({ objectName: 'reason', container: 'content', value: reason })}
                      selectedTags={reasons}
                      tags={reasonsCopy}
                    />
                  )}
                </Box>
              </div>
              <Button onClick={() => onCompleted()}>Next</Button>
            </Box.Flex>
          </Box>
        </ReasonContainer>
      </Box.Flex>
    </>
  );
};

export { Experience };
