import * as React from 'react';

import { IMap } from '../../libraries/mapbox';

import { Context } from './context';

interface IContextProps {
  map: IMap;
}

interface ISourceProps {
  id: string;
  type: 'geojson';
  data: any;
  lineMetrics?: boolean;
}

class Source extends React.Component<IContextProps & ISourceProps> {
  public render() {
    return null;
  }

  public componentDidMount() {
    const { map, id, ...options } = this.props;
    if (this.loaded) {
      map.addSource(id, options);
    }
  }

  public componentWillUnmount() {
    const { map, id } = this.props;
    if (this.loaded) {
      map.removeSource(id);
    }
  }

  public componentDidUpdate(prior: IContextProps & ISourceProps) {
    if (this.props.data !== prior.data) {
      const { id, map, data } = this.props;
      const source = map.getSource(id);
      if (source) {
        (source as mapboxgl.GeoJSONSource).setData(data);
      }
    }
  }

  private get loaded(): boolean {
    const { map } = this.props;
    return map.getStyle() !== undefined;
  }
}

const SourceWithContext = (props: ISourceProps) => {
  return <Context.Consumer>{({ map }) => map && <Source map={map} {...props} />}</Context.Consumer>;
};

export { SourceWithContext as Source };
