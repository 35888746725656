import * as React from 'react';
import { useLaborClocksQuery } from '@application/platform_schema';
import {
  Rating,
  ReviewReason,
  useUserReviewsUpsertMutation,
  useUserReviewReasonsQuery,
} from '@application/platform_schema';
import { Accordion, Box, Button, COLORS, Text } from '@clutter/clean';
import styled from '@emotion/styled';
import { RateUser, Review } from './team/rate_user';
import { Loader } from '@application/components/loader';
import { reasonCopyToCode } from './utils/reasons';
import { ProgressState, useProgressContext } from '../shared/progress_context';

interface ITeamProps {
  onCompleted(): void;
  onError?(): void;
}

const Title = styled(Text.Title)`
  color: ${COLORS.tealDark};
  white-space: nowrap;
`;

const AccordionGroup = styled(Accordion.Group)`
  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Team = ({ onCompleted, onError }: ITeamProps) => {
  const [openItems, setOpenItems] = React.useState<Record<string, boolean>>({});

  const { data: queryData } = useUserReviewReasonsQuery();
  const leadReasons = queryData?.userReviewReasons.leadReasons || [];
  const associateReasons = queryData?.userReviewReasons.associateReasons || [];

  const { data, error: queryError, loading } = useLaborClocksQuery();
  const [execute, { error: mutationError }] = useUserReviewsUpsertMutation({
    onError,
  });

  const { setProgress } = useProgressContext();

  React.useEffect(() => {
    setProgress(ProgressState.TeamReviewed);
  }, [setProgress]);

  if (queryError || mutationError) {
    throw queryError || mutationError;
  }
  if (loading) {
    return <Loader />;
  }
  if (!data) {
    throw new Error('missing data for query');
  }

  const laborClocks = data.laborClocks;

  const updateReviews = (review: Review, reasons: ReviewReason[]) => {
    setOpenItems({ [review.userID]: true });
    const reasonsHash: Record<string, true> = {};
    if (review.reasons) {
      review.reasons.forEach((reason) => {
        const code = reasonCopyToCode(reason, reasons);
        reasonsHash[code] = true;
      });
    }
    execute({
      variables: {
        inputs: [
          {
            rating: review.rating,
            userID: review.userID,
            reasons: JSON.stringify(reasonsHash),
          },
        ],
      },
    });
  };

  return (
    <Box.Flex flexDirection="column" maxWidth="327px" margin="0 auto" textAlign="center">
      <Title size="large">How was your team?</Title>
      <Box margin="16px 0 40px">
        <Text.Body>Your feedback is anonymous and won’t be shared directly with your team.</Text.Body>
      </Box>
      <AccordionGroup
        openItems={openItems}
        onToggleItem={(name, open = openItems[name]) => {
          setOpenItems({ [name]: !open });
        }}
      >
        {laborClocks.map((laborClock, i) => {
          const reviewReasons = laborClock.role === 'lead' ? leadReasons : associateReasons;
          return (
            <RateUser
              key={i}
              reviewReasons={reviewReasons}
              laborClock={laborClock}
              onRate={(review) => updateReviews(review, reviewReasons)}
            />
          );
        })}
      </AccordionGroup>
      <Box margin="40px 0 0">
        <Button fullWidth={true} onClick={() => onCompleted()}>
          Next
        </Button>
      </Box>
    </Box.Flex>
  );
};

export { Team };
