import { ApolloProvider } from '@apollo/client';
import * as React from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Box, Layout } from '@clutter/clean';

import { NotificationsContext } from '@application/contexts';

import { INotification } from '@application/types';

import { PageView } from './wt';

import { platformClient } from '../libraries/apollo';

import { Flashes } from './app/flashes';
import { Navigation } from './app/navigation';

import { Appointment } from './appointment';
import { Home } from './home';
import { ConnectedProgressBar, ProgressProvider } from './appointment/shared/progress_context';

export const App: React.FC = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  return (
    <ApolloProvider client={platformClient}>
      <Layout>
        <Router>
          <PageView>
            <NotificationsContext.Provider
              value={{
                notifications,
                onNotify: (notification) => {
                  setNotifications([...notifications, notification]);
                },
              }}
            >
              <ProgressProvider>
                <Navigation />
                <ConnectedProgressBar />
                <Flashes />
                <Box padding="40px 24px 0" maxWidth="100vw" overflow="hidden visible">
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/a/:token">
                      <Appointment />
                    </Route>
                  </Switch>
                </Box>
              </ProgressProvider>
            </NotificationsContext.Provider>
          </PageView>
        </Router>
      </Layout>
    </ApolloProvider>
  );
};
