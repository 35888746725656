import React from 'react';
import { Box, Text } from '@clutter/clean';
import { useOrderContext } from '@application/contexts/order';
import { OrderTypeEnum } from '@application/platform_schema';

function resolveCopy(type: OrderTypeEnum): { title: React.ReactNode; subTitle: React.ReactNode } {
  switch (type) {
    case OrderTypeEnum.Return:
      return {
        title: 'Your items have been returned!',
        subTitle: 'Thank you for trusting Clutter with your moving & storage needs.',
      };
    case OrderTypeEnum.Pickup:
      return {
        title: 'Your items are on their way to our warehouse',
        subTitle: 'Thank you for trusting Clutter with your moving & storage needs.',
      };
    default: {
      throw new Error(`Unsupported order type: ${type}`);
    }
  }
}

export const JobStatus = () => {
  const { app } = useOrderContext();

  const { title, subTitle } = resolveCopy(app.order.type);

  return (
    <>
      <Box maxWidth="323px">
        <Text.Title size="large">{title}</Text.Title>
      </Box>
      <Box margin="8px 0 0">
        <Text.Headline>{subTitle}</Text.Headline>
      </Box>
    </>
  );
};
