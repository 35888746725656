import * as React from 'react';

import { Crop, Fit, Image } from '@application/components/shared/image';
import { Box, Text, COLORS } from '@clutter/clean';
import { Image as ImageType, ImageState } from '@application/platform_schema';

export interface IParkedImageProps {
  source: string;
  state: string;
}
export interface IArrivedProps {
  movers: string[];
  parkedImage?: IParkedImageProps;
}

const Arrived = ({ movers, parkedImage }: IArrivedProps) => {
  let moverNames = 'your team';

  if (movers.length == 1) {
    moverNames = movers[0];
  } else if (movers.length > 0) {
    moverNames = movers.slice(0, movers.length - 1).join(', ') + ' and ' + movers[movers.length - 1];
  }

  return (
    <Box margin="0 0 0 0" color={COLORS.panther}>
      <Text.Title size="large">Your team is here - meet them outside!</Text.Title>
      {parkedImage && (
        <Box.Flex margin="10px 0 0 0" width="300px" flexShrink={0}>
          <Image
            naturalWidth={300}
            naturalHeight={300}
            width="300px"
            height="300px"
            source={parkedImage.source}
            initialLoaded={parkedImage.state === ImageState.Uploaded}
            fit={Fit.Crop}
            crop={Crop.Focalpoint}
          />
        </Box.Flex>
      )}
      <Box margin="10px 0 0 0">
        <Text.Headline>
          Your team just parked. Grab a mask & meet {moverNames} outside so they can get started with your appointment.
        </Text.Headline>
      </Box>
    </Box>
  );
};

export { Arrived };
