import { createContext } from 'react';

import { INotification } from '@application/types';

export const NotificationsContext = createContext<{
  notifications: INotification[];
  onNotify(notification: INotification): void;
}>({
  notifications: [],
  onNotify: (_: INotification) => {
    /* noop */
  },
});
