import * as React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { Canceled } from './canceled';
import { Rate } from './rate';
import { Rescheduled } from './rescheduled';
import { Summary } from './summary';
import { Thanks } from './thanks';
import { Tip } from './rate/tip';
import { Tracker } from './tracker';
import { Items } from './items/items';

import { AppFragment, Mode } from '@application/platform_schema';
import { useOrderContext } from '@application/contexts/order';
import { ItemSelectionContextProvider } from '@application/contexts/item_selection';
import { useEffect, useRef } from 'react';

const RedirectForOrder: React.FC<{
  token: string;
  app: AppFragment;
}> = ({ token, app }) => {
  const path = (() => {
    const { tippable, ratable, expired, mode } = app;
    if (mode === Mode.Queued) {
      return 'tracker';
    }
    if (mode === Mode.Departed) {
      return 'tracker';
    }
    if (mode === Mode.Arrived) {
      return 'tracker';
    }
    if (mode === Mode.DepartedForDestination) {
      return 'tracker';
    }
    if (mode === Mode.ArrivedAtDestination) {
      return 'tracker';
    }
    if (mode === Mode.Summary) {
      return 'summary';
    }
    if (expired) {
      return 'thanks';
    }
    if (mode === Mode.Finalize) {
      if (tippable) return 'rate';
      else if (ratable) return 'rate/experience';
      else return 'thanks';
    }
    if (mode === Mode.Canceled) {
      return 'canceled';
    }
    if (mode === Mode.Rescheduled) {
      return 'rescheduled';
    }
  })();
  return <Redirect to={`/a/${token}/${path}`} />;
};

export const Wizard: React.FC = () => {
  const { app, token, hasReturn } = useOrderContext();
  const { mode } = app;
  const history = useHistory();
  const modeRef = useRef(mode);

  const goThanks = () => {
    history.push(`/a/${token}/thanks`);
  };

  const canceled = mode === Mode.Canceled;
  const rescheduled = mode === Mode.Rescheduled;
  const summary = mode === Mode.Summary;
  const tracker =
    mode === Mode.Arrived ||
    mode === Mode.Departed ||
    mode === Mode.Queued ||
    mode === Mode.DepartedForDestination ||
    mode === Mode.ArrivedAtDestination;
  const finalize = mode === Mode.Finalize;

  useEffect(() => {
    if (mode === Mode.Finalize && modeRef.current !== Mode.Finalize) {
      history.push(`/a/${token}/rate`);
    }
    modeRef.current = mode;
  }, [mode, history, token]);

  return (
    <ItemSelectionContextProvider>
      <Switch>
        {canceled && (
          <Route path="/a/:token/canceled">
            <Canceled />
          </Route>
        )}
        {rescheduled && (
          <Route path="/a/:token/rescheduled">
            <Rescheduled />
          </Route>
        )}
        {tracker && (
          <Route path="/a/:token/tracker">
            <Tracker />
          </Route>
        )}
        {hasReturn && (
          <Route path="/a/:token/return_items">
            <Items backURL={tracker ? `/a/${token}/tracker` : `/a/${token}/summary`} returnOrder={true} />
          </Route>
        )}
        {(summary || finalize) && (
          <Route path="/a/:token/items" render={() => <Items backURL={`/a/${token}/summary`} returnOrder={false} />} />
        )}
        {(summary || finalize) && (
          <Route path="/a/:token/summary">
            <Summary />
          </Route>
        )}
        {finalize && (
          <Route path="/a/:token/rate">
            <Rate onCompleted={goThanks} />
          </Route>
        )}
        {finalize && (
          <Route path="/a/:token/thanks">
            <Thanks />
          </Route>
        )}
        <Route>
          <RedirectForOrder app={app} token={token} />
        </Route>
      </Switch>
    </ItemSelectionContextProvider>
  );
};
