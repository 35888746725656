import * as React from 'react';

const LOCALE = 'en' as const;
const OPTIONS = { style: 'percent' } as const;

const Percentage = ({ locale = LOCALE, value }: { locale?: string; value: number }) => (
  <React.Fragment>{value.toLocaleString(locale, OPTIONS)}</React.Fragment>
);

export { Percentage };
