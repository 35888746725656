import * as React from 'react';

import { Box, Text, COLORS } from '@clutter/clean';

import { EmptyState } from './empty_state';
import { ItemFeedSection } from '../items/item_feed_section';
import { LaborDuration } from '../shared/labor_duration';
import { PlanProgress } from '../../plan_progress';
import { useOrderContext } from '@application/contexts/order';
import { useItemCountQuery, OrderTypeEnum, OrderSubtypeEnum, OrderServiceTypeEnum } from '@application/platform_schema';
import { useActionCableSubscription } from '@application/hooks/use_action_cable_subscription';
import { MaskReminder, SecurePetsReminder } from '../reminders/reminders';
import { Agenda } from '../agenda/agenda';
import { Detail } from '../items/detail';
import { useItemSelectionContext } from '@application/contexts/item_selection';
import { IssueResolution } from '../issue_resolution/issue_resolution';
import { PricingExplainer } from './pricing_explainer';

const FOOTER_HEIGHT = '132px';

const Overview: React.FC = () => {
  const { hasParked, app, hasPickup, hasReturn } = useOrderContext();

  const { data, refetch } = useItemCountQuery({ skip: !hasPickup });
  const { data: returnOrderData, refetch: returnOrderRefetch } = useItemCountQuery({
    variables: { orderType: OrderTypeEnum.Return },
    skip: !hasReturn,
  });

  useActionCableSubscription({
    channel: 'OrderResetChannel',
    callback: () => {
      hasPickup && refetch();
      hasReturn && returnOrderRefetch();
    },
  });

  const itemCount = data?.itemCount || 0;
  const returnItemCount = returnOrderData?.itemCount || 0;

  const { selectedItem } = useItemSelectionContext();
  if (selectedItem) {
    return <Detail />;
  }

  if (hasPickup && !hasReturn && !itemCount) return <EmptyState />;

  // Ensure that the item feed doesn't show up for sim-jobs prior to items being added
  const showPickupItemSection = hasPickup && itemCount > 0;

  return (
    <Box maxWidth="500px" margin="0 auto" padding={`0 0 ${FOOTER_HEIGHT}`}>
      {showPickupItemSection && <ItemFeedSection itemCount={itemCount} isReturn={false} />}
      {hasReturn && !showPickupItemSection && <ItemFeedSection itemCount={returnItemCount} isReturn={true} />}
      <LaborDuration order={app.order} workBreaks={app.workBreaks} />
      <Box margin="24px 0">
        <Text.Title size="large">Reminders</Text.Title>
      </Box>
      <MaskReminder />
      <SecurePetsReminder />
      <Agenda />
      {[OrderTypeEnum.Pickup, OrderTypeEnum.Return].includes(app.order.type) &&
        [OrderServiceTypeEnum.CurbsidePickup, OrderServiceTypeEnum.FullService].includes(app.order.serviceType) && (
          <Box margin="24px 0">
            <IssueResolution />
          </Box>
        )}
      {app.order.subtype === OrderSubtypeEnum.Onboarding && <PricingExplainer />}
      <Box
        boxShadow="0px -4px 12px rgba(0, 0, 0, 0.05)"
        borderRadius="8px 8px 0px 0px"
        padding="24px 24px 32px"
        position="fixed"
        bottom="0"
        left="0"
        width="100%"
        height={FOOTER_HEIGHT}
        background={COLORS.cloud}
      >
        <PlanProgress />
      </Box>
    </Box>
  );
};

export { Overview };
