import React from 'react';

import { Text, Box } from '@clutter/clean';

export const EmptyState: React.FC = () => {
  return (
    <div>
      <Text.Title size="large">Your items</Text.Title>
      <Box>
        <Text.Body>As your team works, watch them add your items to your photo catalog.</Text.Body>
      </Box>
    </div>
  );
};
