import * as React from 'react';

import { Image } from './image';
import { Layer } from './layer';
import { Source } from './source';

interface ICoordinate {
  latitude: number;
  longitude: number;
}

interface IIconProps {
  id: string;
  coordinate: ICoordinate;
  type: 'origin-pin' | 'destination-pin';
  paint?: any;
  layout?: any;
  maxzoom?: number;
  minzoom?: number;
}

// Defaults are for internal icons. Ie. 'home'
const DEFAULT_LAYOUT = {
  'icon-allow-overlap': true,
  'icon-size': 0.18,
  'icon-padding': 0,
  'icon-ignore-placement': true,
  'text-allow-overlap': true,
  'text-ignore-placement': true,
};

class Icon extends React.Component<IIconProps> {
  public render() {
    const { id, coordinate, type, layout, maxzoom = 24, minzoom = 0 } = this.props;

    const data = {
      type: 'Point',
      coordinates: [coordinate.longitude, coordinate.latitude],
    };

    return (
      <Image icon={type}>
        <Layer
          id={id}
          source={id}
          type="symbol"
          maxzoom={maxzoom}
          minzoom={minzoom}
          layout={{ ...DEFAULT_LAYOUT, ...layout, 'icon-image': type }}
        >
          <Source id={id} data={data} type="geojson" />
        </Layer>
      </Image>
    );
  }
}

export { Icon };
