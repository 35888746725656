import { CustomerItemFragment, ItemGroupFragment } from '@application/platform_schema';

const ITEM_GROUP_TYPENAME = 'ItemGroup';

export const isItemGroup = (item: CustomerItemFragment): item is ItemGroupFragment =>
  item.__typename === ITEM_GROUP_TYPENAME;

export const uniqueId = (item: CustomerItemFragment) => (isItemGroup(item) ? 'group-' : 'item-') + item.id;

export const imageFor = (item: CustomerItemFragment) => {
  if (item.__typename === 'Item') return item.images[item.hero];
  if (!item.heroImage) throw new Error('No heroImage found for item group');
  return item.heroImage;
};
