import React, { useState } from 'react';

import styled from '@emotion/styled';
import { Box, Button, FontWeight, Label, Text, Textarea } from '@clutter/clean';

import { Rating, useExperienceReviewCommentUpdateMutation } from '@application/platform_schema';
import { EmojiRating } from '@application/components/shared/emoji_rating';
import { useWTEvent } from '@application/components/wt/event';
import { ProgressState, useProgressContext } from '../shared/progress_context';

const Question: React.FC<{
  rating?: Rating;
  onRate(rating: Rating): void;
}> = ({ children, rating, onRate }) => (
  <Box.Flex
    height="92px"
    width="100%"
    maxWidth="325px"
    justifyContent="space-between"
    alignItems="center"
    boxShadow={rating ? '0px 0px 4px rgba(0, 0, 0, 0.1)' : '0px 4px 12px rgba(0, 0, 0, 0.1)'}
    borderRadius="4px"
    padding="24px 16px"
    transition="box-shadow 0.2s"
  >
    <Box>{children}</Box>
    <EmojiRating rating={rating} onRate={onRate}></EmojiRating>
  </Box.Flex>
);

const CommentInput = styled(Textarea)`
  margin-top: 8px;
  width: 100%;
  min-height: 144px;
`;

const QUESTIONS = [
  { key: 'felt_prepared', label: 'I felt prepared for my appointment.' },
  { key: 'understood_cost', label: 'I understood the costs of my appointment.' },
];

export const General: React.FC<{ onCompleted: () => void }> = ({ onCompleted }) => {
  const [ratings, setRatings] = useState<Record<string, Rating>>({});
  const [comment, setComment] = useState('');
  const track = useWTEvent({
    action: 'click',
  });

  const [addComment] = useExperienceReviewCommentUpdateMutation({
    variables: { input: { comment } },
  });

  const updateRating = (key: string, rating: Rating) => {
    track({
      container: 'content',
      objectType: 'button',
      objectName: `${key}_rating`,
      value: rating === Rating.Good ? 'good' : 'poor',
    });
    setRatings((current) => ({ ...current, [key]: rating }));
  };

  const { setProgress } = useProgressContext();

  React.useEffect(() => {
    setProgress(ProgressState.ExperienceDescribed);
  }, [setProgress]);

  return (
    <Box textAlign="center" margin="0 auto" maxWidth="325px">
      <Text.Title size="large">
        Describe your
        <br />
        experience
      </Text.Title>
      <Box margin="32px 0 64px" textAlign="left">
        <Box.Flex flexDirection="column" alignItems="center" margin="-8px 0 16px">
          {QUESTIONS.map(({ key, label }) => (
            <Box key={key} margin="8px 0">
              <Question onRate={(rating) => updateRating(key, rating)} rating={ratings[key]}>
                <Text.Body weight={FontWeight.Medium}>{label}</Text.Body>
              </Question>
            </Box>
          ))}
        </Box.Flex>
        <Box textAlign="left">
          <Text.Body weight={FontWeight.Medium}>Any other feedback?</Text.Body>
          <CommentInput
            name="Comment"
            onChange={(e) => setComment(e.currentTarget.value)}
            value={comment}
            placeholder="Tell us more about what went well and what could improve."
          />
        </Box>
      </Box>
      <Button
        fullWidth
        onClick={() => {
          if (comment) addComment();
          onCompleted();
        }}
      >
        Next
      </Button>
    </Box>
  );
};
