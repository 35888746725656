import wt from '@clutter/wt';

import { WT_ENABLED } from '../../config/wt';
import { getPageName } from '@application/utilities/get_page_name';

interface IEventProps {
  action: string; // i.e. click
  container: string; // i.e. hero
  objectType: string; // i.e. link
  objectName: string; // i.e. skip
  pageName?: string; // i.e. thanks
  value?: string | number;
  metadata?: Record<string, string>; // additional nonstandard properties
}

/**
 * A hook to provide default params to track interactions within a component.
 * Prefer this API to calling `wt` directly to allow for future contextual
 * enhancements.
 */
export const useWTEvent = <Default extends Partial<IEventProps> = Record<never, never>>(
  defaults: Default = {} as Default,
) => {
  return (overrides: Omit<IEventProps, keyof Default> & Partial<IEventProps>) => {
    if (WT_ENABLED) {
      const pageName = overrides.pageName || defaults.pageName || getPageName();
      const metadata = overrides.metadata || defaults.metadata;
      if (metadata) {
        delete defaults.metadata;
        delete overrides.metadata;
      }
      wt.track({ ...defaults, ...overrides, ...metadata, pageName });
    }
  };
};
