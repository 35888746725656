import { FieldPolicy } from '@apollo/client';
import { Reference } from '@apollo/client/utilities';
import { Pagination } from '@application/platform_schema';

type CachedPaginatedType = {
  /** Some fields may not be present, but for the sake of merging this isn't relevant */
  pagination: Partial<Pagination>;
  results: Reference[];
};

const dedupe = (arr: Reference[]) => {
  const seen: Record<string, boolean> = {};
  return arr.filter((item) => {
    const id = item.__ref;
    if (seen[id]) return false;
    seen[id] = true;
    return true;
  });
};

export function createInfiniteScrollFieldPolicy(): FieldPolicy {
  return {
    keyArgs: false,
    merge: (existing: CachedPaginatedType | undefined, incoming: CachedPaginatedType) => {
      if (!existing) return incoming;
      return {
        ...existing,
        pagination: { ...existing.pagination, ...incoming.pagination },
        /** This approach assumes that:
         * 1. We start with page === 1
         * 2. We're separately account for any extra params (by clearing the cache for this field)
         * 3. We are separately accounting for new items being prepended (or
         *    don't care). If this isn't accounted for, it's possible for newer
         *    items to be _appended_ to the list (if the number is greater than
         *    the page size).
         */
        results: dedupe([...(existing?.results || []), ...incoming.results]),
      };
    },
  };
}
