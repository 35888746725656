import * as React from 'react';

const PADDING = '0';

const padded = (value: number, digits: number) => {
  let result = String(value);
  while (result.length < digits) {
    result = PADDING + result;
  }
  return result;
};

const Pad = ({ value, digits }: { value: number; digits: number }) => (
  <React.Fragment>{padded(value, digits)}</React.Fragment>
);

export { Pad };
