import * as React from 'react';

import { IMap } from '../../libraries/mapbox';

import { Context } from './context';

import ORIGIN_PIN from '@application/images/icons/origin-pin.png';
import DESTINATION_PIN from '@application/images/icons/destination-pin.png';

const ICONS: Record<'origin-pin' | 'destination-pin', string> = {
  'origin-pin': ORIGIN_PIN,
  'destination-pin': DESTINATION_PIN,
};

interface IContextProps {
  map: IMap;
}

interface IImageProps {
  icon: 'origin-pin' | 'destination-pin';
}

interface IImageState {
  loaded: boolean;
}

class Image extends React.Component<IContextProps & IImageProps, IImageState> {
  constructor(props: IContextProps & IImageProps) {
    super(props);
    this.state = { loaded: props.map.hasImage(props.icon) };
  }

  public componentDidMount() {
    const { map, icon } = this.props;
    if (this.loaded && !map.hasImage(icon)) {
      map.loadImage(ICONS[icon], (error, image) => {
        if (error) {
          throw error;
        }
        if (!map.hasImage(icon)) {
          map.addImage(icon, image);
        }
        this.setState({ loaded: map.hasImage(icon) });
      });
    }
  }

  public componentWillUnmount() {
    const { icon, map } = this.props;
    if (this.loaded) {
      map.removeImage(icon);
    }
  }

  public render() {
    const { loaded } = this.state;
    if (loaded) {
      return this.props.children;
    } else {
      return null;
    }
  }

  private get loaded(): boolean {
    const { map } = this.props;
    return map.getStyle() !== undefined;
  }
}

const ImageWithContext = (props: React.PropsWithChildren<IImageProps>) => {
  return <Context.Consumer>{({ map }) => map && <Image map={map} {...props} />}</Context.Consumer>;
};

export { ImageWithContext as Image };
