import * as polyline from '@mapbox/polyline';
import { LngLat, LngLatBounds } from 'mapbox-gl';
import * as React from 'react';

import { IMap } from '../../libraries/mapbox';

import { Context } from './context';

const PADDING = 40;

interface IContextProps {
  map: IMap;
}

interface IBoundsProps {
  geometry: string;
}

class Bounds extends React.Component<IContextProps & IBoundsProps> {
  public render() {
    return null;
  }

  public componentDidMount() {
    this.reset();
  }

  public componentDidUpdate() {
    this.reset();
  }

  private reset = () => {
    const { map } = this.props;
    const bounds = this.bounds;
    map.fitBounds(bounds, { padding: PADDING });
  };

  private get bounds(): LngLatBounds {
    const { geometry } = this.props;
    const geojson = polyline.toGeoJSON(geometry);

    return geojson.coordinates.reduce((memo: LngLatBounds, coordinate: LngLat) => {
      return memo ? memo.extend(coordinate) : new LngLatBounds(coordinate, coordinate);
    }, null);
  }
}

const BoundsWithContext = (props: IBoundsProps) => {
  return <Context.Consumer>{({ map }) => map && <Bounds map={map} {...props} />}</Context.Consumer>;
};

export { BoundsWithContext as Bounds };
