import React from 'react';

import { Box, COLORS, toggleStyleValue, UnstyledButton } from '@clutter/clean';
import styled from '@emotion/styled';
import { Rating } from '@application/platform_schema';
import thumbsDown from '@application/images/icons/thumbs_down.png';
import thumbsUp from '@application/images/icons/thumbs_up.png';

const ButtonContainer = styled(UnstyledButton)<{ fade: boolean; selected: boolean }>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${COLORS.tealBackground};
  border: 2px solid ${toggleStyleValue('selected', COLORS.dew, COLORS.tealBackground)};
  opacity: ${toggleStyleValue('fade', 0.4, 1)};
  transition: opacity 0.4s;
`;

const RoundButton: React.FC<{ fade: boolean; selected: boolean; label: string; onClick(): void }> = ({
  onClick,
  fade,
  selected,
  label,
  children,
}) => (
  <ButtonContainer fade={fade} selected={selected} onClick={onClick} aria-label={label}>
    {children}
  </ButtonContainer>
);

export const EmojiRating: React.FC<{ rating?: Rating; onRate(rating: Rating): void }> = ({ rating, onRate }) => {
  return (
    <Box.Flex margin="-4px">
      <Box margin="4px">
        <RoundButton
          fade={rating === Rating.Poor}
          selected={rating === Rating.Good}
          onClick={() => onRate(Rating.Good)}
          label="thumbs up"
        >
          <Box padding="3px">
            <img src={thumbsUp} height="40" width="40" />
          </Box>
        </RoundButton>
      </Box>
      <Box margin="4px">
        <RoundButton
          fade={rating === Rating.Good}
          selected={rating === Rating.Poor}
          onClick={() => onRate(Rating.Poor)}
          label="thumbs down"
        >
          <Box padding="5px 3px 1px">
            <img src={thumbsDown} height="40" width="40" />
          </Box>
        </RoundButton>
      </Box>
    </Box.Flex>
  );
};
