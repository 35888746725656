import { ProgressBar } from '@clutter/clean';
import * as React from 'react';
import { useState, createContext } from 'react';

export enum ProgressState {
  Initial = 0,
  Tipped = 0.25,
  AppointmentRated = 0.5,
  TeamReviewed = 0.75,
  ExperienceDescribed = 1.0,
}

const ProgressContext = createContext<{
  progress: ProgressState | null;
  setProgress(progress: number | null): void;
}>({
  progress: null,
  setProgress: (_: number | null) => {
    /* noop */
  },
});

export const useProgressContext = () => React.useContext(ProgressContext);

export const ProgressProvider: React.FC = ({ children }) => {
  const [progress, setProgress] = useState<ProgressState | null>(null);

  return <ProgressContext.Provider value={{ progress, setProgress }}>{children}</ProgressContext.Provider>;
};

export const ConnectedProgressBar = () => {
  const { progress } = React.useContext(ProgressContext);

  if (progress === null) return null;
  return <ProgressBar percentage={progress} />;
};
