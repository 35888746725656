import * as React from 'react';

import { IMap } from '../../libraries/mapbox';

import { Context } from './context';

interface IContextProps {
  map: IMap;
}

interface ILayerProps {
  id: string;
  type: 'line' | 'fill' | 'circle' | 'symbol';
  layout?: any;
  source: any;
  paint?: any;
  minzoom?: number;
  maxzoom?: number;
}

class Layer extends React.Component<IContextProps & ILayerProps> {
  public render() {
    return this.props.children;
  }

  public componentDidMount() {
    const { map, ...options } = this.props;
    if (this.loaded) {
      map.addLayer(options);
    }
  }

  public componentDidUpdate() {
    const { map, id, type } = this.props;

    // Mapbox doesn't seem to have a declarative z-index across layer types. We
    // always want icons on top of lines, so this brutishly brings them to the
    // front on every render.
    if (this.loaded && type === 'symbol') {
      map.moveLayer(id);
    }
  }

  public componentWillUnmount() {
    const { id, map } = this.props;
    if (this.loaded) {
      map.removeLayer(id);
    }
  }

  private get loaded(): boolean {
    const { map } = this.props;
    return map.getStyle() !== undefined;
  }
}

const LayerWithContext = (props: React.PropsWithChildren<ILayerProps>) => {
  return <Context.Consumer>{({ map }) => map && <Layer map={map} {...props} />}</Context.Consumer>;
};

export { LayerWithContext as Layer };
