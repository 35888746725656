class Stub {
  public options: any;

  constructor(options: any) {
    this.options = options;
  }

  public setData = (data: any) => {
    this.options.data = data;
  };

  public getData = () => {
    return this.options.data;
  };
}

export { Stub as Source };
