interface IConfig {
  wt_cookie_domain: string;
  wt_tracker_domain: string;
}

declare const CONFIG: IConfig;

export const WT_COOKIE_EXPIRES = 365 * 20; // i.e. 20 years
export const WT_COOKIE_DOMAIN = CONFIG.wt_cookie_domain;
export const WT_TRACKER_DOMAIN = CONFIG.wt_tracker_domain;

export const WT_ENABLED: boolean = !!WT_COOKIE_DOMAIN && !!WT_TRACKER_DOMAIN;
