import wt from '@clutter/wt';

import { WT_COOKIE_DOMAIN, WT_COOKIE_EXPIRES, WT_ENABLED, WT_TRACKER_DOMAIN } from '@application/config/wt';

if (WT_ENABLED) {
  wt.initialize({
    trackerDomain: WT_TRACKER_DOMAIN,
    cookies: {
      domain: WT_COOKIE_DOMAIN,
      expires: WT_COOKIE_EXPIRES,
    },
  });
}

export const WT_VISITOR_TOKEN = WT_ENABLED ? wt.getVisitorToken() : '';
