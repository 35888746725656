import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  DateTime: string;
  Hash: any;
};

export type AccountDetails = {
  __typename: 'AccountDetails';
  /** Cost information about the onboarding appointment. */
  onboardingCostDetails: LaborCostDetails;
  /** The set of active and pending subscriptions on the account with upcoming subscription totals. */
  upcomingSubscriptionSet: UpcomingSubscriptionSet;
};

export type Address = {
  __typename: 'Address';
  city: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  state: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type App = {
  __typename: 'App';
  expired: Scalars['Boolean'];
  latestSubscriptionInvoices?: Maybe<Array<Invoice>>;
  mode: Mode;
  order: Order;
  ratable: Scalars['Boolean'];
  tippable: Scalars['Boolean'];
  token: Scalars['String'];
  /** All of the work breaks for a dispatch */
  workBreaks: Array<WorkBreak>;
};

export type Barcode = {
  __typename: 'Barcode';
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type Charge = {
  __typename: 'Charge';
  id: Scalars['ID'];
  sourceBrand: Scalars['String'];
};

export type Checkin = {
  __typename: 'Checkin';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  timestamp: Scalars['DateTime'];
};

export type Customer = {
  __typename: 'Customer';
  firstName: Scalars['String'];
  id: Scalars['ID'];
  initialSetupToken?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CustomerItem = Item | ItemGroup;

export enum CustomerItemTypeEnum {
  Group = 'GROUP',
  Item = 'ITEM'
}


export type DetailedCustomerItemInput = {
  uuid: Scalars['ID'];
  type: CustomerItemTypeEnum;
};

export type Error = {
  __typename: 'Error';
  code: ErrorCodeEnum;
  expected?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ErrorCodeEnum {
  BuildGratuityError = 'BUILD_GRATUITY_ERROR',
  MissingExperienceReview = 'MISSING_EXPERIENCE_REVIEW',
  Unknown = 'UNKNOWN'
}

export type ExperienceReviewCommentInput = {
  comment: Scalars['String'];
};

/** Autogenerated return type of ExperienceReviewCommentUpdate */
export type ExperienceReviewCommentUpdatePayload = {
  __typename: 'ExperienceReviewCommentUpdatePayload';
  error?: Maybe<Error>;
  status: Status;
};

export type ExperienceReviewInput = {
  score: Scalars['Int'];
  reasons: Scalars['Hash'];
};

/** Autogenerated return type of ExperienceReviewUpsert */
export type ExperienceReviewUpsertPayload = {
  __typename: 'ExperienceReviewUpsertPayload';
  status: Status;
};

export type FinancingIntent = {
  __typename: 'FinancingIntent';
  intended: Scalars['Boolean'];
  paymentURL: Scalars['String'];
};

/** Autogenerated return type of GratuityCreate */
export type GratuityCreatePayload = {
  __typename: 'GratuityCreatePayload';
  error?: Maybe<Error>;
  status: Status;
};

export type GratuityInput = {
  amount?: Maybe<Scalars['Int']>;
  skipped: Scalars['Boolean'];
};


export type Image = {
  __typename: 'Image';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  size: Scalars['String'];
  source: Scalars['String'];
  state: ImageState;
};

export enum ImageState {
  /** INITIALIZED */
  Initialized = 'initialized',
  /** REMOVED */
  Removed = 'removed',
  /** TRANSFERRING */
  Transferring = 'transferring',
  /** UPLOADED */
  Uploaded = 'uploaded'
}

export type Invoice = {
  __typename: 'Invoice';
  charges: Array<Charge>;
  id: Scalars['ID'];
  status: InvoiceStatusEnum;
  total: Scalars['Float'];
};

export enum InvoiceStatusEnum {
  Failed = 'FAILED',
  FinalAttemptFailed = 'FINAL_ATTEMPT_FAILED',
  NotAttempted = 'NOT_ATTEMPTED',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED'
}

export type Item = {
  __typename: 'Item';
  barcode: Barcode;
  categoryName?: Maybe<Scalars['String']>;
  contents?: Maybe<Scalars['String']>;
  cuft?: Maybe<Scalars['Float']>;
  hero: Scalars['Int'];
  id: Scalars['ID'];
  images: Array<Image>;
  name?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type ItemGroup = {
  __typename: 'ItemGroup';
  categoryName?: Maybe<Scalars['String']>;
  contents?: Maybe<Scalars['String']>;
  cuft?: Maybe<Scalars['Float']>;
  heroImage?: Maybe<Image>;
  id: Scalars['ID'];
  items: Array<Item>;
  name?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
};

export enum ItemSortEnum {
  CuftAsc = 'CUFT_ASC',
  CuftDesc = 'CUFT_DESC',
  Newest = 'NEWEST'
}

export type ItemUpdateInput = {
  uuid: Scalars['ID'];
  type: CustomerItemTypeEnum;
  name?: Maybe<Scalars['String']>;
  contents?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ItemUpdate */
export type ItemUpdatePayload = {
  __typename: 'ItemUpdatePayload';
  item: CustomerItem;
  status: Status;
};

export type LaborClock = {
  __typename: 'LaborClock';
  id: Scalars['String'];
  role: Scalars['String'];
  user: User;
};

/** Information about labor pricing for a type of labor. */
export type LaborCostDetails = {
  __typename: 'LaborCostDetails';
  /**
   * The service charge and rate of labor after any discounted time has elapsed.
   * For example, "Free", "$65/hour per mover", or "$65/hour per mover (45 minute minimum)"
   * or "$49 service charge + $65/hour per mover".
   */
  formattedCost: Scalars['String'];
};

export enum Mode {
  Arrived = 'ARRIVED',
  ArrivedAtDestination = 'ARRIVED_AT_DESTINATION',
  Canceled = 'CANCELED',
  Departed = 'DEPARTED',
  DepartedForDestination = 'DEPARTED_FOR_DESTINATION',
  Finalize = 'FINALIZE',
  Queued = 'QUEUED',
  Rescheduled = 'RESCHEDULED',
  Summary = 'SUMMARY'
}

export type MovingOperation = {
  __typename: 'MovingOperation';
  destinationAddress: Address;
  id: Scalars['ID'];
};

export type Mutation = {
  __typename: 'Mutation';
  experienceReviewCommentUpdate: ExperienceReviewCommentUpdatePayload;
  experienceReviewUpsert: ExperienceReviewUpsertPayload;
  gratuityCreate: GratuityCreatePayload;
  itemUpdate: ItemUpdatePayload;
  userReviewsUpsert: UserReviewsUpsertPayload;
};


export type MutationExperienceReviewCommentUpdateArgs = {
  input: ExperienceReviewCommentInput;
};


export type MutationExperienceReviewUpsertArgs = {
  input: ExperienceReviewInput;
};


export type MutationGratuityCreateArgs = {
  input: GratuityInput;
};


export type MutationItemUpdateArgs = {
  input: ItemUpdateInput;
};


export type MutationUserReviewsUpsertArgs = {
  inputs: Array<UserReviewInput>;
};

export type Order = {
  __typename: 'Order';
  address: Address;
  arrivalWindow: Scalars['Int'];
  contact?: Maybe<OrderContact>;
  customer: Customer;
  ended?: Maybe<Scalars['DateTime']>;
  eta?: Maybe<Scalars['DateTime']>;
  expectedStartTime?: Maybe<Scalars['DateTime']>;
  financingIntent?: Maybe<FinancingIntent>;
  /** The estimated arrival window based on simulations */
  formattedEstimatedArrivalWindow: Scalars['String'];
  /** The actual arrival window that is currently booked on the order */
  formattedScheduledArrivalWindow: Scalars['String'];
  id: Scalars['ID'];
  invoices?: Maybe<Array<Invoice>>;
  /** Does not support fetching multiple orders (without an n-plus-one) */
  lastCheckin?: Maybe<Checkin>;
  movers: Scalars['Int'];
  movingOperation?: Maybe<MovingOperation>;
  orderEvents: Array<OrderEvent>;
  region: Region;
  route?: Maybe<Route>;
  scheduled: Scalars['DateTime'];
  serviceType: OrderServiceTypeEnum;
  services: Array<OrderTypeEnum>;
  /** If true, it means that the SLA window was changed to a later time than the customer originally booked. */
  slaWindowAdjustedToLaterTime: Scalars['Boolean'];
  started?: Maybe<Scalars['DateTime']>;
  subtype: OrderSubtypeEnum;
  type: OrderTypeEnum;
  /** Does not support fetching multiple orders (without an n-plus-one) */
  usage: Usage;
};

export type OrderContact = {
  __typename: 'OrderContact';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type OrderEvent = {
  __typename: 'OrderEvent';
  category: Scalars['String'];
  eventName: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Image>;
  isBreakEvent: Scalars['Boolean'];
  timestamp: Scalars['DateTime'];
};

export enum OrderServiceTypeEnum {
  Auction = 'AUCTION',
  Capsule = 'CAPSULE',
  CurbsidePickup = 'CURBSIDE_PICKUP',
  Disposal = 'DISPOSAL',
  Doorstep = 'DOORSTEP',
  DropOff = 'DROP_OFF',
  Facility = 'FACILITY',
  FullService = 'FULL_SERVICE',
  LongDistance = 'LONG_DISTANCE',
  Shipment = 'SHIPMENT',
  ThirdPartyReturn = 'THIRD_PARTY_RETURN'
}

export enum OrderSubtypeEnum {
  Cancelation = 'CANCELATION',
  Final = 'FINAL',
  None = 'NONE',
  Onboarding = 'ONBOARDING',
  Packing = 'PACKING',
  Subsequent = 'SUBSEQUENT',
  SupplyKit = 'SUPPLY_KIT',
  Travel = 'TRAVEL',
  Unpacking = 'UNPACKING'
}

export enum OrderTypeEnum {
  Disposal = 'DISPOSAL',
  Move = 'MOVE',
  Pickup = 'PICKUP',
  PickupReturn = 'PICKUP_RETURN',
  Restock = 'RESTOCK',
  RetailDelivery = 'RETAIL_DELIVERY',
  Return = 'RETURN',
  SelfStorageShuttle = 'SELF_STORAGE_SHUTTLE',
  TaskOrder = 'TASK_ORDER'
}

export type PaginatedCustomerItem = {
  __typename: 'PaginatedCustomerItem';
  pagination: Pagination;
  results: Array<CustomerItem>;
};

export type Pagination = {
  __typename: 'Pagination';
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  prevPage?: Maybe<Scalars['Int']>;
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum PlanAdjustmentKindEnum {
  Downgrade = 'DOWNGRADE',
  Upgrade = 'UPGRADE'
}

export type PlanAdjustments = {
  __typename: 'PlanAdjustments';
  planAdjustmentKind?: Maybe<PlanAdjustmentKindEnum>;
  storageSubscriptions: Array<StorageSubscriptions>;
};

export type Query = {
  __typename: 'Query';
  accountDetails: AccountDetails;
  app: App;
  experienceReview?: Maybe<Review>;
  itemCount: Scalars['Int'];
  itemDetailed: CustomerItem;
  itemsPaginated: PaginatedCustomerItem;
  /** Only for pickup items */
  itemsRecent?: Maybe<Array<CustomerItem>>;
  laborClocks: Array<LaborClock>;
  moverNames: Array<Scalars['String']>;
  orderReviewReasons: Array<ReviewReason>;
  planAdjustments: PlanAdjustments;
  share: Share;
  suggestedPerMoverGratuities: SuggestedPerMoverGratuity;
  userReviewReasons: UserReviewReasons;
};


export type QueryItemCountArgs = {
  orderType?: Maybe<OrderTypeEnum>;
};


export type QueryItemDetailedArgs = {
  input: DetailedCustomerItemInput;
};


export type QueryItemsPaginatedArgs = {
  page?: Maybe<Scalars['Int']>;
  per?: Maybe<Scalars['Int']>;
  sort?: Maybe<ItemSortEnum>;
  orderType?: Maybe<OrderTypeEnum>;
};

export enum Rating {
  Good = 'GOOD',
  Poor = 'POOR'
}

export type Region = {
  __typename: 'Region';
  id: Scalars['ID'];
  tz: Scalars['String'];
};

export type Review = {
  __typename: 'Review';
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  rating: Rating;
  score?: Maybe<Scalars['Int']>;
};

export type ReviewReason = {
  __typename: 'ReviewReason';
  negativeCopy?: Maybe<Scalars['String']>;
  positiveCopy?: Maybe<Scalars['String']>;
  reasonCode: Scalars['String'];
};

export type Route = {
  __typename: 'Route';
  eta?: Maybe<Scalars['DateTime']>;
  kind: Scalars['String'];
  polyline: Scalars['String'];
};

export type Share = {
  __typename: 'Share';
  text: Scalars['String'];
  url: Scalars['String'];
};

export enum Status {
  Ok = 'OK',
  Unauthorized = 'UNAUTHORIZED',
  Unprocessable = 'UNPROCESSABLE'
}

export type StorageSubscriptions = {
  __typename: 'StorageSubscriptions';
  active: Scalars['Boolean'];
  discountedTotal: Scalars['Float'];
  formattedDimensions: Scalars['String'];
};

export type Subscription = {
  __typename: 'Subscription';
  displayDimensions?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  total: Scalars['Float'];
};

export type SuggestedPerMoverGratuity = {
  __typename: 'SuggestedPerMoverGratuity';
  high: Scalars['Int'];
  low: Scalars['Int'];
  mid: Scalars['Int'];
};

export type UpcomingSubscriptionSet = {
  __typename: 'UpcomingSubscriptionSet';
  displayDimensions?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  storageSubscriptions: Array<Subscription>;
  total: Scalars['Float'];
};

export type Usage = {
  __typename: 'Usage';
  formattedCubicFootage: Scalars['String'];
  totalCubicFootage: Scalars['Int'];
  usedCubicFootage: Scalars['Float'];
};

export type User = {
  __typename: 'User';
  externalID: Scalars['ID'];
  given: Scalars['String'];
  id: Scalars['String'];
  imageURL?: Maybe<Scalars['String']>;
  initials: Scalars['String'];
};

export type UserReviewInput = {
  userID: Scalars['ID'];
  rating: Rating;
  reasons?: Maybe<Scalars['Hash']>;
};

export type UserReviewReasons = {
  __typename: 'UserReviewReasons';
  associateReasons: Array<ReviewReason>;
  leadReasons: Array<ReviewReason>;
};

/** Autogenerated return type of UserReviewsUpsert */
export type UserReviewsUpsertPayload = {
  __typename: 'UserReviewsUpsertPayload';
  status: Status;
};

export type WorkBreak = {
  __typename: 'WorkBreak';
  ended?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  started?: Maybe<Scalars['DateTime']>;
};

export type AddressFragment = (
  { __typename: 'Address' }
  & Pick<Address, 'latitude' | 'longitude'>
);

export type AppFragment = (
  { __typename: 'App' }
  & Pick<App, 'token' | 'tippable' | 'ratable' | 'expired' | 'mode'>
  & { order: (
    { __typename: 'Order' }
    & OrderFragment
  ), workBreaks: Array<(
    { __typename: 'WorkBreak' }
    & Pick<WorkBreak, 'id' | 'started' | 'ended'>
  )>, latestSubscriptionInvoices?: Maybe<Array<(
    { __typename: 'Invoice' }
    & InvoiceFragment
  )>> }
);

export type ContactFragment = (
  { __typename: 'OrderContact' }
  & Pick<OrderContact, 'name'>
);

export type CustomerFragment = (
  { __typename: 'Customer' }
  & Pick<Customer, 'firstName' | 'name' | 'initialSetupToken'>
);

type CustomerItem_Item_Fragment = (
  { __typename: 'Item' }
  & ItemFragment
);

type CustomerItem_ItemGroup_Fragment = (
  { __typename: 'ItemGroup' }
  & ItemGroupFragment
);

export type CustomerItemFragment = CustomerItem_Item_Fragment | CustomerItem_ItemGroup_Fragment;

type DetailedCustomerItem_Item_Fragment = (
  { __typename: 'Item' }
  & DetailedItemFragment
);

type DetailedCustomerItem_ItemGroup_Fragment = (
  { __typename: 'ItemGroup' }
  & DetailedItemGroupFragment
);

export type DetailedCustomerItemFragment = DetailedCustomerItem_Item_Fragment | DetailedCustomerItem_ItemGroup_Fragment;

export type DetailedItemFragment = (
  { __typename: 'Item' }
  & Pick<Item, 'name' | 'contents'>
  & ItemFragment
);

export type DetailedItemGroupFragment = (
  { __typename: 'ItemGroup' }
  & Pick<ItemGroup, 'name' | 'contents'>
  & { items: Array<(
    { __typename: 'Item' }
    & { images: Array<(
      { __typename: 'Image' }
      & ImageFragment
    )> }
  )> }
  & ItemGroupFragment
);

export type ImageFragment = (
  { __typename: 'Image' }
  & Pick<Image, 'source' | 'state'>
);

export type InvoiceFragment = (
  { __typename: 'Invoice' }
  & Pick<Invoice, 'id' | 'total' | 'status'>
  & { charges: Array<(
    { __typename: 'Charge' }
    & Pick<Charge, 'sourceBrand'>
  )> }
);

export type ItemFragment = (
  { __typename: 'Item' }
  & Pick<Item, 'id' | 'uuid' | 'name' | 'categoryName' | 'cuft' | 'hero' | 'timestamp'>
  & { barcode: (
    { __typename: 'Barcode' }
    & Pick<Barcode, 'value'>
  ), images: Array<(
    { __typename: 'Image' }
    & ImageFragment
  )> }
);

export type ItemGroupFragment = (
  { __typename: 'ItemGroup' }
  & Pick<ItemGroup, 'id' | 'uuid' | 'name' | 'categoryName' | 'cuft'>
  & { heroImage?: Maybe<(
    { __typename: 'Image' }
    & ImageFragment
  )> }
);

export type LaborClockFragment = (
  { __typename: 'LaborClock' }
  & Pick<LaborClock, 'id' | 'role'>
  & { user: (
    { __typename: 'User' }
    & UserFragment
  ) }
);

export type OrderFragment = (
  { __typename: 'Order' }
  & Pick<Order, 'id' | 'eta' | 'scheduled' | 'services' | 'serviceType' | 'subtype' | 'type' | 'started' | 'ended' | 'formattedEstimatedArrivalWindow' | 'formattedScheduledArrivalWindow' | 'slaWindowAdjustedToLaterTime'>
  & { lastCheckin?: Maybe<(
    { __typename: 'Checkin' }
    & Pick<Checkin, 'latitude' | 'longitude' | 'timestamp'>
  )>, orderEvents: Array<(
    { __typename: 'OrderEvent' }
    & Pick<OrderEvent, 'id' | 'category' | 'eventName'>
    & { image?: Maybe<(
      { __typename: 'Image' }
      & Pick<Image, 'id' | 'source' | 'state'>
    )> }
  )>, region: (
    { __typename: 'Region' }
    & Pick<Region, 'id' | 'tz'>
  ), route?: Maybe<(
    { __typename: 'Route' }
    & Pick<Route, 'polyline' | 'kind' | 'eta'>
  )>, usage: (
    { __typename: 'Usage' }
    & UsageFragment
  ), address: (
    { __typename: 'Address' }
    & AddressFragment
  ), contact?: Maybe<(
    { __typename: 'OrderContact' }
    & ContactFragment
  )>, customer: (
    { __typename: 'Customer' }
    & CustomerFragment
  ), invoices?: Maybe<Array<(
    { __typename: 'Invoice' }
    & InvoiceFragment
  )>>, financingIntent?: Maybe<(
    { __typename: 'FinancingIntent' }
    & Pick<FinancingIntent, 'intended' | 'paymentURL'>
  )>, movingOperation?: Maybe<(
    { __typename: 'MovingOperation' }
    & { destinationAddress: (
      { __typename: 'Address' }
      & AddressFragment
    ) }
  )> }
);

export type StorageSubscriptionsFragment = (
  { __typename: 'StorageSubscriptions' }
  & Pick<StorageSubscriptions, 'active' | 'formattedDimensions' | 'discountedTotal'>
);

export type SubscriptionFragment = (
  { __typename: 'Subscription' }
  & Pick<Subscription, 'id' | 'total' | 'displayDimensions'>
);

export type UsageFragment = (
  { __typename: 'Usage' }
  & Pick<Usage, 'usedCubicFootage' | 'totalCubicFootage' | 'formattedCubicFootage'>
);

export type UserFragment = (
  { __typename: 'User' }
  & Pick<User, 'id' | 'given' | 'initials' | 'imageURL' | 'externalID'>
);

export type ExperienceReviewCommentUpdateMutationVariables = Exact<{
  input: ExperienceReviewCommentInput;
}>;


export type ExperienceReviewCommentUpdateMutation = (
  { __typename: 'Mutation' }
  & { experienceReviewCommentUpdate: (
    { __typename: 'ExperienceReviewCommentUpdatePayload' }
    & Pick<ExperienceReviewCommentUpdatePayload, 'status'>
    & { error?: Maybe<(
      { __typename: 'Error' }
      & Pick<Error, 'code' | 'message'>
    )> }
  ) }
);

export type ExperienceReviewUpsertMutationVariables = Exact<{
  input: ExperienceReviewInput;
}>;


export type ExperienceReviewUpsertMutation = (
  { __typename: 'Mutation' }
  & { experienceReviewUpsert: (
    { __typename: 'ExperienceReviewUpsertPayload' }
    & Pick<ExperienceReviewUpsertPayload, 'status'>
  ) }
);

export type GratuityCreateMutationVariables = Exact<{
  input: GratuityInput;
}>;


export type GratuityCreateMutation = (
  { __typename: 'Mutation' }
  & { gratuityCreate: (
    { __typename: 'GratuityCreatePayload' }
    & Pick<GratuityCreatePayload, 'status'>
    & { error?: Maybe<(
      { __typename: 'Error' }
      & Pick<Error, 'code' | 'message' | 'expected'>
    )> }
  ) }
);

export type ItemUpdateMutationVariables = Exact<{
  input: ItemUpdateInput;
}>;


export type ItemUpdateMutation = (
  { __typename: 'Mutation' }
  & { itemUpdate: (
    { __typename: 'ItemUpdatePayload' }
    & Pick<ItemUpdatePayload, 'status'>
    & { item: (
      { __typename: 'Item' }
      & Pick<Item, 'id' | 'name' | 'contents'>
    ) | (
      { __typename: 'ItemGroup' }
      & Pick<ItemGroup, 'id' | 'name' | 'contents'>
    ) }
  ) }
);

export type UserReviewsUpsertMutationVariables = Exact<{
  inputs: Array<UserReviewInput>;
}>;


export type UserReviewsUpsertMutation = (
  { __typename: 'Mutation' }
  & { userReviewsUpsert: (
    { __typename: 'UserReviewsUpsertPayload' }
    & Pick<UserReviewsUpsertPayload, 'status'>
  ) }
);

export type AccountDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountDetailsQuery = (
  { __typename: 'Query' }
  & { accountDetails: (
    { __typename: 'AccountDetails' }
    & { upcomingSubscriptionSet: (
      { __typename: 'UpcomingSubscriptionSet' }
      & { storageSubscriptions: Array<(
        { __typename: 'Subscription' }
        & SubscriptionFragment
      )> }
    ), onboardingCostDetails: (
      { __typename: 'LaborCostDetails' }
      & Pick<LaborCostDetails, 'formattedCost'>
    ) }
  ) }
);

export type AppQueryVariables = Exact<{ [key: string]: never; }>;


export type AppQuery = (
  { __typename: 'Query' }
  & { app: (
    { __typename: 'App' }
    & AppFragment
  ) }
);

export type ExperienceReviewQueryVariables = Exact<{ [key: string]: never; }>;


export type ExperienceReviewQuery = (
  { __typename: 'Query' }
  & { experienceReview?: Maybe<(
    { __typename: 'Review' }
    & Pick<Review, 'id' | 'rating'>
  )> }
);

export type ItemCountQueryVariables = Exact<{
  orderType?: Maybe<OrderTypeEnum>;
}>;


export type ItemCountQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'itemCount'>
);

export type ItemDetailedQueryVariables = Exact<{
  input: DetailedCustomerItemInput;
}>;


export type ItemDetailedQuery = (
  { __typename: 'Query' }
  & { itemDetailed: (
    { __typename: 'Item' }
    & DetailedCustomerItem_Item_Fragment
  ) | (
    { __typename: 'ItemGroup' }
    & DetailedCustomerItem_ItemGroup_Fragment
  ) }
);

export type ItemsPaginatedQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  per?: Maybe<Scalars['Int']>;
  sort?: Maybe<ItemSortEnum>;
  orderType?: Maybe<OrderTypeEnum>;
}>;


export type ItemsPaginatedQuery = (
  { __typename: 'Query' }
  & { itemsPaginated: (
    { __typename: 'PaginatedCustomerItem' }
    & { pagination: (
      { __typename: 'Pagination' }
      & Pick<Pagination, 'currentPage' | 'totalCount' | 'totalPages'>
    ), results: Array<(
      { __typename: 'Item' }
      & CustomerItem_Item_Fragment
    ) | (
      { __typename: 'ItemGroup' }
      & CustomerItem_ItemGroup_Fragment
    )> }
  ) }
);

export type ItemsRecentQueryVariables = Exact<{ [key: string]: never; }>;


export type ItemsRecentQuery = (
  { __typename: 'Query' }
  & { itemsRecent?: Maybe<Array<(
    { __typename: 'Item' }
    & CustomerItem_Item_Fragment
  ) | (
    { __typename: 'ItemGroup' }
    & CustomerItem_ItemGroup_Fragment
  )>> }
);

export type LaborClocksQueryVariables = Exact<{ [key: string]: never; }>;


export type LaborClocksQuery = (
  { __typename: 'Query' }
  & { laborClocks: Array<(
    { __typename: 'LaborClock' }
    & LaborClockFragment
  )> }
);

export type MoverNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type MoverNamesQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'moverNames'>
);

export type OrderReviewReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderReviewReasonsQuery = (
  { __typename: 'Query' }
  & { orderReviewReasons: Array<(
    { __typename: 'ReviewReason' }
    & Pick<ReviewReason, 'positiveCopy' | 'negativeCopy' | 'reasonCode'>
  )> }
);

export type PlanAdjustmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type PlanAdjustmentsQuery = (
  { __typename: 'Query' }
  & { planAdjustments: (
    { __typename: 'PlanAdjustments' }
    & Pick<PlanAdjustments, 'planAdjustmentKind'>
    & { storageSubscriptions: Array<(
      { __typename: 'StorageSubscriptions' }
      & StorageSubscriptionsFragment
    )> }
  ) }
);

export type ShareQueryVariables = Exact<{ [key: string]: never; }>;


export type ShareQuery = (
  { __typename: 'Query' }
  & { share: (
    { __typename: 'Share' }
    & Pick<Share, 'text' | 'url'>
  ) }
);

export type SuggestedPerMoverGratuitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type SuggestedPerMoverGratuitiesQuery = (
  { __typename: 'Query' }
  & { suggestedPerMoverGratuities: (
    { __typename: 'SuggestedPerMoverGratuity' }
    & Pick<SuggestedPerMoverGratuity, 'low' | 'mid' | 'high'>
  ) }
);

export type UserReviewReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserReviewReasonsQuery = (
  { __typename: 'Query' }
  & { userReviewReasons: (
    { __typename: 'UserReviewReasons' }
    & { associateReasons: Array<(
      { __typename: 'ReviewReason' }
      & Pick<ReviewReason, 'positiveCopy' | 'negativeCopy' | 'reasonCode'>
    )>, leadReasons: Array<(
      { __typename: 'ReviewReason' }
      & Pick<ReviewReason, 'positiveCopy' | 'negativeCopy' | 'reasonCode'>
    )> }
  ) }
);

export const UsageFragmentDoc = gql`
    fragment usage on Usage {
  usedCubicFootage
  totalCubicFootage
  formattedCubicFootage
}
    `;
export const AddressFragmentDoc = gql`
    fragment address on Address {
  latitude
  longitude
}
    `;
export const ContactFragmentDoc = gql`
    fragment contact on OrderContact {
  name
}
    `;
export const CustomerFragmentDoc = gql`
    fragment customer on Customer {
  firstName
  name
  initialSetupToken
}
    `;
export const InvoiceFragmentDoc = gql`
    fragment invoice on Invoice {
  id
  total
  status
  charges {
    sourceBrand
  }
}
    `;
export const OrderFragmentDoc = gql`
    fragment order on Order {
  id
  eta
  lastCheckin {
    latitude
    longitude
    timestamp
  }
  orderEvents {
    id
    category
    eventName
    image {
      id
      source
      state
    }
  }
  region {
    id
    tz
  }
  route {
    polyline
    kind
    eta
  }
  scheduled
  services
  serviceType
  subtype
  type
  usage {
    ...usage
  }
  address {
    ...address
  }
  contact {
    ...contact
  }
  customer {
    ...customer
  }
  started
  ended
  invoices {
    ...invoice
  }
  financingIntent {
    intended
    paymentURL
  }
  movingOperation {
    destinationAddress {
      ...address
    }
  }
  formattedEstimatedArrivalWindow
  formattedScheduledArrivalWindow
  slaWindowAdjustedToLaterTime
}
    ${UsageFragmentDoc}
${AddressFragmentDoc}
${ContactFragmentDoc}
${CustomerFragmentDoc}
${InvoiceFragmentDoc}`;
export const AppFragmentDoc = gql`
    fragment app on App {
  token
  tippable
  ratable
  expired
  order {
    ...order
  }
  mode
  workBreaks {
    id
    started
    ended
  }
  latestSubscriptionInvoices {
    ...invoice
  }
}
    ${OrderFragmentDoc}
${InvoiceFragmentDoc}`;
export const ImageFragmentDoc = gql`
    fragment image on Image {
  source
  state
}
    `;
export const ItemFragmentDoc = gql`
    fragment item on Item {
  id
  uuid
  barcode {
    value
  }
  name
  categoryName
  cuft
  images {
    ...image
  }
  hero
  timestamp
}
    ${ImageFragmentDoc}`;
export const ItemGroupFragmentDoc = gql`
    fragment itemGroup on ItemGroup {
  id
  uuid
  name
  categoryName
  cuft
  heroImage {
    ...image
  }
}
    ${ImageFragmentDoc}`;
export const CustomerItemFragmentDoc = gql`
    fragment customerItem on CustomerItem {
  ... on Item {
    ...item
  }
  ... on ItemGroup {
    ...itemGroup
  }
}
    ${ItemFragmentDoc}
${ItemGroupFragmentDoc}`;
export const DetailedItemFragmentDoc = gql`
    fragment detailedItem on Item {
  ...item
  name
  contents
}
    ${ItemFragmentDoc}`;
export const DetailedItemGroupFragmentDoc = gql`
    fragment detailedItemGroup on ItemGroup {
  ...itemGroup
  name
  contents
  items {
    images {
      ...image
    }
  }
}
    ${ItemGroupFragmentDoc}
${ImageFragmentDoc}`;
export const DetailedCustomerItemFragmentDoc = gql`
    fragment detailedCustomerItem on CustomerItem {
  ... on Item {
    ...detailedItem
  }
  ... on ItemGroup {
    ...detailedItemGroup
  }
}
    ${DetailedItemFragmentDoc}
${DetailedItemGroupFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment user on User {
  id
  given
  initials
  imageURL
  externalID
}
    `;
export const LaborClockFragmentDoc = gql`
    fragment laborClock on LaborClock {
  id
  role
  user {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const StorageSubscriptionsFragmentDoc = gql`
    fragment storageSubscriptions on StorageSubscriptions {
  active
  formattedDimensions
  discountedTotal
}
    `;
export const SubscriptionFragmentDoc = gql`
    fragment subscription on Subscription {
  id
  total
  displayDimensions
}
    `;
export const ExperienceReviewCommentUpdateDocument = gql`
    mutation ExperienceReviewCommentUpdate($input: ExperienceReviewCommentInput!) {
  experienceReviewCommentUpdate(input: $input) {
    status
    error {
      code
      message
    }
  }
}
    `;
export type ExperienceReviewCommentUpdateMutationFn = Apollo.MutationFunction<ExperienceReviewCommentUpdateMutation, ExperienceReviewCommentUpdateMutationVariables>;

/**
 * __useExperienceReviewCommentUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceReviewCommentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceReviewCommentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceReviewCommentUpdateMutation, { data, loading, error }] = useExperienceReviewCommentUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExperienceReviewCommentUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ExperienceReviewCommentUpdateMutation, ExperienceReviewCommentUpdateMutationVariables>) {
        return Apollo.useMutation<ExperienceReviewCommentUpdateMutation, ExperienceReviewCommentUpdateMutationVariables>(ExperienceReviewCommentUpdateDocument, baseOptions);
      }
export type ExperienceReviewCommentUpdateMutationHookResult = ReturnType<typeof useExperienceReviewCommentUpdateMutation>;
export type ExperienceReviewCommentUpdateMutationResult = Apollo.MutationResult<ExperienceReviewCommentUpdateMutation>;
export type ExperienceReviewCommentUpdateMutationOptions = Apollo.BaseMutationOptions<ExperienceReviewCommentUpdateMutation, ExperienceReviewCommentUpdateMutationVariables>;
export const ExperienceReviewUpsertDocument = gql`
    mutation ExperienceReviewUpsert($input: ExperienceReviewInput!) {
  experienceReviewUpsert(input: $input) {
    status
  }
}
    `;
export type ExperienceReviewUpsertMutationFn = Apollo.MutationFunction<ExperienceReviewUpsertMutation, ExperienceReviewUpsertMutationVariables>;

/**
 * __useExperienceReviewUpsertMutation__
 *
 * To run a mutation, you first call `useExperienceReviewUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceReviewUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceReviewUpsertMutation, { data, loading, error }] = useExperienceReviewUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExperienceReviewUpsertMutation(baseOptions?: Apollo.MutationHookOptions<ExperienceReviewUpsertMutation, ExperienceReviewUpsertMutationVariables>) {
        return Apollo.useMutation<ExperienceReviewUpsertMutation, ExperienceReviewUpsertMutationVariables>(ExperienceReviewUpsertDocument, baseOptions);
      }
export type ExperienceReviewUpsertMutationHookResult = ReturnType<typeof useExperienceReviewUpsertMutation>;
export type ExperienceReviewUpsertMutationResult = Apollo.MutationResult<ExperienceReviewUpsertMutation>;
export type ExperienceReviewUpsertMutationOptions = Apollo.BaseMutationOptions<ExperienceReviewUpsertMutation, ExperienceReviewUpsertMutationVariables>;
export const GratuityCreateDocument = gql`
    mutation GratuityCreate($input: GratuityInput!) {
  gratuityCreate(input: $input) {
    status
    error {
      code
      message
      expected
    }
  }
}
    `;
export type GratuityCreateMutationFn = Apollo.MutationFunction<GratuityCreateMutation, GratuityCreateMutationVariables>;

/**
 * __useGratuityCreateMutation__
 *
 * To run a mutation, you first call `useGratuityCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGratuityCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gratuityCreateMutation, { data, loading, error }] = useGratuityCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGratuityCreateMutation(baseOptions?: Apollo.MutationHookOptions<GratuityCreateMutation, GratuityCreateMutationVariables>) {
        return Apollo.useMutation<GratuityCreateMutation, GratuityCreateMutationVariables>(GratuityCreateDocument, baseOptions);
      }
export type GratuityCreateMutationHookResult = ReturnType<typeof useGratuityCreateMutation>;
export type GratuityCreateMutationResult = Apollo.MutationResult<GratuityCreateMutation>;
export type GratuityCreateMutationOptions = Apollo.BaseMutationOptions<GratuityCreateMutation, GratuityCreateMutationVariables>;
export const ItemUpdateDocument = gql`
    mutation ItemUpdate($input: ItemUpdateInput!) {
  itemUpdate(input: $input) {
    status
    item {
      ... on Item {
        id
        name
        contents
      }
      ... on ItemGroup {
        id
        name
        contents
      }
    }
  }
}
    `;
export type ItemUpdateMutationFn = Apollo.MutationFunction<ItemUpdateMutation, ItemUpdateMutationVariables>;

/**
 * __useItemUpdateMutation__
 *
 * To run a mutation, you first call `useItemUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useItemUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [itemUpdateMutation, { data, loading, error }] = useItemUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useItemUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ItemUpdateMutation, ItemUpdateMutationVariables>) {
        return Apollo.useMutation<ItemUpdateMutation, ItemUpdateMutationVariables>(ItemUpdateDocument, baseOptions);
      }
export type ItemUpdateMutationHookResult = ReturnType<typeof useItemUpdateMutation>;
export type ItemUpdateMutationResult = Apollo.MutationResult<ItemUpdateMutation>;
export type ItemUpdateMutationOptions = Apollo.BaseMutationOptions<ItemUpdateMutation, ItemUpdateMutationVariables>;
export const UserReviewsUpsertDocument = gql`
    mutation UserReviewsUpsert($inputs: [UserReviewInput!]!) {
  userReviewsUpsert(inputs: $inputs) {
    status
  }
}
    `;
export type UserReviewsUpsertMutationFn = Apollo.MutationFunction<UserReviewsUpsertMutation, UserReviewsUpsertMutationVariables>;

/**
 * __useUserReviewsUpsertMutation__
 *
 * To run a mutation, you first call `useUserReviewsUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserReviewsUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userReviewsUpsertMutation, { data, loading, error }] = useUserReviewsUpsertMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUserReviewsUpsertMutation(baseOptions?: Apollo.MutationHookOptions<UserReviewsUpsertMutation, UserReviewsUpsertMutationVariables>) {
        return Apollo.useMutation<UserReviewsUpsertMutation, UserReviewsUpsertMutationVariables>(UserReviewsUpsertDocument, baseOptions);
      }
export type UserReviewsUpsertMutationHookResult = ReturnType<typeof useUserReviewsUpsertMutation>;
export type UserReviewsUpsertMutationResult = Apollo.MutationResult<UserReviewsUpsertMutation>;
export type UserReviewsUpsertMutationOptions = Apollo.BaseMutationOptions<UserReviewsUpsertMutation, UserReviewsUpsertMutationVariables>;
export const AccountDetailsDocument = gql`
    query AccountDetails {
  accountDetails {
    upcomingSubscriptionSet {
      storageSubscriptions {
        ...subscription
      }
    }
    onboardingCostDetails {
      formattedCost
    }
  }
}
    ${SubscriptionFragmentDoc}`;

/**
 * __useAccountDetailsQuery__
 *
 * To run a query within a React component, call `useAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountDetailsQuery(baseOptions?: Apollo.QueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>) {
        return Apollo.useQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(AccountDetailsDocument, baseOptions);
      }
export function useAccountDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>) {
          return Apollo.useLazyQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(AccountDetailsDocument, baseOptions);
        }
export type AccountDetailsQueryHookResult = ReturnType<typeof useAccountDetailsQuery>;
export type AccountDetailsLazyQueryHookResult = ReturnType<typeof useAccountDetailsLazyQuery>;
export type AccountDetailsQueryResult = Apollo.QueryResult<AccountDetailsQuery, AccountDetailsQueryVariables>;
export const AppDocument = gql`
    query App {
  app {
    ...app
  }
}
    ${AppFragmentDoc}`;

/**
 * __useAppQuery__
 *
 * To run a query within a React component, call `useAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppQuery(baseOptions?: Apollo.QueryHookOptions<AppQuery, AppQueryVariables>) {
        return Apollo.useQuery<AppQuery, AppQueryVariables>(AppDocument, baseOptions);
      }
export function useAppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppQuery, AppQueryVariables>) {
          return Apollo.useLazyQuery<AppQuery, AppQueryVariables>(AppDocument, baseOptions);
        }
export type AppQueryHookResult = ReturnType<typeof useAppQuery>;
export type AppLazyQueryHookResult = ReturnType<typeof useAppLazyQuery>;
export type AppQueryResult = Apollo.QueryResult<AppQuery, AppQueryVariables>;
export const ExperienceReviewDocument = gql`
    query ExperienceReview {
  experienceReview {
    id
    rating
  }
}
    `;

/**
 * __useExperienceReviewQuery__
 *
 * To run a query within a React component, call `useExperienceReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceReviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useExperienceReviewQuery(baseOptions?: Apollo.QueryHookOptions<ExperienceReviewQuery, ExperienceReviewQueryVariables>) {
        return Apollo.useQuery<ExperienceReviewQuery, ExperienceReviewQueryVariables>(ExperienceReviewDocument, baseOptions);
      }
export function useExperienceReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExperienceReviewQuery, ExperienceReviewQueryVariables>) {
          return Apollo.useLazyQuery<ExperienceReviewQuery, ExperienceReviewQueryVariables>(ExperienceReviewDocument, baseOptions);
        }
export type ExperienceReviewQueryHookResult = ReturnType<typeof useExperienceReviewQuery>;
export type ExperienceReviewLazyQueryHookResult = ReturnType<typeof useExperienceReviewLazyQuery>;
export type ExperienceReviewQueryResult = Apollo.QueryResult<ExperienceReviewQuery, ExperienceReviewQueryVariables>;
export const ItemCountDocument = gql`
    query ItemCount($orderType: OrderTypeEnum) {
  itemCount(orderType: $orderType)
}
    `;

/**
 * __useItemCountQuery__
 *
 * To run a query within a React component, call `useItemCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemCountQuery({
 *   variables: {
 *      orderType: // value for 'orderType'
 *   },
 * });
 */
export function useItemCountQuery(baseOptions?: Apollo.QueryHookOptions<ItemCountQuery, ItemCountQueryVariables>) {
        return Apollo.useQuery<ItemCountQuery, ItemCountQueryVariables>(ItemCountDocument, baseOptions);
      }
export function useItemCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemCountQuery, ItemCountQueryVariables>) {
          return Apollo.useLazyQuery<ItemCountQuery, ItemCountQueryVariables>(ItemCountDocument, baseOptions);
        }
export type ItemCountQueryHookResult = ReturnType<typeof useItemCountQuery>;
export type ItemCountLazyQueryHookResult = ReturnType<typeof useItemCountLazyQuery>;
export type ItemCountQueryResult = Apollo.QueryResult<ItemCountQuery, ItemCountQueryVariables>;
export const ItemDetailedDocument = gql`
    query ItemDetailed($input: DetailedCustomerItemInput!) {
  itemDetailed(input: $input) {
    ...detailedCustomerItem
  }
}
    ${DetailedCustomerItemFragmentDoc}`;

/**
 * __useItemDetailedQuery__
 *
 * To run a query within a React component, call `useItemDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemDetailedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useItemDetailedQuery(baseOptions: Apollo.QueryHookOptions<ItemDetailedQuery, ItemDetailedQueryVariables>) {
        return Apollo.useQuery<ItemDetailedQuery, ItemDetailedQueryVariables>(ItemDetailedDocument, baseOptions);
      }
export function useItemDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemDetailedQuery, ItemDetailedQueryVariables>) {
          return Apollo.useLazyQuery<ItemDetailedQuery, ItemDetailedQueryVariables>(ItemDetailedDocument, baseOptions);
        }
export type ItemDetailedQueryHookResult = ReturnType<typeof useItemDetailedQuery>;
export type ItemDetailedLazyQueryHookResult = ReturnType<typeof useItemDetailedLazyQuery>;
export type ItemDetailedQueryResult = Apollo.QueryResult<ItemDetailedQuery, ItemDetailedQueryVariables>;
export const ItemsPaginatedDocument = gql`
    query ItemsPaginated($page: Int, $per: Int, $sort: ItemSortEnum, $orderType: OrderTypeEnum) {
  itemsPaginated(page: $page, per: $per, sort: $sort, orderType: $orderType) {
    pagination {
      currentPage
      totalCount
      totalPages
    }
    results {
      ...customerItem
    }
  }
}
    ${CustomerItemFragmentDoc}`;

/**
 * __useItemsPaginatedQuery__
 *
 * To run a query within a React component, call `useItemsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsPaginatedQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per: // value for 'per'
 *      sort: // value for 'sort'
 *      orderType: // value for 'orderType'
 *   },
 * });
 */
export function useItemsPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<ItemsPaginatedQuery, ItemsPaginatedQueryVariables>) {
        return Apollo.useQuery<ItemsPaginatedQuery, ItemsPaginatedQueryVariables>(ItemsPaginatedDocument, baseOptions);
      }
export function useItemsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsPaginatedQuery, ItemsPaginatedQueryVariables>) {
          return Apollo.useLazyQuery<ItemsPaginatedQuery, ItemsPaginatedQueryVariables>(ItemsPaginatedDocument, baseOptions);
        }
export type ItemsPaginatedQueryHookResult = ReturnType<typeof useItemsPaginatedQuery>;
export type ItemsPaginatedLazyQueryHookResult = ReturnType<typeof useItemsPaginatedLazyQuery>;
export type ItemsPaginatedQueryResult = Apollo.QueryResult<ItemsPaginatedQuery, ItemsPaginatedQueryVariables>;
export const ItemsRecentDocument = gql`
    query ItemsRecent {
  itemsRecent {
    ...customerItem
  }
}
    ${CustomerItemFragmentDoc}`;

/**
 * __useItemsRecentQuery__
 *
 * To run a query within a React component, call `useItemsRecentQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsRecentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsRecentQuery({
 *   variables: {
 *   },
 * });
 */
export function useItemsRecentQuery(baseOptions?: Apollo.QueryHookOptions<ItemsRecentQuery, ItemsRecentQueryVariables>) {
        return Apollo.useQuery<ItemsRecentQuery, ItemsRecentQueryVariables>(ItemsRecentDocument, baseOptions);
      }
export function useItemsRecentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsRecentQuery, ItemsRecentQueryVariables>) {
          return Apollo.useLazyQuery<ItemsRecentQuery, ItemsRecentQueryVariables>(ItemsRecentDocument, baseOptions);
        }
export type ItemsRecentQueryHookResult = ReturnType<typeof useItemsRecentQuery>;
export type ItemsRecentLazyQueryHookResult = ReturnType<typeof useItemsRecentLazyQuery>;
export type ItemsRecentQueryResult = Apollo.QueryResult<ItemsRecentQuery, ItemsRecentQueryVariables>;
export const LaborClocksDocument = gql`
    query LaborClocks {
  laborClocks {
    ...laborClock
  }
}
    ${LaborClockFragmentDoc}`;

/**
 * __useLaborClocksQuery__
 *
 * To run a query within a React component, call `useLaborClocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLaborClocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLaborClocksQuery({
 *   variables: {
 *   },
 * });
 */
export function useLaborClocksQuery(baseOptions?: Apollo.QueryHookOptions<LaborClocksQuery, LaborClocksQueryVariables>) {
        return Apollo.useQuery<LaborClocksQuery, LaborClocksQueryVariables>(LaborClocksDocument, baseOptions);
      }
export function useLaborClocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LaborClocksQuery, LaborClocksQueryVariables>) {
          return Apollo.useLazyQuery<LaborClocksQuery, LaborClocksQueryVariables>(LaborClocksDocument, baseOptions);
        }
export type LaborClocksQueryHookResult = ReturnType<typeof useLaborClocksQuery>;
export type LaborClocksLazyQueryHookResult = ReturnType<typeof useLaborClocksLazyQuery>;
export type LaborClocksQueryResult = Apollo.QueryResult<LaborClocksQuery, LaborClocksQueryVariables>;
export const MoverNamesDocument = gql`
    query MoverNames {
  moverNames
}
    `;

/**
 * __useMoverNamesQuery__
 *
 * To run a query within a React component, call `useMoverNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoverNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoverNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMoverNamesQuery(baseOptions?: Apollo.QueryHookOptions<MoverNamesQuery, MoverNamesQueryVariables>) {
        return Apollo.useQuery<MoverNamesQuery, MoverNamesQueryVariables>(MoverNamesDocument, baseOptions);
      }
export function useMoverNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MoverNamesQuery, MoverNamesQueryVariables>) {
          return Apollo.useLazyQuery<MoverNamesQuery, MoverNamesQueryVariables>(MoverNamesDocument, baseOptions);
        }
export type MoverNamesQueryHookResult = ReturnType<typeof useMoverNamesQuery>;
export type MoverNamesLazyQueryHookResult = ReturnType<typeof useMoverNamesLazyQuery>;
export type MoverNamesQueryResult = Apollo.QueryResult<MoverNamesQuery, MoverNamesQueryVariables>;
export const OrderReviewReasonsDocument = gql`
    query OrderReviewReasons {
  orderReviewReasons {
    positiveCopy
    negativeCopy
    reasonCode
  }
}
    `;

/**
 * __useOrderReviewReasonsQuery__
 *
 * To run a query within a React component, call `useOrderReviewReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderReviewReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderReviewReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderReviewReasonsQuery(baseOptions?: Apollo.QueryHookOptions<OrderReviewReasonsQuery, OrderReviewReasonsQueryVariables>) {
        return Apollo.useQuery<OrderReviewReasonsQuery, OrderReviewReasonsQueryVariables>(OrderReviewReasonsDocument, baseOptions);
      }
export function useOrderReviewReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderReviewReasonsQuery, OrderReviewReasonsQueryVariables>) {
          return Apollo.useLazyQuery<OrderReviewReasonsQuery, OrderReviewReasonsQueryVariables>(OrderReviewReasonsDocument, baseOptions);
        }
export type OrderReviewReasonsQueryHookResult = ReturnType<typeof useOrderReviewReasonsQuery>;
export type OrderReviewReasonsLazyQueryHookResult = ReturnType<typeof useOrderReviewReasonsLazyQuery>;
export type OrderReviewReasonsQueryResult = Apollo.QueryResult<OrderReviewReasonsQuery, OrderReviewReasonsQueryVariables>;
export const PlanAdjustmentsDocument = gql`
    query PlanAdjustments {
  planAdjustments {
    storageSubscriptions {
      ...storageSubscriptions
    }
    planAdjustmentKind
  }
}
    ${StorageSubscriptionsFragmentDoc}`;

/**
 * __usePlanAdjustmentsQuery__
 *
 * To run a query within a React component, call `usePlanAdjustmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanAdjustmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanAdjustmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanAdjustmentsQuery(baseOptions?: Apollo.QueryHookOptions<PlanAdjustmentsQuery, PlanAdjustmentsQueryVariables>) {
        return Apollo.useQuery<PlanAdjustmentsQuery, PlanAdjustmentsQueryVariables>(PlanAdjustmentsDocument, baseOptions);
      }
export function usePlanAdjustmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanAdjustmentsQuery, PlanAdjustmentsQueryVariables>) {
          return Apollo.useLazyQuery<PlanAdjustmentsQuery, PlanAdjustmentsQueryVariables>(PlanAdjustmentsDocument, baseOptions);
        }
export type PlanAdjustmentsQueryHookResult = ReturnType<typeof usePlanAdjustmentsQuery>;
export type PlanAdjustmentsLazyQueryHookResult = ReturnType<typeof usePlanAdjustmentsLazyQuery>;
export type PlanAdjustmentsQueryResult = Apollo.QueryResult<PlanAdjustmentsQuery, PlanAdjustmentsQueryVariables>;
export const ShareDocument = gql`
    query Share {
  share {
    text
    url
  }
}
    `;

/**
 * __useShareQuery__
 *
 * To run a query within a React component, call `useShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareQuery({
 *   variables: {
 *   },
 * });
 */
export function useShareQuery(baseOptions?: Apollo.QueryHookOptions<ShareQuery, ShareQueryVariables>) {
        return Apollo.useQuery<ShareQuery, ShareQueryVariables>(ShareDocument, baseOptions);
      }
export function useShareLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShareQuery, ShareQueryVariables>) {
          return Apollo.useLazyQuery<ShareQuery, ShareQueryVariables>(ShareDocument, baseOptions);
        }
export type ShareQueryHookResult = ReturnType<typeof useShareQuery>;
export type ShareLazyQueryHookResult = ReturnType<typeof useShareLazyQuery>;
export type ShareQueryResult = Apollo.QueryResult<ShareQuery, ShareQueryVariables>;
export const SuggestedPerMoverGratuitiesDocument = gql`
    query SuggestedPerMoverGratuities {
  suggestedPerMoverGratuities {
    low
    mid
    high
  }
}
    `;

/**
 * __useSuggestedPerMoverGratuitiesQuery__
 *
 * To run a query within a React component, call `useSuggestedPerMoverGratuitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedPerMoverGratuitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedPerMoverGratuitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuggestedPerMoverGratuitiesQuery(baseOptions?: Apollo.QueryHookOptions<SuggestedPerMoverGratuitiesQuery, SuggestedPerMoverGratuitiesQueryVariables>) {
        return Apollo.useQuery<SuggestedPerMoverGratuitiesQuery, SuggestedPerMoverGratuitiesQueryVariables>(SuggestedPerMoverGratuitiesDocument, baseOptions);
      }
export function useSuggestedPerMoverGratuitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedPerMoverGratuitiesQuery, SuggestedPerMoverGratuitiesQueryVariables>) {
          return Apollo.useLazyQuery<SuggestedPerMoverGratuitiesQuery, SuggestedPerMoverGratuitiesQueryVariables>(SuggestedPerMoverGratuitiesDocument, baseOptions);
        }
export type SuggestedPerMoverGratuitiesQueryHookResult = ReturnType<typeof useSuggestedPerMoverGratuitiesQuery>;
export type SuggestedPerMoverGratuitiesLazyQueryHookResult = ReturnType<typeof useSuggestedPerMoverGratuitiesLazyQuery>;
export type SuggestedPerMoverGratuitiesQueryResult = Apollo.QueryResult<SuggestedPerMoverGratuitiesQuery, SuggestedPerMoverGratuitiesQueryVariables>;
export const UserReviewReasonsDocument = gql`
    query UserReviewReasons {
  userReviewReasons {
    associateReasons {
      positiveCopy
      negativeCopy
      reasonCode
    }
    leadReasons {
      positiveCopy
      negativeCopy
      reasonCode
    }
  }
}
    `;

/**
 * __useUserReviewReasonsQuery__
 *
 * To run a query within a React component, call `useUserReviewReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserReviewReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserReviewReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserReviewReasonsQuery(baseOptions?: Apollo.QueryHookOptions<UserReviewReasonsQuery, UserReviewReasonsQueryVariables>) {
        return Apollo.useQuery<UserReviewReasonsQuery, UserReviewReasonsQueryVariables>(UserReviewReasonsDocument, baseOptions);
      }
export function useUserReviewReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserReviewReasonsQuery, UserReviewReasonsQueryVariables>) {
          return Apollo.useLazyQuery<UserReviewReasonsQuery, UserReviewReasonsQueryVariables>(UserReviewReasonsDocument, baseOptions);
        }
export type UserReviewReasonsQueryHookResult = ReturnType<typeof useUserReviewReasonsQuery>;
export type UserReviewReasonsLazyQueryHookResult = ReturnType<typeof useUserReviewReasonsLazyQuery>;
export type UserReviewReasonsQueryResult = Apollo.QueryResult<UserReviewReasonsQuery, UserReviewReasonsQueryVariables>;