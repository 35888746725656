import * as Sentry from '@sentry/react';

import { WT_VISITOR_TOKEN } from './wt';

interface IConfig {
  sentry_dsn: string;
  sentry_environment: string;
  release_sha: string | null;
}

declare const CONFIG: IConfig;

const SENTRY_DSN = CONFIG.sentry_dsn;

if (SENTRY_DSN && process.env.RAILS_ENV !== 'test') {
  Sentry.init({
    dsn: CONFIG.sentry_dsn,
    release: CONFIG.release_sha ?? undefined,
    environment: CONFIG.sentry_environment,
  });

  Sentry.setUser({ id: WT_VISITOR_TOKEN });
}
