import * as React from 'react';

import { Box, COLORS } from '@clutter/clean';
import { Arrived } from './arrived';
import { Arrival } from './arrival';
import { ArrivalWindow } from './arrival_window';

import { useMoverNamesQuery, Mode } from '@application/platform_schema';
import { useOrderContext } from '@application/contexts/order';

const StatusHeader: React.FC = () => {
  const {
    app: { order, mode, workBreaks },
    staleCheckin,
  } = useOrderContext();
  const { data } = useMoverNamesQuery();
  const moverNames = data?.moverNames;
  const hasArrived = mode === Mode.Arrived || mode === Mode.ArrivedAtDestination;
  const { route, orderEvents } = order;
  const showArrivalWindow = !hasArrived && !route && mode !== Mode.DepartedForDestination;
  const workBreakActive = workBreaks.some((element) => {
    return element.started && !element.ended;
  });
  const parkedImage =
    mode === Mode.Arrived
      ? orderEvents.find((event) => event.eventName == 'parked' && event.category === 'linear_orders')?.image
      : undefined;

  return (
    <Box margin="0 0 40px 0">
      {showArrivalWindow && (
        <ArrivalWindow
          formattedEstimatedArrivalWindow={order.formattedEstimatedArrivalWindow}
          formattedScheduledArrivalWindow={order.formattedScheduledArrivalWindow}
          slaWindowAdjustedToLaterTime={order.slaWindowAdjustedToLaterTime}
        />
      )}
      {!hasArrived && route && route.eta && (
        <Arrival
          route={route}
          scheduled={order.scheduled}
          timeZone={order.region.tz}
          stale={staleCheckin}
          lastCheckinTimestamp={order.lastCheckin?.timestamp}
          workBreakActive={workBreakActive}
        />
      )}
      {hasArrived && <Arrived movers={moverNames ? moverNames : []} parkedImage={parkedImage ?? undefined} />}
      <Box margin="40px 0 0 0" height="1px" background={COLORS.grayBorder} />
    </Box>
  );
};

export { StatusHeader };
