/** @jsx jsx */

import * as React from 'react';

import { LaborClockFragment } from '@application/platform_schema';
import { Rating, UserReviewReasonsQuery } from '@application/platform_schema';

import { Box, COLORS, FontWeight, Text, Accordion, TagSelector } from '@clutter/clean';
import { Avatar } from '../../shared';
import { EmojiRating } from '@application/components/shared/emoji_rating';
import { css, jsx } from '@emotion/react';
import { useWTEvent } from '@application/components/wt/event';

type UserReviewReasonCopy = UserReviewReasonsQuery['userReviewReasons']['leadReasons'];

export type Review = {
  rating: Rating;
  userID: string;
  reasons: Set<string>;
};

interface IRateTeamProps {
  laborClock: LaborClockFragment;
  reviewReasons: UserReviewReasonCopy;
  onRate(review: Review): void;
}

const goodRatingStyles = css`
  background: ${COLORS.tealBackground};
  border-bottom: none;
`;

const poorRatingStyles = css`
  background: ${COLORS.dust};
  border-bottom: none;
`;

export const RateUser: React.FC<IRateTeamProps> = ({ laborClock, reviewReasons, onRate }) => {
  const [rating, setRating] = React.useState<Rating | undefined>(undefined);

  const positiveReviewReasons = reviewReasons.map((value: UserReviewReasonCopy[0]) => value.positiveCopy ?? '');
  const negativeReviewReasons = reviewReasons.map((value: UserReviewReasonCopy[0]) => value.negativeCopy ?? '');

  const track = useWTEvent({
    action: 'click',
    objectType: 'button',
    container: 'mover_review',
    metadata: { user_id: laborClock.user.externalID },
  });

  const onUpdateReview = ({ rating: newRating = rating, reasons }: { rating?: Rating; reasons: Set<string> }) => {
    setRating(newRating);
    if (!newRating) {
      return;
    }
    onRate({ userID: laborClock.user.externalID, rating: newRating, reasons });
  };

  return (
    <Box.Flex
      borderRadius="4px"
      flexDirection="column"
      boxShadow="0px 4px 14px rgba(0, 0, 0, 0.1)"
      data-test-id={`${laborClock.user.initials}_container`}
    >
      <Box.Flex height="92px" padding="16px" justifyContent="space-between" alignItems="center">
        <Box.Flex justifyContent="flex-start">
          <Avatar initials={laborClock.user.initials} imageURL={laborClock.user.imageURL} size="small" />
          <Box.Flex flexDirection="column" margin="0 0 0 20px" textAlign="left" justifyContent="center">
            <Text.Callout weight={FontWeight.Medium}>{laborClock.user.given}</Text.Callout>
            <Text.Callout weight={FontWeight.Regular}>
              {laborClock.role === 'lead' ? 'Team Lead' : 'Associate'}
            </Text.Callout>
          </Box.Flex>
        </Box.Flex>
        <EmojiRating
          onRate={(rating: Rating) => {
            track({ objectName: 'emoji_rating', value: rating === Rating.Good ? 'good' : 'poor' });
            onUpdateReview({ rating: rating, reasons: new Set() });
          }}
          rating={rating}
        />
      </Box.Flex>
      {rating && (
        <Accordion.Item
          name={laborClock.user.externalID}
          css={rating === Rating.Good ? goodRatingStyles : poorRatingStyles}
          label={
            <Text.Callout weight={FontWeight.Medium}>
              {rating === Rating.Good ? 'What did they do well?' : 'What could have improved'}
            </Text.Callout>
          }
        >
          <Box padding="0 16px 16px">
            <TagSelector
              key={rating}
              tags={rating === Rating.Good ? positiveReviewReasons : negativeReviewReasons}
              onClick={(tag, selected) => track({ objectName: 'reason', value: tag })}
              size="small"
              onChange={(tags) => onUpdateReview({ reasons: tags })}
            />
          </Box>
        </Accordion.Item>
      )}
    </Box.Flex>
  );
};
