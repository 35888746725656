import { Input } from '@clutter/clean';
import * as React from 'react';

interface ICurrencyInputProps {
  max?: number;
  min?: number;
  amount?: number;
  onUpdate(amount?: number): void;
}

export const CurrencyInput = ({
  max,
  min,
  amount,
  onUpdate,
  ...props
}: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> & ICurrencyInputProps) => (
  <Input
    type="tel"
    pattern="[0-9]*"
    value={amount !== undefined && amount !== 0 ? `$${amount}` : '$'}
    onChange={(event) => {
      if (event.target.value !== '$') {
        const value = Number(event.target.value.replace(/[^0-9.-]+/g, ''));
        if (min && value < min) {
          return;
        }
        if (max && value > max) {
          return;
        }
        onUpdate(value);
      } else {
        onUpdate(undefined);
      }
    }}
    {...props}
  />
);
