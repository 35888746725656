import { Box, COLORS, FontWeight, Text, TextButton, UnstyledButton } from '@clutter/clean';
import styled from '@emotion/styled';
import * as React from 'react';

import logo from '@application/images/logo.svg';
import close from '@application/images/icons/close.svg';
import menu from '@application/images/icons/menu.svg';

import { useWTEvent } from '../wt/event';

const HEADER_HEIGHT = '86px';
const FAQ_HREF = 'https://help.clutter.com/';
const CALL_HREF = 'tel:+1-800-805-4023';

const Container = styled.header`
  height: ${HEADER_HEIGHT};
  border-bottom: 1px solid ${COLORS.grayBorder};
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 32px 16px;
`;

const Menu = styled.div`
  position: absolute;
  top: ${HEADER_HEIGHT};
  height: calc(100vh - ${HEADER_HEIGHT});
  background: ${COLORS.cloud};
  z-index: 100;
  width: 100%;
  left: 0;
`;

const MenuItem = styled.div`
  padding: 32px 64px;
  text-align: center;

  & + & {
    border-top: 1px solid ${COLORS.grayBorder};
  }
`;

const Navigation: React.FC = () => {
  const [active, setActive] = React.useState(false);

  const track = useWTEvent({
    action: 'click',
    container: 'navigation',
    objectType: 'link',
    pageName: 'today:default',
  });

  return (
    <Container>
      <Box>
        <img src={logo} alt="Clutter" height="20" />
      </Box>
      <Box>
        <UnstyledButton onClick={() => setActive(!active)}>
          <img src={active ? close : menu} height="20" />
        </UnstyledButton>
      </Box>

      {active && (
        <Menu>
          <MenuItem>
            <TextButton href={FAQ_HREF} onClick={() => track({ objectName: 'faq' })} size="medium">
              FAQ
            </TextButton>
          </MenuItem>
          <MenuItem>
            <Box margin="0 0 8px">
              <Text.Callout weight={FontWeight.Medium}>Need help? Call us</Text.Callout>
            </Box>
            <TextButton href={CALL_HREF} onClick={() => track({ objectName: 'call-btn' })} size="medium">
              (800) 805-4023
            </TextButton>
          </MenuItem>
        </Menu>
      )}
    </Container>
  );
};

export { Navigation };
