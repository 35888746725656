import { useEffect, useRef, useState } from 'react';
import { actioncable } from '../libraries/actioncable';

export const useActionCableSubscription = <T>({
  channel,
  callback,
}: {
  channel: string;
  callback(data: T): void;
}): { connected: boolean } => {
  const [connected, setConnected] = useState(false);
  const ref = useRef(callback);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  useEffect(() => {
    const subscription = actioncable.subscriptions.create(channel, {
      connected: () => setConnected(true),
      disconnected: () => setConnected(false),
      received: (data: T) => ref.current(data),
    });
    return (): void => {
      subscription.unsubscribe();
    };
  }, [channel]);

  return { connected };
};
