import { tween } from 'popmotion';
import * as React from 'react';

import { Layer } from './layer';
import { Source } from './source';

interface ICoordinate {
  latitude: number;
  longitude: number;
}

interface ICircleProps {
  id: string;
  coordinate: ICoordinate;
  paint: any;
}

interface ICircleState {
  coordinate: ICoordinate;
}

class Circle extends React.Component<ICircleProps, ICircleState> {
  constructor(props: ICircleProps) {
    super(props);
    this.state = { coordinate: props.coordinate };
  }

  public componentDidUpdate(prior: ICircleProps) {
    if (prior.coordinate !== this.props.coordinate) {
      const options = {
        from: {
          latitude: prior.coordinate.latitude,
          longitude: prior.coordinate.longitude,
        },
        to: {
          latitude: this.props.coordinate.latitude,
          longitude: this.props.coordinate.longitude,
        },
      };
      tween(options).start((coordinate: ICoordinate) => {
        this.setState({ coordinate });
      });
    }
  }

  public render() {
    const { coordinate } = this.state;
    const { id, paint } = this.props;

    const data = {
      type: 'Point',
      coordinates: [coordinate.longitude, coordinate.latitude],
    };

    return (
      <Layer id={id} type="circle" source={id} paint={paint}>
        <Source id={id} data={data} type="geojson" />
      </Layer>
    );
  }
}

export { Circle };
