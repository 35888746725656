import * as React from 'react';
import { COLORS, Text, Box } from '@clutter/clean';

import { AgendaStep, AgendaStepState } from './agenda_step';
import { useOrderContext } from '@application/contexts/order';
import { OrderTypeEnum, OrderSubtypeEnum, Mode } from '@application/platform_schema';

type AgendaStepDescriptor = {
  title: string;
  description: string;
  state: AgendaStepState;
};

const buildAgendaStep = (
  title: string,
  description: string,
  state: AgendaStepState = AgendaStepState.Unstarted,
): AgendaStepDescriptor => {
  return {
    title: title,
    description: description,
    state: state,
  };
};

const resolveState = (started?: boolean, completed?: boolean) =>
  completed ? AgendaStepState.Completed : started ? AgendaStepState.InProgress : AgendaStepState.Unstarted;

const completeWalkthroughStep = (state: AgendaStepState) => {
  return buildAgendaStep(
    'Complete walkthrough',
    'You’ll show your Team Lead what’s headed to storage and what’s staying.',
    state,
  );
};

const signPaperworkStep = (state: AgendaStepState) => {
  return buildAgendaStep('Sign paperwork', 'Read and sign any paperwork so your team can get started.', state);
};

const getStartedStep = (state: AgendaStepState) => {
  return buildAgendaStep(
    'Your team will get started',
    'Your team will wrap any furniture with blankets & plastic to make sure they’re protected. They’ll then load all your items into the truck. While they work, watch your items get added to your photo catalog!\n\nDuring your appointment, your team may take their required 30 minute lunch break. Don’t worry, you won’t be charged for this time.',
    state,
  );
};

const itemsToWarehouseStep = (state: AgendaStepState) => {
  return buildAgendaStep(
    'Your items are on their way to our warehouse',
    'Your items are safely packed into our truck and being driven to our secure warehouse.',
    state,
  );
};

const itemsToStorageStep = (state: AgendaStepState) => {
  return buildAgendaStep(
    'Our team will move your items into storage',
    'Our team will unload your items off the truck, pack them onto pallets, and move them onto shelves for storage.',
    state,
  );
};

const addOrRemoveItemsStep = (state: AgendaStepState) => {
  return buildAgendaStep(
    'Add or remove items whenever you need them',
    'Log into your account portal to schedule a home delivery or pickup. The cost of the appointment depends on the number of items, as well as the date and level of assistance you choose.',
    state,
  );
};

const MovingAgenda = () => {
  const {
    app: { order, mode },
  } = useOrderContext();

  if (mode === Mode.Summary || mode === Mode.Finalize) return null;

  const steps: AgendaStepDescriptor[] = [
    buildAgendaStep(
      'Complete walkthrough',
      'You’ll show your Team Lead what’s headed to your new home and what’s staying.',
    ),
    buildAgendaStep('Sign paperwork', 'Read and sign any paperwork so your team can get started.'),
    buildAgendaStep(
      'Your team will pack your items',
      'Your team will box up your items and protect your furnitire with blankets & plastic wrap. ',
    ),
    buildAgendaStep(
      'We’ll load up the truck',
      'Your team will secure your items in the moving truck to ensure they’re safe for travel.',
    ),
    buildAgendaStep(
      'We’ll move your items into your new home',
      'Once everything is loaded onto the truck, your team will head to your new home and unpack your items.',
    ),
  ];

  if (order.financingIntent?.intended === true) {
    steps.push(
      buildAgendaStep(
        'Complete your payment with Affirm within 48 hrs',
        'Once your move is finished, we’ll send you a link to complete your payment with Affirm. Don’t worry, if you don’t complete your payment, we’ll charge your card on file.',
      ),
    );
  }

  return (
    <Box padding="0 0 0 0" margin="48px 0 24px 0" background={COLORS.cloud}>
      <Box margin="0 0 24px 0" color={COLORS.panther}>
        <Text.Title size="large">The plan</Text.Title>
      </Box>
      {steps.map((step, index) => {
        return <AgendaStep {...step} key={index} />;
      })}
    </Box>
  );
};

const StorageAgenda = () => {
  const { app, hasWalkedThrough, hasParked, hasSignedApproval, hasEnded } = useOrderContext();

  if (app.order.type != OrderTypeEnum.Pickup && app.order.type != OrderTypeEnum.PickupReturn) {
    return null;
  }

  let title = 'The plan';
  const steps: AgendaStepDescriptor[] = [];
  if (app.mode == Mode.Finalize) {
    title = 'What happens next?';
    steps.push(
      itemsToWarehouseStep(AgendaStepState.Unstarted),
      itemsToStorageStep(AgendaStepState.Unstarted),
      addOrRemoveItemsStep(AgendaStepState.Unstarted),
    );
  } else {
    if (app.order.type == OrderTypeEnum.Pickup && app.order.subtype == OrderSubtypeEnum.Onboarding) {
      steps.push(
        completeWalkthroughStep(resolveState(hasParked, hasWalkedThrough)),
        signPaperworkStep(resolveState(hasWalkedThrough, hasSignedApproval)),
      );
    } else {
      steps.push(signPaperworkStep(resolveState(hasParked, hasSignedApproval)));
    }
    steps.push(getStartedStep(resolveState(hasSignedApproval, hasEnded)));
  }

  return (
    <Box padding="0 0 0 0" margin="48px 0 24px 0" background={COLORS.cloud}>
      <Box margin="0 0 24px 0" color={COLORS.panther}>
        <Text.Title size="large">{title}</Text.Title>
      </Box>
      {steps.map((step, index) => {
        return <AgendaStep {...step} key={index} />;
      })}
    </Box>
  );
};

export const Agenda = () => {
  const type = useOrderContext().app.order.type;
  return type === OrderTypeEnum.Move ? <MovingAgenda /> : <StorageAgenda />;
};
